import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button, FormControl, TextField, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import DoctariCalendar from '../../components/DoctariCalendar';
import LinearLoader from '../../components/LinearLoader';
import Notification from '../../components/Notification';
import { AGENDA } from '../../utils/urls';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DoctariModal from '../../components/Modal';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/helpers';

function Operator({
  classes,
  doctorsInfo,
  appointmentsData,
  deleteMessages,

  userState,
  setSelectedDoctor,
  clearSchedules,
  getUserData,
  session,
  clearSelectedDoctor,
}) {
  const theme = useTheme();
  const history = useHistory();
  const [t, i18n] = useTranslation('global');
  const [state, setState] = useState({
    dateOfAppointment: null,
    doctorName: null,
    specialty: null,
    country: null,
    type: null,
    availability: true,
    score: false,
    doctorId: null,
    doctorFullData: null,
    // userLoad: false,
  });
  const {
    doctorId,
    doctorFullData,
    // userLoad,
  } = state;

  const { countries, specialities, numOfPages, doctors, currentPage } =
    doctorsInfo;
  const [userLoad, setUserLoad] = useState(false);
  const [createAppointmentModalOpen, setCreateAppointmentModalOpen] =
    useState(false);
  const [specialtyName, setSpecialtyName] = useState('');
  const [doctorSpecialty, setDoctorSpecialty] = useState([]);
  const [proficientData, setProficientData] = useState(null);
  const [doctorSlot, setDoctorSlot] = useState(null);
  const [appointmentType, setAppointmentType] = useState('');
  const isMedicine = appointmentType == 'Medicina';
  const isProficient = appointmentType == 'Peritaje';

  useEffect(() => {
    if (specialtyName != null && userLoad) {
      setDoctorSpecialty(
        userState.user.doctors.filter((doctor) => {
          return doctor.specialty.id == specialtyName.id && doctor.is_active;
        })
      );
      let doctorsSpecialtyHelper = userState.user.doctors.filter((doctor) => {
        return doctor.specialty.id == specialtyName.id && doctor.is_active;
      });
      history.push({
        pathname: AGENDA,
        state: {
          specialtyType: specialtyName
            ? specialtyName.name
            : doctorFullData.specialty.name,
          specialtyId: specialtyName.id,
          doctorsSpecialty: doctorsSpecialtyHelper,
        },
      });
    }
  }, [specialtyName]);

  useEffect(() => {
    getUserData(session.userType, session.id);
    if (doctorsInfo.selectedDoctor != null) {
      setState({
        ...state,
        doctorId: doctorsInfo.selectedDoctor.id,
        doctorFullData: doctorsInfo.selectedDoctor,
      });
    }
  }, []);

  useEffect(() => {
    if (doctorId) {
      setSelectedDoctor(doctorFullData);
      setDoctorSlot(doctorFullData.specialty.duration.minutes);
    } else {
      clearSchedules();
      clearSelectedDoctor();
      setState({
        ...state,
        doctorId: null,
      });
    }
  }, [doctorId]);

  useEffect(() => {
    if (userState.user) {
      if (userState.user.doctors) {
        setUserLoad(true);
      }
    }
  }, [userState.user]);

  useEffect(() => {
    if (appointmentsData.appointmentCreated) {
      if (
        appointmentsData.appointmentCreated.patient_link ||
        appointmentsData.appointmentCreated.client_link
      ) {
        setCreateAppointmentModalOpen(true);
      }
    }
  }, [appointmentsData.appointmentCreated]);

  const ClipboardCopy = ({ copyText, label }) => {
    const [isCopied, setIsCopied] = useState(false);

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }

    // onClick handler function for the copy button
    const handleCopyClick = () => {
      // Asynchronously call copyTextToClipboard
      copyTextToClipboard(copyText)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <TextField
          id="my-input"
          aria-describedby="my-helper-text"
          // onChange={(e) =>
          //   setDataPatient({ ...dataPatient, name: e.target.value })
          // }
          // type="text"
          style={{ width: '80%' }}
          variant="outlined"
          value={copyText}
          size="small"
          label={label}
          readOnly
        />
        {/* <input type="text" value={copyText} readOnly /> */}
        {/* Bind our handler function to the onClick button property */}
        <Button
          style={{ float: 'right', margin: '10px' }}
          variant="text"
          color="primary"
          //disabled={!attachments.length}
          onClick={handleCopyClick}
        >
          <span>{isCopied ? 'Copiado!' : 'Copiar'}</span>
        </Button>
        {/* <button onClick={handleCopyClick}>
          <span>{isCopied ? 'Copied!' : 'Copy'}</span>
        </button> */}
      </Box>
    );
  };

  return (
    <>
      {doctorsInfo.loading && <LinearLoader />}

      {appointmentsData.success.genericMessage ==
        'Appointment eliminado con exito' && (
        <Notification
          type="error"
          message={'Consulta cancelada'}
          onCloseCallback={deleteMessages}
        />
      )}
      <DoctariModal
        open={createAppointmentModalOpen}
        setOpen={(val) => setCreateAppointmentModalOpen(val)}
        title={`Consulta ${t('word.created')}`}
      >
        <ClipboardCopy
          copyText={
            appointmentsData.appointmentCreated
              ? appointmentsData.appointmentCreated.patient_link
                ? appointmentsData.appointmentCreated.patient_link
                : appointmentsData.appointmentCreated.client_link
              : ''
          }
          label="Link paciente"
        />
        <ClipboardCopy
          copyText={
            appointmentsData.appointmentCreated
              ? appointmentsData.appointmentCreated.video_conference_code
              : ''
          }
          label="Código paciente"
        />
        <p style={{ margin: '5%' }}>
          {`${t('sentence.sendEmailCreatedAppointment')}`}{' '}
          {appointmentsData.appointmentCreated
            ? appointmentsData.appointmentCreated.patient_link
              ? 'paciente'
              : `${t('word.client')}`
            : ''}{' '}
          {`${t('sentence.sendEmailCreatedAppointment2')}`}
        </p>

        <Button
          style={{ float: 'right', margin: '10px' }}
          variant="contained"
          color="primary"
          onClick={() => {
            setCreateAppointmentModalOpen(false);
          }}
        >
          {`${capitalize(t('word.accept'))}`}
        </Button>
      </DoctariModal>
      <Grid
        container
        direction="row"
        item
        xs={11}
        md={12}
        style={{ padding: '1rem 0 0 3rem', gap: '1rem' }}
      >
        <FormControl className={classes.formControl}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={['Medicina', 'Peritaje']}
            // getOptionLabel={(option) => option.name || ''}
            sx={{ width: 300 }}
            onChange={(event, value) => {
              setAppointmentType(value);
              if (value == 'Peritaje') {
                let proficientList = userState.user.proficients;

                history.push({
                  pathname: AGENDA,
                  state: {
                    specialtyType: 'Peritaje',
                    specialtyId: 0,
                    doctorsSpecialty: proficientList,
                  },
                });
              }
            }}
            value={appointmentType}
            renderInput={(params) => <TextField {...params} label="Tipo" />}
          />
        </FormControl>
        {isMedicine && (
          <FormControl className={classes.formControl}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={userLoad ? userState.specialities : []}
              getOptionLabel={(option) => option.name || ''}
              sx={{ width: 300 }}
              onChange={(_, value) => {
                setSpecialtyName(value);
              }}
              value={specialtyName}
              renderInput={(params) => (
                <TextField {...params} label={t('Assistant.specialty')} />
              )}
            />
          </FormControl>
        )}
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ flexWrap: 'initial', fontFamily: theme.typography.fontFamily }}
      >
        <Grid item xs={11} md={12}>
          <DoctariCalendar
            currentWeek={0}
            schedules={appointmentsData.schedules}
            slot={session.slot}
          ></DoctariCalendar>
        </Grid>
      </Grid>
    </>
  );
}

export default Operator;
