import moment from 'moment';

const SLOT = 20; //In minutes
const hours = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];

const isProfessional = (userType) => {
  if (userType === 'doctor') return true;
  else return false;
};

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const getSlotsRange = (slot) => {
  let initialHour = moment().set('hour', '00').set('minute', '00');
  const currentDay = initialHour.date();
  const hours = [];
  while (currentDay === initialHour.date()) {
    hours.push(initialHour.format('HH:mm'));
    initialHour.add(slot, 'minute');
  }
  return hours;
};

const mobileScreen = () => {
  return window.innerWidth <= 900;
};

const CONSTANTS = { numberOfPages: 5, hours, slot: SLOT };

export { CONSTANTS, isProfessional, capitalize, getSlotsRange, mobileScreen };
