import { useState, useEffect } from 'react';

const useMobile = (mobileWidth = 468) => {
  const [width, setWidth] = useState(window.innerWidth);
  const itsMobile = true;
  const mobileLimit = mobileWidth;
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  if (width < mobileLimit) {
    return itsMobile;
  }
  return !itsMobile;
};
export default useMobile;
