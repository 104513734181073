import React, { useState } from 'react';
import {
  Grid,
  Button,
  Paper,
  Box,
  FormControl,
  Input,
  InputLabel,
  Link,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function ForgotPassword({ goLogin, classes, validateEmail, sendEmail }) {
  const [state, setState] = useState({
    email: null,
  });
  const { email } = state;
  const [t, i18n] = useTranslation('global');

  function handleSendEmail() {
    if (email) {
      sendEmail({ email });
    }
  }

  return (
    <Grid
      container
      alignItems="center"
      style={{ minHeight: '100vh', backgroundColor: '#f1f1f1' }}
      justify="center"
    >
      <Grid item xs={11} md={3}>
        <Paper elevation={3}>
          <Box p={'13%'} display="flex" flexDirection="column">
            <Box className={classes.H5}>{`Recuperar ${t(
              'word.password'
            )}`}</Box>
            <FormControl className={classes.formControl} error={email === ''}>
              <InputLabel htmlFor="my-input">Email *</InputLabel>
              <Input
                id="my-input"
                aria-describedby="my-helper-text"
                autoComplete="off"
                onChange={(e) => setState({ ...state, email: e.target.value })}
                value={email}
                required
              />
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              onClick={handleSendEmail}
              disabled={!validateEmail(email)}
            >
              ENVIAR EMAIL
            </Button>
          </Box>
        </Paper>
        <Box className={classes.helpText}>
          <Link style={{ cursor: 'pointer' }} onClick={goLogin}>
            {`${t('sentence.resetPassword')}`}
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ForgotPassword;
