import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  video: {
    borderRadius: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px;',
    '@media(max-width:1440px) and (min-width:790px)': {
      width: '100%',
    },
  },
  gridContainer: {
    height: '92vh',
    width: '100%',
    position: 'absolute',
    top: '8%',
  },
  gridItem: {
    marginBottom: '2rem',
    width: '80%',
    height: '81%',
  },
  videoContainer: {
    // backgroundColor: "black",
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    marginBottom: '10px',
    '@media(max-width:810px) and (min-width:790px)': {
      width: '100%',
      height: '100%',
      borderRadius: '10px',
    },
  },
  videoContaineMobile: {
    // backgroundColor: "black",
    width: '100%',
    height: '100%',
    borderRadius: '10px',
  },
  paperContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: '5%',
    '@media(max-width:810px) and (min-width:790px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '5%',
    },
  },
  paperContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '5%',
  },
  videoAudioContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '57%',
    '@media(max-width:1366px) and (min-width:1280px)': {
      width: '80%',
    },
    '@media(max-width:810px) and (min-width:790px)': {
      width: '100%',
      height: '100%',
      borderRadius: '10px',
    },
  },
  textContainer: {
    marginRight: 'auto',
    marginLeft: '30px',
    fontFamily: 'roboto',
  },
  textContainerMobile: {
    marginRight: '30px',
    marginLeft: '30px',
    fontFamily: 'roboto',
  },
  title: {
    color: '#067dcf',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: '10px',
  },
  containerMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginBottom: '5%',
    width: '100%',
  },
}));
