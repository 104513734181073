import { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Grid,
  Avatar,
  TextField,
  CardHeader,
  Typography,
  Box,
} from '@material-ui/core';
import { useStyles } from './style';
import DoctariModal from '../../components/Modal';
import { DropzoneArea } from 'material-ui-dropzone';
import { connect } from 'react-redux';
import { APPOINTMENTS } from '../../utils/urls';
import { userActionsTypes } from '../../redux/user/actions';
import { appointmentActionsTypes } from '../../redux/appointment/actions';
import AutocompleteInfo from '../../components/AutocompleteInfo';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import { AttachmentList } from './AttachmentList';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/helpers';

function AppointmentDetails({
  getUser,
  createAppointment,
  appointmentsData,
  session,
  userData,
  addUserInfo,
  deleteUserInfo,
  createInfo,
  deleteAppointment,
  updateAppointment,
  uploadAppointmentFiles,
  deleteAppointmentFile,
  deleteAttachmentMessages,
  updateAppointmentLight,
  updateAppointmentProficient,
}) {
  const history = useHistory();
  const location = useLocation();
  const styles = useStyles();
  const [buttonClicked, setButtonClicked] = useState(false);
  const isProfessional = session.userType == 'doctor';
  const isProficient = session.userType == 'proficient';
  const appointment = location.state.appointment;
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [seeAttachmentsModalOpen, setSeeAttachmentsModalOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [input, setInput] = useState(appointment.patient_appointment_reason);
  const [files, setFiles] = useState(appointment.files);
  const isAssistant = session.userType == 'assistant';
  const [doctorFullData, setDoctorFullData] = useState(null);
  const [userLoading, setUserLoading] = useState(true);
  const isDone = appointment.is_done;
  const [cancelAppointmentModal, setCancelAppointmentModal] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [t, i18n] = useTranslation('global');

  useEffect(() => {
    if (appointmentsData.success.uploadFiles) {
      files.push(...appointmentsData.success.uploadFiles);
      setFiles(files);
      setAttachmentModalOpen(false);
    }
    return () => deleteAttachmentMessages(true);
  }, [appointmentsData.success.uploadFiles]);

  useEffect(() => {
    if (
      ((appointmentsData.success.genericMessage &&
        appointmentsData.success.genericMessage ==
          'Appointment eliminado con exito') ||
        appointmentsData.success.genericMessage ==
          'Appointment actualizado con exito') &&
      buttonClicked
    ) {
      history.push(APPOINTMENTS);
    }
  }, [appointmentsData.success.genericMessage]);

  useEffect(() => {
    getUser(session.userType, session.id);
  }, []);

  useEffect(() => {
    if (userData.user) {
      setUserLoading(false);
    }
  }, [userData.user]);

  const { user, medications, allergies, diseases } = userData;

  const getUrlAndId = (type, isForDelete) => {
    let dataType = {
      id: 'medication_id',
      url: isForDelete ? 'delete_medication' : 'patient_medication',
    };
    if (type === 'diseases') {
      dataType = {
        id: 'disease_id',
        url: isForDelete ? 'delete_disease' : 'patient_disease',
      };
    } else if (type === 'allergies') {
      dataType = {
        id: 'allergie_id',
        url: isForDelete ? 'delete_allergie' : 'patient_allergie',
      };
    }
    return dataType;
  };

  const handleUserMedication = (isNew, med, isForDelete = false) => {
    const infoToUpdate = 'medications';
    const data = getUrlAndId(infoToUpdate, isForDelete);
    if (!isForDelete) {
      if (isNew) {
        createInfo(infoToUpdate, { name: med.name }, data);
      } else {
        addUserInfo(infoToUpdate, data, med);
      }
    } else {
      deleteUserInfo(infoToUpdate, data, med.id);
    }
  };

  const handleUserDisease = (isNew, disease, isForDelete = false) => {
    const infoToUpdate = 'diseases';
    const data = getUrlAndId(infoToUpdate, isForDelete);
    if (!isForDelete) {
      if (isNew) {
        createInfo(infoToUpdate, { name: disease.name }, data);
      } else {
        addUserInfo(infoToUpdate, data, disease);
      }
    } else {
      deleteUserInfo(infoToUpdate, data, disease.id);
    }
  };

  const handleUserAllergie = (isNew, allergie, isForDelete = false) => {
    const infoToUpdate = 'allergies';
    const data = getUrlAndId(infoToUpdate, isForDelete);
    if (!isForDelete) {
      if (isNew) {
        createInfo(infoToUpdate, { name: allergie.name }, data);
      } else {
        addUserInfo(infoToUpdate, data, allergie);
      }
    } else {
      deleteUserInfo(infoToUpdate, data, allergie.id);
    }
  };

  const addInformation = {
    handleUserMedication,
    handleUserAllergie,
    handleUserDisease,
  };

  const handleAttachmentsChange = (files) => {
    setAttachments(files);
  };

  const handleUploadAttachments = () => {
    const formDataAppointment = new FormData();
    for (let i = 0; i < attachments.length; i++) {
      formDataAppointment.append(`file${i}`, attachments[i]);
    }
    const body = {
      files: formDataAppointment,
      appointmentId: appointment.id,
    };
    uploadAppointmentFiles(body);
  };

  const handleInputCHange = (e) => {
    setInput(e.target.value);
  };
  const handleDeleteAppointment = () => {
    setButtonClicked(true);
    deleteAppointment(appointment.id);
  };

  const handleUpdateAppointment = () => {
    setButtonClicked(true);
    let body = {
      appointmentId: appointment.id,
      patient_appointment_reason: input,
      diseases: user.diseases,
      medications: user.medications,
      allergies: user.allergies,
    };
    updateAppointment(body);
  };

  const handleChangeDoctor = (e, value) => {
    if (value == null) {
      setDoctorFullData(null);
    } else {
      setDoctorFullData(value);
    }
  };

  const handleCancelAppointment = (e) => {
    e.preventDefault();
    setButtonClicked(true);
    let body = {
      status: 'cancelled',
      is_cancelled: true,
      cancellation_reason: feedback,
    };
    if (isProficient) {
      updateAppointmentProficient(appointment.id, body);
    } else if (appointment.is_light) {
      updateAppointmentLight(appointment.id, body);
    } else {
      body['appointmentId'] = appointment.id;
      updateAppointment(body);
    }
  };

  return (
    <>
      {/* Add files modal */}
      <DoctariModal
        open={attachmentModalOpen}
        setOpen={(val) => setAttachmentModalOpen(val)}
        title={`${capitalize(t('word.attach'))} ${t('word.studies')}`}
      >
        <DropzoneArea
          useChipsForPreview={true}
          dropzoneText={`${t('sentence.attachedFileModal')}`}
          onChange={(files) => handleAttachmentsChange(files)}
          onDelete={(file) => console.log(file)}
          clearOnUnmount={false}
          filesLimit={3}
          initialFiles={attachments}
          getFileAddedMessage={(file) => `${file} añadido`}
          getFileRemovedMessage={(file) => `${file} eliminado`}
          getFileLimitExceedMessage={(limit) =>
            `${t('sentence.attachedFileModal2')} ${limit} ${t('word.files')}`
          }
        />
        <Button
          style={{ float: 'right', margin: '10px' }}
          variant="contained"
          color="primary"
          disabled={!attachments.length}
          onClick={handleUploadAttachments}
        >
          {!appointmentsData.loading
            ? capitalize(t('word.accept'))
            : `${capitalize(t('word.loading'))}...`}
        </Button>
        <Button
          style={{ float: 'right', margin: '10px' }}
          color="red"
          onClick={() => {
            setAttachments([]);
            setAttachmentModalOpen(false);
          }}
          variant="outlined"
          /* color="primary" */
        >
          Cancelar
        </Button>
      </DoctariModal>
      {/* See files modal */}
      <DoctariModal
        open={seeAttachmentsModalOpen}
        setOpen={(val) => setSeeAttachmentsModalOpen(val)}
        title={`${capitalize(t('word.files'))} adjuntos`}
      >
        <AttachmentList
          files={files}
          setFiles={setFiles}
          deleteFile={deleteAppointmentFile}
        ></AttachmentList>
        <Button
          style={{ float: 'right', margin: '10px' }}
          variant="contained"
          color="primary"
          onClick={() => setSeeAttachmentsModalOpen(false)}
        >
          {capitalize(t('word.accept'))}
        </Button>
        <Button
          style={{ float: 'right', margin: '10px' }}
          color="red"
          onClick={() => setSeeAttachmentsModalOpen(false)}
          variant="outlined"
          /*  color="primary" */
        >
          Cancelar
        </Button>
      </DoctariModal>

      {/* Appointment's cancelation modal */}
      <DoctariModal
        open={cancelAppointmentModal}
        setOpen={(val) => setCancelAppointmentModal(val)}
        title={`${capitalize(t('word.confirmation'))} de ${t(
          'word.cancellation'
        )}`}
      >
        <Typography style={{ padding: '1rem' }}>
          {`${t('sentence.cancelAppointmentModal')}`}
        </Typography>
        <form onSubmit={handleCancelAppointment}>
          <Box style={{ padding: '1rem' }}>
            <TextField
              required
              id="outlined-multiline-static"
              label="¿Por qué?"
              multiline
              style={{ width: '100%' }}
              rows="4"
              placeholder="Comentarios..."
              variant="outlined"
              onChange={(e) => setFeedback(e.target.value)}
            />
          </Box>
          <Box>
            <Button
              style={{ float: 'right', margin: '10px' }}
              variant="contained"
              color="primary"
              // disabled={!attachments.length}
              // onClick={() => }
              type="submit"
            >
              {!appointmentsData.loading
                ? capitalize(t('word.accept'))
                : `${capitalize(t('word.loading'))}...`}
            </Button>

            <Button
              style={{ float: 'right', margin: '10px' }}
              color="red"
              onClick={() => {
                setCancelAppointmentModal(false);
              }}
              variant="outlined"
              /* color="primary" */
            >
              Cancelar
            </Button>
          </Box>
        </form>
      </DoctariModal>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          md={12}
          justifyContent="space-between"
          className={styles.menuContainer}
        >
          <h2 className={styles.title}>{`${capitalize(t('word.details'))} ${t(
            'prep.of-the'
          )} consulta`}</h2>
          <CardHeader
            avatar={
              <Avatar
                alt="Foto de perfil"
                src={
                  appointment.patient //TODO todos los chequeos de appointment.patient hay que sacarlos, quedaron appointment con patient= null por error, hay que limpiarlos
                    ? isProfessional
                      ? appointment.patient.profile_picture &&
                        appointment.patient.profile_picture.url
                      : appointment.doctor.profile_picture &&
                        appointment.doctor.profile_picture.url
                    : null
                }
                className={styles.avatar}
              />
            }
            title={
              appointment.patient
                ? isProfessional
                  ? appointment.patient.full_name
                  : appointment.doctor.full_name
                : isProficient
                ? appointment.client.full_name
                : '-'
            }
            subheader={
              appointment.patient
                ? isProfessional
                  ? null
                  : appointment.doctor.specialty.name
                : ''
            }
          />
        </Grid>
        <Grid item className={styles.formConteiner}>
          <Paper elevation={8} style={{ padding: '40px 40px' }}>
            <Grid item container spacing={2} className={styles.testContainer}>
              <Grid className={styles.firstPaperContainer}>
                <p>
                  <b>{`${t('Appointment.appointment-date')}`}:</b>
                  {` ${moment(appointment.date)
                    .tz(moment.tz.guess())
                    .format('YYYY-MM-DD HH:mm [hs]')}`}
                </p>
                <p>
                  {`${t('Appointment.value')}`}:{' '}
                  <b>{appointment.price ? `$${appointment.price}` : '-'}</b>{' '}
                </p>
              </Grid>
              {isAssistant && (
                <Grid
                  // container
                  // direction="row"
                  item
                  xs={12}
                  md={12}
                  // style={{ padding: '1rem 0 0 3rem' }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={
                      !userLoading
                        ? userData.user.doctors.filter((doctor) => {
                            return doctor.is_active;
                          })
                        : []
                    }
                    getOptionLabel={(option) => option.full_name}
                    sx={{ width: 300 }}
                    value={doctorFullData}
                    onChange={handleChangeDoctor}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('Assistant.select-doctor')}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item md={12} xs={12}>
                <TextField
                  disabled={
                    isProfessional || isAssistant || isDone || isProficient
                  }
                  required
                  id="standard-basic"
                  label={t('HistoricAppointmentCard.appointment-reason')}
                  variant="standard"
                  style={{ width: '100%' }}
                  className={styles.paperGridItems}
                  onChange={handleInputCHange}
                  defaultValue={
                    appointment.patient
                      ? appointment.patient_appointment_reason
                      : appointment.client_reason_text
                  }
                />
              </Grid>
              {appointment.patient ? (
                <>
                  <Grid item md={6} xs={12} className={styles.paperGridItems}>
                    <AutocompleteInfo
                      label={'Alergias'}
                      optionList={allergies}
                      valuesSelected={
                        isProfessional || isAssistant
                          ? appointment.allergies
                          : user
                          ? user.allergies
                          : []
                      }
                      isProfessional={isProfessional || isProficient}
                      setNewValue={(allergie) => {
                        const isNew = true;
                        addInformation.handleUserAllergie(isNew, allergie);
                      }}
                      addValue={(allergie) => {
                        const isNew = false;
                        addInformation.handleUserAllergie(isNew, allergie);
                      }}
                      deleteValue={(allergie) => {
                        const isNew = false;
                        const isForDelete = true;
                        addInformation.handleUserAllergie(
                          isNew,
                          allergie,
                          isForDelete
                        );
                      }}
                      isAssistant={isAssistant}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={styles.paperGridItems}>
                    <AutocompleteInfo
                      label={t('MedicalData.diseases')}
                      optionList={diseases}
                      valuesSelected={
                        isProfessional || isAssistant
                          ? appointment.diseases
                          : user
                          ? user.diseases
                          : []
                      }
                      isProfessional={isProfessional || isProficient}
                      setNewValue={(disease) => {
                        const isNew = true;
                        addInformation.handleUserDisease(isNew, disease);
                      }}
                      addValue={(disease) => {
                        const isNew = false;
                        addInformation.handleUserDisease(isNew, disease);
                      }}
                      deleteValue={(disease) => {
                        const isNew = false;
                        const isForDelete = true;
                        addInformation.handleUserDisease(
                          isNew,
                          disease,
                          isForDelete
                        );
                      }}
                      isAssistant={isAssistant}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={styles.paperGridItems}>
                    <AutocompleteInfo
                      label={'Medicamentos'}
                      optionList={medications}
                      valuesSelected={
                        isProfessional || isAssistant
                          ? appointment.medications
                          : user
                          ? user.medications
                          : []
                      }
                      isProfessional={isProfessional || isProficient}
                      setNewValue={(med) => {
                        const isNew = true;
                        addInformation.handleUserMedication(isNew, med);
                      }}
                      addValue={(med) => {
                        const isNew = false;
                        addInformation.handleUserMedication(isNew, med);
                      }}
                      deleteValue={(med) => {
                        const isNew = false;
                        const isForDelete = true;
                        addInformation.handleUserMedication(
                          isNew,
                          med,
                          isForDelete
                        );
                      }}
                      isAssistant={isAssistant}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item md={12} xs={12}>
                  <TextField
                    disabled={
                      isProfessional || isAssistant || isDone || isProficient
                    }
                    required
                    id="standard-basic"
                    label={`Tipo de ${t('word.expertise')}`}
                    variant="standard"
                    style={{ width: '100%' }}
                    className={styles.paperGridItems}
                    InputProps={{
                      readOnly: true,
                    }}
                    defaultValue={
                      appointment.appointment_type == 'vehicle'
                        ? t('word.vehicle')
                        : t('word.home')
                    }
                  />
                </Grid>
              )}
              <div className={styles.forthtPaperContainer}>
                <Grid item>
                  {!isProfessional && !isAssistant && !isProficient && (
                    <Button
                      variant="outlined"
                      className={styles.buttons}
                      color="primary"
                      style={{ marginRight: '10px' }}
                      onClick={() => setAttachmentModalOpen(true)}
                      disabled={isDone}
                    >
                      {capitalize(t('Appointment.attached'))}
                    </Button>
                  )}
                  {!isProfessional && !isAssistant && !isProficient && (
                    <Button
                      variant="outlined"
                      className={styles.buttons}
                      color="primary"
                      style={{ marginRight: '10px' }}
                      onClick={() => setSeeAttachmentsModalOpen(true)}
                      disabled={isDone}
                    >
                      Ver {t('HistoricAppointmentCard.attached')}
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    className={styles.buttons}
                    color="primary"
                    onClick={() => setCancelAppointmentModal(true)}
                    disabled={isAssistant || isDone}
                  >
                    Cancelar consulta
                  </Button>
                  {!isProfessional && !isProficient && (
                    <Button
                      variant="contained"
                      className={styles.buttons}
                      color="primary"
                      onClick={handleUpdateAppointment}
                      disabled={isAssistant || isDone}
                    >
                      {t('PersonalData.save')}
                    </Button>
                  )}
                  {isProfessional ||
                    (isProficient && (
                      <Button
                        variant="contained"
                        className={styles.buttons}
                        color="primary"
                        onClick={() => {
                          let pageName =
                            process.env.REACT_APP_VIDEO_URL +
                            'appointment/' +
                            appointment.id +
                            '/?idUser=' +
                            session.id +
                            '&token=' +
                            session.accessToken +
                            `${
                              appointment.client_link
                                ? appointment.appointment_type == 'vehicle'
                                  ? '&expertise=vehicle&light=1'
                                  : '&expertise=home&light=1'
                                : '&light=1'
                            }` +
                            '&language=' +
                            `${
                              localStorage.getItem('language') == 'es'
                                ? 'es'
                                : 'pt-br'
                            }`;
                          window.location.href = pageName;
                        }}
                      >
                        {capitalize(t('word.accept'))} e ir
                      </Button>
                    ))}
                </Grid>
              </div>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ session, user, appointments }) => {
  return {
    session,
    userData: user,
    appointmentsData: appointments,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUser: (type, id) =>
    dispatch({
      type: userActionsTypes.GET_USER,
      payload: { type, id },
    }),
  addUserInfo: (type, data, newValue) =>
    dispatch({
      type: userActionsTypes.ADD_INFO_USER,
      payload: { type, data, newValue },
    }),
  createInfo: (type, body, data) =>
    dispatch({
      type: userActionsTypes.CREATE_INFORMATION,
      payload: { type, body, data },
    }),
  deleteUserInfo: (type, data, valueId) =>
    dispatch({
      type: userActionsTypes.DELETE_INFO_USER,
      payload: { type, data, valueId },
    }),
  createAppointment: (
    scheduleId,
    reason,
    doctor,
    patient,
    token,
    allergiesObjects,
    medicationsObjects,
    diseasesObjects
  ) =>
    dispatch({
      type: appointmentActionsTypes.CREATE_APPOINTMENT,
      payload: {
        scheduleId,
        reason,
        doctor,
        patient,
        token,
        allergiesObjects,
        medicationsObjects,
        diseasesObjects,
      },
    }),

  updateAppointment: (body) =>
    dispatch({
      type: appointmentActionsTypes.UPDATE_APPOINTMENT,
      payload: {
        body,
      },
    }),
  updateAppointmentLight: (id, body) =>
    dispatch({
      type: appointmentActionsTypes.UPDATE_APPOINTMENT_LIGHT,
      payload: { id, body },
    }),
  uploadAppointmentFiles: (body) =>
    dispatch({
      type: appointmentActionsTypes.UPLOAD_APPOINTMENT_FILES,
      payload: { body },
    }),
  deleteAppointment: (appointmentId) =>
    dispatch({
      type: appointmentActionsTypes.DELETE_APPOINTMENT,
      payload: {
        appointmentId,
      },
    }),
  deleteAppointmentFile: (fileId) =>
    dispatch({
      type: appointmentActionsTypes.DELETE_APPOINTMENT_FILE,
      payload: {
        fileId,
      },
    }),
  deleteAttachmentMessages: () =>
    dispatch({
      type: appointmentActionsTypes.DELETE_ATTACHMENT_MESSAGES,
    }),
  updateAppointmentProficient: (id, body) =>
    dispatch({
      type: appointmentActionsTypes.UPDATE_APPOINTMENT_PROFICIENT,
      payload: { id, body },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetails);
