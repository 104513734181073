import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import reducers from '../';
import userSaga from '../user/sagas';
import doctorSaga from '../doctor/sagas';
import appointmentSaga from '../appointment/sagas';
import proficientsSaga from '../proficient/sagas';

const sagaMiddleware = createSagaMiddleware();
const middleware =
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(sagaMiddleware)
    : composeWithDevTools(applyMiddleware(sagaMiddleware));

const store = createStore(reducers, middleware);

const persistor = persistStore(store);

sagaMiddleware.run(userSaga);
sagaMiddleware.run(doctorSaga);
sagaMiddleware.run(appointmentSaga);
sagaMiddleware.run(proficientsSaga);

export { store, persistor };
