import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Paper,
  Box,
  FormControl,
  Input,
  InputLabel,
  Link,
} from '@material-ui/core';
import LinearLoader from '../../components/LinearLoader';
import Header from '../../components/Header';
import Notification from '../../components/Notification';
import SignUpForm from './SignUpForm';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

import { userActionsTypes } from '../../redux/user/actions';
import { useStyles } from './style';
import {
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/helpers';

function Login({
  userState,
  onLogin,
  onTokenLogin,
  onSignup,
  getInitialData,
  session,
  confirmResetPassword,
  getPasswordResetToken,
  deleteMessages,
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  let { resetToken, token } = queryString.parse(location.search);

  const [state, setState] = useState({
    email: null,
    password: null,
    showSignUp: false,
    showForgotPassword: false,
    showResetPassword: resetToken,
  });
  const [first, setfirst] = useState(false);
  const { email, password, showSignUp, showForgotPassword, showResetPassword } =
    state;
  const { countries, specialities } = userState;
  const [t, i18n] = useTranslation('global');

  useEffect(() => {
    if (token) {
      // we pass tpken here as an object because is what backend needs.
      onTokenLogin({ token });
    }
  }, []);

  useEffect(() => {
    if (userState.success) {
      setState({
        showSignUp: false,
        showForgotPassword: false,
        showResetPassword: false,
      });
    }
  }, [userState.success]);

  const handleLogin = (e) => {
    e.preventDefault();
    if (email && password) {
      const credentials = {
        email,
        password,
      };
      onLogin(credentials);
      setfirst(true);
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // if (session && session.email) {
  //   history.replace(HOMEPAGE);
  // }

  return (
    <>
      <Header />
      {userState.success && (
        <Notification
          type="success"
          message={userState.success}
          onCloseCallback={deleteMessages}
        />
      )}
      {userState.error && (
        <Notification
          type="error"
          message={userState.error}
          onCloseCallback={deleteMessages}
        />
      )}
      {userState.loading && <LinearLoader />}
      {/* <LoginForm onLogin={onLogin} /> */}
      {showResetPassword ? (
        <ResetPassword
          resetToken={resetToken}
          classes={classes}
          goLogin={() => setState({ ...state, showResetPassword: false })}
          resetPassword={confirmResetPassword}
        />
      ) : showSignUp ? (
        <SignUpForm
          classes={classes}
          goLogin={() => setState({ ...state, showSignUp: false })}
          getInitialData={getInitialData}
          countries={countries}
          specialities={specialities}
          validateEmail={validateEmail}
          onSignup={onSignup}
        />
      ) : showForgotPassword ? (
        <ForgotPassword
          classes={classes}
          goLogin={() => setState({ ...state, showForgotPassword: false })}
          validateEmail={validateEmail}
          sendEmail={getPasswordResetToken}
        />
      ) : (
        <form onSubmit={handleLogin}>
          <Grid
            container
            alignItems="center"
            style={{ minHeight: '100vh', backgroundColor: '#f1f1f1' }}
            justify="center"
          >
            <Grid item xs={11} md={3}>
              <Paper elevation={3}>
                <Box p={'13%'} display="flex" flexDirection="column">
                  <Box className={classes.H5}>{`${capitalize(
                    t('word.getInto')
                  )}`}</Box>
                  <FormControl
                    className={classes.formControl}
                    error={email === ''}
                  >
                    <InputLabel htmlFor="my-input">Email *</InputLabel>
                    <Input
                      id="my-input"
                      aria-describedby="my-helper-text"
                      autoComplete="off"
                      onChange={(e) =>
                        setState({ ...state, email: e.target.value })
                      }
                      value={email}
                      required
                    />
                  </FormControl>
                  <FormControl
                    className={classes.formControl}
                    error={password === ''}
                  >
                    <InputLabel htmlFor="my-input">{`${t(
                      'AccountData.pass'
                    )} *`}</InputLabel>
                    <Input
                      id="my-input"
                      aria-describedby="my-helper-text"
                      onChange={(e) =>
                        setState({ ...state, password: e.target.value })
                      }
                      value={password}
                      type="password"
                      color="primary"
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    //onClick={handleLogin}
                    disabled={!validateEmail(email) || !password}
                  >
                    {`${t('word.getInto')}`}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.register}
                    onClick={() => setState({ ...state, showSignUp: true })}
                  >
                    {`${t('word.signIn')}`}
                  </Button>
                </Box>
              </Paper>
              <Box className={classes.helpText}>
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    setState({ ...state, showForgotPassword: true })
                  }
                >
                  {`${t('sentence.login-1')}`}
                </Link>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
}

const mapStateToProps = ({ user, session }) => {
  return {
    userState: user,
    session,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLogin: (credentials) =>
    dispatch({ type: userActionsTypes.USER_LOGIN, payload: credentials }),
  onTokenLogin: (token) =>
    dispatch({ type: userActionsTypes.USER_TOKEN_LOGIN, payload: token }),
  confirmResetPassword: (data) =>
    dispatch({ type: userActionsTypes.CONFIRM_RESET_PASSWORD, payload: data }),
  getPasswordResetToken: (data) =>
    dispatch({
      type: userActionsTypes.GET_PASSWORD_RESET_TOKEN,
      payload: data,
    }),
  getInitialData: () => dispatch({ type: userActionsTypes.GET_SIGNUP_DATA }),
  onSignup: (type, body) =>
    dispatch({ type: userActionsTypes.USER_SIGNUP, payload: { type, body } }),
  deleteMessages: () =>
    dispatch({
      type: userActionsTypes.DELETE_MESSAGES,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
