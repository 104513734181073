import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Select,
  Box,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import DoctariModal from '../../components/Modal';
import { APPOINTMENTS } from '../../utils/urls';
import { userActionsTypes } from '../../redux/user/actions';

function Main({ session, selectType }) {
  const theme = useTheme();
  const [state, setState] = useState({
    modalOpen: true,
    type: null,
  });
  const history = useHistory();
  const { modalOpen, type } = state;

  //If the user has more than one role (eg. doctor and patient) we show the modal for it to choose one role
  if (session.availableUserTypes > 1) {
    return (
      <>
        <DoctariModal
          open={modalOpen}
          setOpen={(val) => setState({ ...state, modalOpen: val })}
          title={'Tipo de usuario'}
          hideCross
        >
          <Box
            style={{
              padding: '2rem',
              overflow: 'auto',
              fontFamily: theme.typography.fontFamily,
            }}
          >
            <Box display="flex" flexDirection="column">
              <h4>
                Seleccione el tipo de usuario que desea usar en la plataforma
              </h4>
              <FormControl
                style={{
                  marginBottom: '1rem',
                  width: '100%',
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  Seleccione un tipo *
                </InputLabel>
                <Select
                  inputProps={{
                    name: 'type',
                    id: 'type-native-simple',
                  }}
                  native
                  value={type}
                  onChange={(e) => setState({ ...state, type: e.target.value })}
                >
                  <option aria-label="None" value="" />
                  {session.availableUserTypes &&
                    session.availableUserTypes.map((type) => {
                      if (type === 'doctor') {
                        return <option value={type}>Doctor</option>;
                      } else {
                        return <option value={type}>Paciente</option>;
                      }
                    })}
                </Select>
              </FormControl>
            </Box>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '1rem',
              }}
            >
              <Button
                color="primary"
                variant="contained"
                disabled={!type}
                onClick={() => {
                  selectType(type);
                  history.push(APPOINTMENTS);
                }}
              >
                Seleccionar
              </Button>
            </Grid>
          </Box>
        </DoctariModal>
      </>
    );
  } else {
    return <Redirect to={APPOINTMENTS} />;
  }
}

const mapStateToProps = ({ session }) => {
  return {
    session,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectType: (userType) =>
    dispatch({ type: userActionsTypes.SELECT_TYPE, payload: userType }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
