import React, { useEffect } from "react";
import { Grid, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import AgendaRequestCard from "../Cards/AgendaRequestCard";
import DoctariSpinner from "../Spinner";
import {useTranslation} from 'react-i18next'

function PatientAgendaRequest({ closeModal, getScheduleRequests, updateScheduleRequests, acceptScheduleRequest, userId, token, appointmentsData }) {
  useEffect(() => {
    getScheduleRequests();
  }, []);
  const { scheduleRequests, loading } = appointmentsData;

  const useStyles = makeStyles(() => ({
    boxStyles: {
      padding: "2rem",
      overflowX: "hidden",
      "@media screen and (min-width:320px)": {
        padding: ".7rem",
      },
    },
  }));

  const classes = useStyles();
  const [t, i18n] = useTranslation('global')

  return (
    <Box
      className={
        classes.boxStyles
        /* scheduleRequests.length > 0
          ? { padding: "2rem", overflowY: "scroll", overflowX: "hidden" }
          : {
              padding: "2rem",
            } */
      }
    >
      <Box display="flex" flexDirection="column">
        {loading ? (
          <DoctariSpinner />
        ) : scheduleRequests && scheduleRequests.length > 0 ? (
          <>
            {scheduleRequests.map((scheduleRequest) => {
              return (
                <AgendaRequestCard
                  scheduleRequest={scheduleRequest}
                  acceptScheduleRequest={() =>
                    acceptScheduleRequest({
                      schedule_request_id: scheduleRequest.id,
                    })
                  }
                  appointmentsData={appointmentsData}
                  isPatient={true}
                  updateScheduleRequest={(body) => updateScheduleRequests(`?patient=${userId}`, scheduleRequest.id, body, token)}
                />
              );
            })}
          </>
        ) : (
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {t('PatientAgendaRequest.no-requests')}
          </h4>
        )}
      </Box>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <Button color="primary" variant="contained" onClick={closeModal}>
          {t('PatientAgendaRequest.close')}
        </Button>
      </Grid>
    </Box>
  );
}

export default PatientAgendaRequest;
