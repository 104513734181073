import { useState, useEffect } from 'react';
import moment from 'moment';
import {
  FormControl,
  InputLabel,
  Box,
  Input,
  Radio,
  IconButton,
  Avatar,
  FormControlLabel,
  Select,
  TextField,
  RadioGroup,
  Button,
  Grid,
  FormLabel,
  FormHelperText,
} from '@material-ui/core';
import { CameraAlt, RemoveCircle } from '@material-ui/icons';
import ProfilePicture from './profile-picture';
import DoctariModal from '../../components/Modal';
import { useTranslation } from 'react-i18next';

function PersonalData({
  classes,
  countries,
  user,
  createAddress,
  deleteAddress,
  isOlderThan18,
  updateUser,
  isPatient,
  updateProfilePic,
  isProfileDoctor,
  session,
  doctors,
  createAddressByAssistant,
  deleteAddressByAssistant,
  isDoctor,
}) {
  const [state, setState] = useState({
    name: null,
    lastName: null,
    country: null,
    document: null,
    email: null,
    dateOfBirth: null,
    phone: null,
    license: null,
    city: null,
    gender: null,
    modalOpen: false,
    showProfilePicModal: false,
    address: [],
  });

  const {
    name,
    lastName,
    country,
    document,
    email,
    dateOfBirth,
    phone,
    license,
    city,
    gender,
    modalOpen,
    showProfilePicModal,
    address,
  } = state;
  const [t, i18n] = useTranslation('global');

  useEffect(() => {
    if (user || doctors.selectedDoctor) {
      setState({
        ...state,
        name: doctors.selectedDoctor
          ? doctors.selectedDoctor.first_name
          : user.first_name,
        lastName: doctors.selectedDoctor
          ? doctors.selectedDoctor.last_name
          : user.last_name,
        document: doctors.selectedDoctor
          ? doctors.selectedDoctor.identity_document
          : user.identity_document,
        dateOfBirth: moment(
          doctors.selectedDoctor
            ? doctors.selectedDoctor.date_of_birth
            : user.date_of_birth
        ).format('YYYY-MM-DD'),
        phone: doctors.selectedDoctor
          ? doctors.selectedDoctor.telephone
          : user.telephone,
        gender: doctors.selectedDoctor
          ? doctors.selectedDoctor.gender
          : user.gender,
        email: doctors.selectedDoctor
          ? doctors.selectedDoctor.email
          : user.email,
        license: doctors.selectedDoctor
          ? doctors.selectedDoctor.license_number
          : user.license_number,
        city: doctors.selectedDoctor ? doctors.selectedDoctor.city : user.city,
        country: doctors.selectedDoctor
          ? doctors.selectedDoctor.country.id
          : user.country.id,
        address: doctors.selectedDoctor
          ? doctors.selectedDoctor.address
          : user.address,
      });
    }
  }, [user, doctors.selectedDoctor]);

  const onSave = () => {
    const body = {
      first_name: name,
      last_name: lastName,
      identity_document: document,
      date_of_birth: dateOfBirth,
      telephone: phone,
      gender: gender,
      email: email,
      country,
      license_number: license,
      city: city,
      address: address,
    };
    updateUser(
      isProfileDoctor ? 'doctor' : session.userType,
      isProfileDoctor && doctors.selectedDoctor
        ? doctors.selectedDoctor.id
        : user?.id,
      body,
      false,
      isProfileDoctor
    );
  };

  const isEnable = () => {
    let isEnable = true;
    let obj = null;
    for (const prop in state) {
      obj = state[prop];
      if (prop !== 'modalOpen') {
        if (obj && prop === 'dateOfBirth') {
          isEnable = isEnable && isOlderThan18(dateOfBirth);
        } else if (prop === 'license' && isPatient) {
          isEnable = isEnable;
        } else if (obj != null) {
          isEnable = isEnable;
        }
      }
    }
    return isEnable;
  };

  const AddNewDirection = ({ closeModal }) => {
    const [state, setState] = useState({
      addressCountry: null,
      addressCity: null,
      street: null,
      streetNumber: null,
    });

    const isEnable = () => {
      let isEnable = true;
      let obj = null;
      for (const prop in state) {
        obj = state[prop];
        isEnable = isEnable && obj;
      }
      return isEnable;
    };
    const { addressCountry, addressCity, street, streetNumber } = state;
    return (
      <Box className={classes.modalBody}>
        <Box display="flex">
          <FormControl
            className={classes.formControl}
            error={street === ''}
            style={{ marginRight: '1.5rem' }}
          >
            <InputLabel htmlFor="my-input-street" shrink>
              Calle *
            </InputLabel>
            <Input
              id="my-input-street"
              aria-describedby="my-helper-text"
              onChange={(e) => setState({ ...state, street: e.target.value })}
              value={street}
              required
            />
          </FormControl>
          <FormControl
            className={classes.formControl}
            style={{ marginLeft: '1.5rem' }}
          >
            <InputLabel id="institute-native-simple" shrink>
              País *
            </InputLabel>
            <Select
              inputProps={{
                name: 'institution',
                id: 'institute-native-simple',
              }}
              native
              value={addressCountry}
              onChange={(e) =>
                setState({ ...state, addressCountry: e.target.value })
              }
            >
              <option aria-label="None" value="" />
              {countries &&
                countries.map((country) => {
                  return <option value={country.id}>{country.name}</option>;
                })}
            </Select>
          </FormControl>
        </Box>

        <Box display="flex">
          <FormControl
            className={classes.formControl}
            error={streetNumber === ''}
            style={{ marginRight: '1.5rem' }}
          >
            <InputLabel htmlFor="my-input-streetNumber" shrink>
              Nro de puerta *
            </InputLabel>
            <Input
              id="my-input-streetNumber"
              aria-describedby="my-helper-text"
              onChange={(e) =>
                setState({ ...state, streetNumber: e.target.value })
              }
              value={streetNumber}
              required
            />
          </FormControl>
          <FormControl
            className={classes.formControl}
            error={addressCity === ''}
            style={{ marginLeft: '1.5rem' }}
          >
            <InputLabel htmlFor="my-input-addressCity" shrink>
              Ciudad *
            </InputLabel>
            <Input
              id="my-input-addressCity"
              aria-describedby="my-helper-text"
              onChange={(e) =>
                setState({ ...state, addressCity: e.target.value })
              }
              value={addressCity}
              required
            />
          </FormControl>
        </Box>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => closeModal()}
            style={{ marginRight: '1rem' }}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              const body = {
                street,
                city: addressCity,
                street_number: streetNumber,
                country: addressCountry,
                doctor: doctors?.selectedDoctor
                  ? doctors.selectedDoctor.id
                  : user.id,
              };
              if (
                session.userType == 'assistant' ||
                session.userType == 'operator'
              ) {
                createAddressByAssistant(body);
              } else {
                createAddress(body);
              }
              setState({ ...state, modalOpen: false });
            }}
            disabled={!isEnable()}
          >
            Agregar
          </Button>
        </Grid>
      </Box>
    );
  };

  const openModalProfilePic = () => {
    // if (doctors.selectedDoctor) {
    setState({ ...state, showProfilePicModal: true });
    // }
  };

  const openModalAddAddress = () => {
    // if (doctors.selectedDoctor) {
    setState({ ...state, modalOpen: true });
    // }
  };

  return (
    <Box
      padding="1rem"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pointerEvents: isProfileDoctor
          ? !doctors.selectedDoctor
            ? 'none'
            : ''
          : '',
      }}
    >
      <ProfilePicture
        open={showProfilePicModal}
        setOpen={(val) => setState({ ...state, showProfilePicModal: val })}
        updateProfilePic={updateProfilePic}
        currentPhoto={user && user.profile_picture && user.profile_picture.url}
      />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pointerEvents: isProfileDoctor ? 'none' : 'auto',
          opacity: isProfileDoctor ? 0.5 : 1,
        }}
        onClick={openModalProfilePic}
        /* onClick={() => setState({ ...state, showProfilePicModal: true })} */
        disabled={isProfileDoctor}
      >
        <Avatar
          alt="Imagen"
          src={
            doctors.selectedDoctor && doctors.selectedDoctor.profile_picture
              ? doctors.selectedDoctor.profile_picture.url
              : user && user.profile_picture
              ? user.profile_picture.url
              : null
          }
          className={classes.avatar}
          style={{ cursor: !email ? 'default' : 'pointer' }}
        ></Avatar>
        <IconButton
          style={{ cursor: !email ? 'default' : 'pointer' }}
          aria-label="upload picture"
          component="span"
          className={classes.avatarButton}
        >
          <CameraAlt />
        </IconButton>
      </Box>
      <Box className={classes.formContainer}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="my-input-email" shrink>
            Email
          </InputLabel>
          <Input
            id="my-input-email"
            aria-describedby="my-helper-text"
            autoComplete="off"
            value={email}
            required
            disabled
          />
        </FormControl>
        <FormControl className={classes.formControl} error={name === ''}>
          <InputLabel htmlFor="my-input-name" shrink>
            {t('PersonalData.name')}
          </InputLabel>
          <Input
            id="my-input-name"
            aria-describedby="my-helper-text"
            autoComplete="off"
            onChange={(e) => setState({ ...state, name: e.target.value })}
            value={name}
            shrink
            required
            // disabled={isProfileDoctor ? doctors.selectDoctor === null : false}
          />
        </FormControl>
        <FormControl className={classes.formControl} error={lastName === ''}>
          <InputLabel htmlFor="my-input-lastName" shrink>
            {t('PersonalData.last-name')}
          </InputLabel>
          <Input
            id="my-input-lastName"
            aria-describedby="my-helper-text"
            autoComplete="off"
            onChange={(e) => setState({ ...state, lastName: e.target.value })}
            value={lastName}
            required
            // disabled={isProfileDoctor ? doctors.selectDoctor === null : false}
          />
        </FormControl>
        <FormControl className={classes.formControl} error={country === ''}>
          <InputLabel id="demo-simple-select-label" shrink>
            {t('PersonalData.country')}
          </InputLabel>
          <Select
            // disabled={isProfileDoctor ? doctors.selectDoctor === null : false}
            inputProps={{
              name: 'age',
              id: 'age-native-simple',
            }}
            native
            value={country}
            onChange={(e) =>
              setState({ ...state, country: Number(e.target.value) })
            }
          >
            <option aria-label="None" value="" />
            {countries &&
              countries.map((country) => {
                return <option value={country.id}>{country.name}</option>;
              })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} error={city === ''}>
          <InputLabel htmlFor="my-input" shrink>
            {t('PersonalData.city')}
          </InputLabel>
          <Input
            disabled={isProfileDoctor ? doctors.selectedDoctor === null : false}
            id="my-input"
            aria-describedby="my-helper-text"
            autoComplete="off"
            onChange={(e) => setState({ ...state, city: e.target.value })}
            value={city}
            required
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            // disabled={isProfileDoctor ? doctors.selectDoctor === null : false}
            id="date"
            label={t('PersonalData.date-of-birth')}
            type="date"
            value={dateOfBirth}
            onChange={(e) =>
              setState({ ...state, dateOfBirth: e.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
            error={!isOlderThan18(dateOfBirth)}
          />
          {!isOlderThan18(dateOfBirth) && (
            <FormHelperText error>Debe ser mayor de 18 años</FormHelperText>
          )}
        </FormControl>
        <FormControl className={classes.formControl} error={document === ''}>
          <InputLabel htmlFor="my-input-document" shrink>
            Documento *
          </InputLabel>
          <Input
            // disabled={isProfileDoctor ? doctors.selectDoctor === null : false}
            id="my-input-document"
            aria-describedby="my-helper-text"
            autoComplete="off"
            onChange={(e) => setState({ ...state, document: e.target.value })}
            value={document}
            required
          />
        </FormControl>
        <FormControl
          className={classes.formControl}
          style={{ marginTop: '1rem' }}
          error={gender === ''}
          // disabled={isProfileDoctor ? doctors.selectDoctor === null : false}
        >
          <FormLabel component="legend">{t('PersonalData.gender')}</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            onChange={(e) => setState({ ...state, gender: e.target.value })}
            value={gender}
            row
          >
            <FormControlLabel
              value="F"
              control={<Radio />}
              label={t('PersonalData.female')}
            />
            <FormControlLabel value="M" control={<Radio />} label="Masculino" />
            <FormControlLabel
              value="O"
              control={<Radio />}
              label={t('PersonalData.other')}
            />
          </RadioGroup>
        </FormControl>
        <FormControl className={classes.formControl} error={phone === ''}>
          <InputLabel htmlFor="my-input-phone" shrink>
            {t('PersonalData.phone')}
          </InputLabel>
          <Input
            // disabled={doctors.selectDoctor === null}
            id="my-input-phone"
            aria-describedby="my-helper-text"
            autoComplete="off"
            onChange={(e) => setState({ ...state, phone: e.target.value })}
            value={phone}
            required
          />
        </FormControl>
        {isDoctor && (
          <FormControl className={classes.formControl} error={license === ''}>
            <InputLabel htmlFor="my-input-license" shrink>
              {t('PersonalData.license')}
            </InputLabel>
            <Input
              // disabled={doctors.selectDoctor === null}
              id="my-input-license"
              aria-describedby="my-helper-text"
              onChange={(e) => setState({ ...state, license: e.target.value })}
              value={license}
              required
            />
          </FormControl>
        )}
        <span className={classes.comment}>{t('PersonalData.required')}</span>
      </Box>
      {(isDoctor || doctors?.selectedDoctor) && (
        <DoctariModal
          open={modalOpen}
          setOpen={(val) => setState({ ...state, modalOpen: val })}
          title={'Nueva dirección'}
        >
          <AddNewDirection
            closeModal={() => setState({ ...state, modalOpen: false })}
          />
        </DoctariModal>
      )}
      {(isDoctor || doctors?.selectedDoctor) && (
        <Box marginBottom="2rem" width="100%">
          <Box display="flex" justifyContent="space-between" marginTop="2rem">
            <span className={classes.directionTitle}>
              {t('PersonalData.address-list')}
            </span>
            <span
              className={classes.editField}
              onClick={openModalAddAddress}
              style={{
                color: !doctors.selectedDoctor ? 'grey' : '',
                cursor: !doctors.selectedDoctor ? 'default' : 'pointer',
              }}
            >
              {t('PersonalData.add-address')}
            </span>
          </Box>
          <Box
            style={{
              marginTop: '1rem',
            }}
          >
            {address.map((address) => {
              return (
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <span
                    className={classes.direction}
                  >{`${address.street} ${address.street_number} - ${address.city}, ${address.country.name}`}</span>
                  <IconButton
                    aria-label="upload picture"
                    component="span"
                    onClick={() => {
                      if (
                        session.userType == 'assistant' ||
                        session.userType == 'operator'
                      ) {
                        deleteAddressByAssistant(address.id);
                      } else {
                        deleteAddress(address.id);
                      }
                    }}
                  >
                    <RemoveCircle />
                  </IconButton>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
      <Box display="flex" justifyContent="end">
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          disabled={
            !isEnable() || (isProfileDoctor && doctors.selectedDoctor === null)
          }
        >
          {t('PersonalData.save')}
        </Button>
      </Box>
    </Box>
  );
}

export default PersonalData;
