import { call, put, takeEvery, all, retry } from 'redux-saga/effects';
import { proficientsActions, proficientsActionsTypes } from './actions';
import API from '../../API/endpoints';
import { isProfessional } from '../../utils/helpers';
import { store } from '../store';
import { userActions } from '../user/actions';

// function* getDoctors(action) {
//   yield put(doctorsActions.loading(true));
//   try {
//     const { queryParams, queryValues, page, token } = action.payload;
//     let urlParams = '';
//     if (queryParams.length > 0) {
//       for (let i = 0; i < queryParams.length; i++) {
//         urlParams += `&${queryParams[i]}=${queryValues[i]}`;
//       }
//     }

//     let [countries, specialities, doctors] = [[], [], []];
//     if (true) {
//       //TODO pasar current page al saga así veo si me traigo todo o solo los doctores, solamente en la pagina 1 tengo que traer todo
//       [countries, specialities, doctors] = yield all([
//         call(API.getCountries),
//         call(API.getSpecilities),
//         call(API.getDoctors, urlParams, token),
//       ]);
//     } else {
//       doctors = yield call(API.getDoctors, urlParams, token);
//     }

//     yield put(
//       doctorsActions.successGetDoctors({
//         ...doctors.data,
//         page,
//         countries: countries.data,
//         specialities: specialities.data,
//       })
//     );
//   } catch (e) {
//     if (e.response.status === 401) {
//       throw e;
//     }
//     yield put(doctorsActions.error('Error al traer los datos'));
//   }
//   yield put(doctorsActions.loading(false));
// }

// function* getDoctorData(action) {
//   yield put(doctorsActions.loading(true));
//   try {
//     const { id, patientId, scheduleBody, appointmentBody, token } =
//       action.payload;
//     const [doctor, schedule, prevAppointments] = yield all([
//       call(API.getUser, 'doctor', id, token),
//       call(API.getSpecilities),
//       call(API.getDoctors, scheduleBody, token),
//     ]);
//     // yield put(
//     //   doctorsActions.successGetDoctors({
//     //     ...doctors.data,
//     //     page,
//     //     countries: countries.data,
//     //     specialities: specialities.data,
//     //   })
//     // );
//   } catch (e) {
//     if (e.response.status === 401) {
//       throw e;
//     }
//     yield put(doctorsActions.error('Error al traer los datos'));
//   }
//   yield put(doctorsActions.loading(false));
// }

export function refreshToken(saga) {
  return function* (action) {
    yield put(proficientsActions.loading(true));
    const actionToken = { payload: { ...action.payload, token: true } };
    try {
      yield* saga(actionToken);
    } catch (error) {
      if (
        error.response.status === 401 &&
        error.response.data.code === 'token_not_valid'
      ) {
        const reduxStore = store;
        try {
          //Try refresh token
          const token = yield call(API.refreshToken, {
            refresh: reduxStore.getState().session.refreshToken,
          });
          yield put(
            userActions.successTokenRefresh({
              message: 'Nuevo token obtenido con éxito',
              token: token.data,
            })
          );
          yield* saga(actionToken);
        } catch (error) {
          if (
            error.response.status === 401 &&
            error.response.data.code === 'token_not_valid'
          ) {
            yield put(userActions.logoutUser()); //Refresh token expired
          }
        }
      }
    }
    yield put(proficientsActions.loading(false));
  };
}

export default function* proficientsSaga() {
  // yield takeEvery(doctorsActionsTypes.GET_DOCTORS, refreshToken(getDoctors));
  // yield takeEvery(doctorsActionsTypes.GET_DOCTOR, refreshToken(getDoctorData));
}
