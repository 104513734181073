import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  profileTabs: {
    color: 'white',
    marginTop: '5rem',
    width: '50%',
    '@media (max-width: 900px)': {
      width: '100%',
      marginTop: '2rem',
    },
  },
  avatar: {
    cursor: 'pointer',
    border: `solid 4px ${theme.palette.primary.main}`,
    width: theme.spacing(25),
    height: theme.spacing(25),
    '@media (max-width: 900px)': {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
  },
  avatarButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    marginTop: theme.spacing(23),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '@media (max-width: 900px)': {
      marginTop: theme.spacing(13),
    },
  },
  swipeableContainer: {
    fontFamily: theme.typography.fontFamily,
    width: '60%',
    marginBottom: '4rem',
    '@media (max-width: 900px)': {
      width: '100%',
    },
  },
  formContainer: {
    marginTop: '5rem',
    width: '50%',
    '@media (max-width: 900px)': {
      marginTop: '3rem',
      width: '100%',
    },
  },
  formControl: {
    fontFamily: theme.typography.fontFamily,
    marginBottom: '1rem',
    width: '100%',
  },
  selectDoctor : {
    width:'20%',
    marginRight: 'auto',
    padding :'1rem 0 0 3rem'
  },
  formControlProf: {
    fontFamily: theme.typography.fontFamily,
    marginBottom: '2rem',
    width: '100%',
    '@media (max-width: 900px)': {
      marginBottom: '1rem',
    },
  },
  comment: {
    fontFamily: theme.typography.fontFamily,
    color: 'grey',
    fontSize: '0.9em',
  },
  directionTitle: {
    color: theme.palette.primary.main,
    fontSize: '1.2em',
    fontWeight: 'bold',
  },
  editField: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontStyle: 'italic',
    fontSize: '14.4px',
    cursor: 'pointer',
  },
  extraData: {
    marginTop: '1rem',
    width: '70%',
    '@media (max-width: 900px)': {
      width: '95%',
    },
  },
  fieldDescription: {
    fontSize: '14px',
    marginLeft: '1rem',
    '@media (max-width: 900px)': {
      marginLeft: '0rem',
    },
  },
  direction: {
    fontFamily: theme.typography.fontFamily,
    color: 'black',
    fontSize: '1em',
    marginLeft: '2rem',
    '@media (max-width: 900px)': {
      marginLeft: '0rem',
    },
  },
  modalBody: {
    padding: '2rem',
    overflow: 'auto',
  },
  changePassword: {
    width: '65%',
    marginLeft: '1rem',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    width: '70%',
    '@media (max-width: 900px)': {
      width: '95%',
    },
  },
  signaturePicture: {
    width: '150px',
    height: '75px',
    border: `solid 2px ${theme.palette.primary.main}`,
    marginTop: '1rem',
    marginLeft: '1rem',
    '@media (max-width: 900px)': {
      marginLeft: '0rem',
    },
  },
}));
