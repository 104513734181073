import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    height: '5rem',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    height: '3rem',
    '@media (max-width: 900px)': {
      height: '2.5rem',
    },
  },
  headerLogin: {
    backgroundColor: theme.palette.primary.main,
    '@media (max-width: 900px)': {
      paddingLeft: 0,
    },
  },
  menuButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    marginRight: '2rem',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerContainer: {
    padding: '10px 20px',
  },
  menuContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%',
    fontFamily: theme.typography.fontFamily,
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: '2%',
    ['@media (max-width: 900px)']: {
      width: '100%',
      justifyContent: 'center',
      flexDirection: 'column',
      marginBottom: '2%',
    },
  },

  formConteiner: {
    width: '50%',
    ['@media (max-width: 900px)']: {
      width: '100%',
    },
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'center',
    ['@media (max-width: 900px)']: {
      width: '100%',
      marginTop: '5%',
      marginBottom: '5%',
      display: 'none',
    },
  },
  mpContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    verticalAlign: 'center',
  },

  paperContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    marginBottom: '5%',
    verticalAlign: 'center',
  },
  firstPaperContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  title: {
    color: theme.palette.primary.main,
    marginTop: '40px',
  },
  paperGridItems: {
    marginBottom: '2%',
  },
  buttons: {
    marginRight: '10px',
    ['@media (max-width: 900px)']: {
      width: '100%',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: '5%',
    },
  },
}));
