import React, { useState, useEffect } from 'react';
import moment from 'moment';
import SwipeableViews from 'react-swipeable-views';
import { Grid, Paper, Box, Tabs, Tab, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PatientForm from './PatientForm';
import ProfessionalForm from './ProfessionalForm';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/helpers';

function SignUpForm({
  onSignup,
  goLogin,
  classes,
  getInitialData,
  countries,
  specialities,
  validateEmail,
}) {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const [t, i18n] = useTranslation('global');

  useEffect(() => {
    getInitialData();
  }, []);

  const isOlderThan18 = (dateOfBirth) => {
    const today = moment();
    const diff = today.diff(moment(dateOfBirth), 'years');
    return diff >= 18;
  };

  const TabPanel = ({ children, value, index, ...other }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Grid
      container
      alignItems="center"
      style={{ backgroundColor: '#f1f1f1' }}
      justify="center"
    >
      <Grid
        item
        xs={11}
        md={4}
        xl={3}
        style={{ marginTop: '7rem', marginBottom: '2rem' }}
      >
        <Paper elevation={3}>
          <Box p={'7%'} display="flex" flexDirection="column">
            <Grid
              item
              xs={12}
              md={12}
              style={{
                marginBottom: '0.5rem',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span className={classes.H5}>{`${capitalize(
                t('word.registration')
              )}`}</span>
              <span className={classes.H4}>{`${t(
                'PersonalData.required'
              )}`}</span>
            </Grid>
            <Paper color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="secondary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="PACIENTE" {...a11yProps(0)} />
                <Tab
                  label={`${t('word.professional').toUpperCase()}`}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Paper>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <PatientForm
                  classes={classes}
                  countries={countries}
                  validateEmail={validateEmail}
                  isOlderThan18={isOlderThan18}
                  onSignup={onSignup}
                />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <ProfessionalForm
                  countries={countries}
                  specialities={specialities}
                  classes={classes}
                  validateEmail={validateEmail}
                  onSignup={onSignup}
                  isOlderThan18={isOlderThan18}
                />
              </TabPanel>
            </SwipeableViews>
          </Box>
        </Paper>
        <Box className={classes.helpText}>
          <span>{`${t('sentence.register')}`} </span>
          <Link style={{ cursor: 'pointer' }} onClick={goLogin}>
            {`${capitalize(t('word.getInto'))}`}
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
}

export default SignUpForm;
