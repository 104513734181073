import axios from 'axios';
import { store } from '../redux/store';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

axiosInstance.interceptors.request.use(async (config) => {
  const reduxStore = store;
  const headerType = config.contentType
    ? 'multipart/form-data'
    : 'application/json';

  if (config.needToken) {
    config.headers = {
      'Content-Type': headerType,
      Authorization: `Bearer ${reduxStore.getState().session.accessToken}`,
      'Accept-Language': `${
        localStorage.getItem('language') == 'es' ? 'es' : 'pt-br'
      }`,
    };
  } else {
    config.headers = {
      'Content-Type': headerType,
      'Accept-Language': `${
        localStorage.getItem('language') == 'es' ? 'es' : 'pt-br'
      }`,
    };
  }
  return config;
});

export default axiosInstance;
