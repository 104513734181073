import { useEffect } from 'react';
import { connect } from 'react-redux';

import Patient from './Patient';
import Doctor from './Doctor';
import { doctorsActionsTypes } from '../../redux/doctor/actions';
import { appointmentActionsTypes } from '../../redux/appointment/actions';
import { useStyles } from './style';
import { userActionsTypes } from '../../redux/user/actions';
import Assistant from './Assistant';
import { proficientsActionsTypes } from '../../redux/proficient/actions';
import Proficient from './Proficient';
import Operator from './Operator';

function Appointments({
  session,
  doctorsInfo,
  appointmentsData,
  deleteMessages,
  getDoctorsInfo,
  createScheduleRequests,
  getSchedulesByDate,
  getAppointments,
  clearAppointments,
  getUserData,
  getNewAccessToken,
  userState,
  setSelectedDoctor,
  clearSchedules,
  clearSelectedDoctor,
  getAppointmentsLight,
  getAppointmentsAll,
  setSelectedProficient,
  clearSelectedProficient,
  proficientsInfo,
  getAppointmentsProficient,
  getProficientDates,
}) {
  const classes = useStyles();

  // It is set by default to the time zone of the server
  // moment.tz.setDefault('America/Montevideo');

  useEffect(() => {
    if (session) {
      getUserData(session.userType, session.id);
    }
    return () => {
      deleteMessages();
    };
  }, []);

  return (
    <>
      {session.userType == 'assistant' ? (
        <Assistant
          classes={classes}
          doctorsInfo={doctorsInfo}
          appointmentsData={appointmentsData}
          deleteMessages={deleteMessages}
          getDoctorsInfo={getDoctorsInfo}
          createScheduleRequests={createScheduleRequests}
          session={session}
          getSchedulesByDate={getSchedulesByDate}
          getAppointments={getAppointments}
          clearAppointments={clearAppointments}
          getUserData={getUserData}
          getNewAccessToken={getNewAccessToken}
          userState={userState}
          setSelectedDoctor={setSelectedDoctor}
          setSelectedProficient={setSelectedProficient}
          clearSchedules={clearSchedules}
          clearSelectedDoctor={clearSelectedDoctor}
          clearSelectedProficient={clearSelectedProficient}
        />
      ) : session.userType == 'doctor' ? (
        <Doctor
          classes={classes}
          doctorsInfo={doctorsInfo}
          appointmentsData={appointmentsData}
          deleteMessages={deleteMessages}
          getDoctorsInfo={getDoctorsInfo}
          createScheduleRequests={createScheduleRequests}
          doctor={session}
          getSchedulesByDate={getSchedulesByDate}
          getAppointments={getAppointmentsAll}
          clearAppointments={clearAppointments}
          getUserData={getUserData}
          getNewAccessToken={getNewAccessToken}
          userState={userState}
        />
      ) : session.userType == 'operator' ? (
        <Operator
          classes={classes}
          doctorsInfo={doctorsInfo}
          appointmentsData={appointmentsData}
          deleteMessages={deleteMessages}
          getDoctorsInfo={getDoctorsInfo}
          createScheduleRequests={createScheduleRequests}
          session={session}
          getSchedulesByDate={getSchedulesByDate}
          getAppointments={getAppointments}
          clearAppointments={clearAppointments}
          getUserData={getUserData}
          getNewAccessToken={getNewAccessToken}
          userState={userState}
          setSelectedDoctor={setSelectedDoctor}
          clearSchedules={clearSchedules}
          clearSelectedDoctor={clearSelectedDoctor}
          clearSelectedProficient={clearSelectedProficient}
          setSelectedProficient={setSelectedProficient}
        />
      ) : session.userType == 'patient' ? (
        <Patient
          classes={classes}
          doctorsInfo={doctorsInfo}
          appointmentsData={appointmentsData}
          deleteMessages={deleteMessages}
          getDoctorsInfo={getDoctorsInfo}
          createScheduleRequests={createScheduleRequests}
          patient={session}
          getAppointments={getAppointments}
          clearAppointments={clearAppointments}
          getUserData={getUserData}
          getNewAccessToken={getNewAccessToken}
          userState={userState}
        />
      ) : (
        <Proficient
          classes={classes}
          proficientsInfo={proficientsInfo}
          appointmentsData={appointmentsData}
          deleteMessages={deleteMessages}
          getDoctorsInfo={getDoctorsInfo}
          createScheduleRequests={createScheduleRequests}
          proficient={session}
          getSchedulesByDate={getSchedulesByDate}
          getAppointments={getAppointmentsProficient}
          clearAppointments={clearAppointments}
          getUserData={getUserData}
          getNewAccessToken={getNewAccessToken}
          userState={userState}
          setSelectedProficient={setSelectedProficient}
          getProficientDates={getProficientDates}
        />
      )}
    </>
  );
}

const mapStateToProps = ({
  session,
  doctors,
  appointments,
  user,
  proficients,
}) => {
  return {
    doctorsInfo: doctors, // doctors
    session,
    appointmentsData: appointments,
    userState: user, //userData
    proficientsInfo: proficients,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDoctorsInfo: (queryParams, queryValues) =>
    dispatch({
      type: doctorsActionsTypes.GET_DOCTORS,
      payload: { queryParams, queryValues },
    }),
  getScheduleRequests: (queryId) =>
    dispatch({
      type: appointmentActionsTypes.GET_SCHEDULE_REQUESTS,
      payload: { queryId },
    }),
  createScheduleRequests: (body, token) =>
    dispatch({
      type: appointmentActionsTypes.CREATE_SCHEDULE_REQUESTS,
      payload: { body, token },
    }),
  clearAppointments: () =>
    dispatch({ type: appointmentActionsTypes.CLEAR_APPOINTMENTS }),
  deleteMessages: () =>
    dispatch({
      type: appointmentActionsTypes.DELETE_MESSAGES,
    }),

  getAppointments: (userType, id, isDone, queryParams, queryValues) =>
    dispatch({
      type: appointmentActionsTypes.GET_APPOINTMENTS, //TODO cambiar el formato de fechas en el back para no tener que hacer todo este juego con format
      payload: {
        userType,
        id,
        isDone,
        queryParams,
        queryValues,
      },
    }),

  getAppointmentsLight: (userType, id, isDone, queryParams, queryValues) =>
    dispatch({
      type: appointmentActionsTypes.GET_APPOINTMENTS_LIGHT, //TODO cambiar el formato de fechas en el back para no tener que hacer todo este juego con format
      payload: {
        userType,
        id,
        isDone,
        queryParams,
        queryValues,
      },
    }),
  getAppointmentsAll: (userType, id, isDone, queryParams, queryValues) =>
    dispatch({
      type: appointmentActionsTypes.GET_APPOINTMENTS_ALL, //TODO cambiar el formato de fechas en el back para no tener que hacer todo este juego con format
      payload: {
        userType,
        id,
        isDone,
        queryParams,
        queryValues,
      },
    }),

  getSchedulesByDate: (start, end, queryParams, queryValues) =>
    dispatch({
      type: appointmentActionsTypes.GET_SCHEDULES_BY_DATE, //TODO cambiar el formato de fechas en el back para no tener que hacer todo este juego con format
      payload: {
        start,
        end,
        queryParams,
        queryValues,
      },
    }),
  getUserData: (type, id) =>
    dispatch({ type: userActionsTypes.GET_USER, payload: { type, id } }),

  getNewAccessToken: (token) =>
    dispatch({
      type: userActionsTypes.REFRESH_TOKEN,
      payload: token,
    }),

  setSelectedDoctor: (doctorBody) =>
    dispatch({
      type: doctorsActionsTypes.SET_DOCTOR_BODY,
      payload: doctorBody,
    }),

  clearSchedules: () =>
    dispatch({ type: appointmentActionsTypes.CLEAR_SHEDULES }),

  clearSelectedDoctor: () =>
    dispatch({ type: doctorsActionsTypes.CLEAR_SELECT_DOCTOR }),

  setSelectedProficient: (proficientBody) =>
    dispatch({
      type: proficientsActionsTypes.SET_PROFICIENT_BODY,
      payload: proficientBody,
    }),

  clearSelectedProficient: () =>
    dispatch({ type: proficientsActionsTypes.CLEAR_SELECT_PROFICIENT }),

  getAppointmentsProficient: (userType, id, isDone, queryParams, queryValues) =>
    dispatch({
      type: appointmentActionsTypes.GET_APPOINTMENTS_PROFICIENT, //TODO cambiar el formato de fechas en el back para no tener que hacer todo este juego con format
      payload: {
        userType,
        id,
        isDone,
        queryParams,
        queryValues,
      },
    }),

  getProficientDates: (start, end, proficientId) =>
    dispatch({
      type: appointmentActionsTypes.GET_PROFICIENT_DATES,
      payload: {
        start,
        end,
        proficientId,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
