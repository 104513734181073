import { appointmentActionsTypes } from './actions';

const APPOINTMENTS_INITIAL_STATE = {
  appointments: [],
  scheduleRequests: [],
  // allergies, medications and diseases are used to create appointment light
  allergies: [],
  medications: [],
  diseases: [],
  numOfPages: 0,
  currentPage: 1,
  error: null,
  success: {
    uploadFiles: null,
    scheduleRequests: null,
    genericMessage: null,
  },
  // successScheduleRequest: null,
  // succcessAttachmentUpload: null,
  appointmentCreated: null,
  loading: false,
  proficientDates: {},
};

const appointmentsReducer = (state = APPOINTMENTS_INITIAL_STATE, actions) => {
  let new_state;
  switch (actions.type) {
    case appointmentActionsTypes.SUCCESS_GET_APPOINTMENTS:
      let numOfPages = Math.ceil(actions.payload.count / 10);
      new_state = {
        ...state,
        error: null,
        numOfPages: numOfPages,
        appointments: actions.payload.results,
      };
      break;
    case appointmentActionsTypes.SUCCESS_CREATE_APPOINTMENT:
      new_state = {
        ...state,
        success: {
          ...state.success,
          genericMessage: actions.payload.message,
        },
        appointmentCreated: actions.payload.data,
      };
      break;
    case appointmentActionsTypes.SUCCESS_UPDATE_APPOINTMENT:
      new_state = {
        ...state,
        success: {
          ...state.success,
          genericMessage: actions.payload.message,
        },
        appointmentUpdated: actions.payload.data,
      };
      break;
    case appointmentActionsTypes.SUCCESS_DELETE_APPOINTMENT:
      new_state = {
        ...state,
        error: null,
        success: {
          ...state.success,
          genericMessage: actions.payload,
        },
      };
      break;
    case appointmentActionsTypes.SUCCESS_GET_SCHEDULE_REQUESTS:
      new_state = {
        ...state,
        error: null,
        scheduleRequests: actions.payload,
      };
      break;
    case appointmentActionsTypes.SUCCESS_UPDATE_SCHEDULE_REQUESTS:
      new_state = {
        ...state,
        error: null,
        success: {
          ...state.success,
          genericMessage: actions.payload,
        },
      };
      break;
    case appointmentActionsTypes.SUCCESS_CREATE_SCHEDULE:
      new_state = {
        ...state,
        error: null,
        success: {
          ...state.success,
          genericMessage: actions.payload.message,
        },
        scheduleCreated: actions.payload.data,
      };
      break;
    case appointmentActionsTypes.SUCCESS_DELETE_SCHEDULE:
      new_state = {
        ...state,
        error: null,
        success: {
          ...state.success,
          genericMessage: actions.payload,
        },
        scheduleCreated: null,
      };
      break;
    case appointmentActionsTypes.SUCCESS_GET_SCHEDULES:
      new_state = {
        ...state,
        error: null,
        schedules: actions.payload,
      };
      break;
    case appointmentActionsTypes.SUCCESS_SCHEDULE_REQUESTS_ACTION:
      new_state = {
        ...state,
        success: {
          ...state.success,
          genericMessage: actions.payload,
        },
      };
      break;
    case appointmentActionsTypes.SUCCESS_UPLOAD_APPOINTMENT_FILES:
      new_state = {
        ...state,
        success: {
          ...state.success,
          uploadFiles: actions.payload,
        },
      };
      break;
    case appointmentActionsTypes.SUCCESS_DELETE_APPOINTMENT_FILE:
      new_state = {
        ...state,
        error: null,
        success: {
          ...state.success,
          genericMessage: actions.payload,
        },
      };
      break;

    case appointmentActionsTypes.SUCCESS_ACCEPT_SCHEDULE_REQUESTS:
      new_state = {
        ...state,
        success: {
          ...state.success,
          scheduleRequests: actions.payload,
        },
      };
      break;
    case appointmentActionsTypes.CLEAR_APPOINTMENTS:
      new_state = {
        ...state,
        appointments: [],
      };
      break;
    case appointmentActionsTypes.DELETE_MESSAGES:
      new_state = {
        ...state,
        success: {
          ...state.success,
          genericMessage: null,
          scheduleRequests: null,
          uploadFiles: null,
        },
        appointmentCreated: null,
        error: null,
      };
      break;
    case appointmentActionsTypes.DELETE_ATTACHMENT_MESSAGES:
      new_state = {
        ...state,
        success: {
          ...state.success,
          scheduleRequests: null,
          uploadFiles: null,
        },
        error: null,
      };
      break;
    case appointmentActionsTypes.LOADING:
      new_state = {
        ...state,
        loading: actions.payload,
        error: actions.payload ? null : state.error,
      };
      break;
    case appointmentActionsTypes.ERROR:
      new_state = {
        ...state,
        error: actions.payload,
      };
      break;
    case appointmentActionsTypes.CLEAR_SHEDULES:
      new_state = {
        ...state,
        schedules: null,
      };
      break;
    case appointmentActionsTypes.CLEAR_SCHEDULE_REQUESTS:
      new_state = {
        ...state,
        scheduleRequests: null,
      };
      break;
    case appointmentActionsTypes.ADD_INFORMATION_APPOINTMENT:
      new_state = {
        ...state,
        error: null,
        [actions.payload.type]: state[actions.payload.type].concat(
          actions.payload.newValue
        ),
      };
      break;
    case appointmentActionsTypes.SUCCESS_CREATE_INFORMATION_APPOINTMENT:
      new_state = {
        ...state,
        error: null,
        [actions.payload.type]: state[actions.payload.type].concat(
          actions.payload.value
        ),
      };
      break;
    case appointmentActionsTypes.DELETE_INFORMATION_APPOINTMENT:
      new_state = {
        ...state,
        error: null,
        [actions.payload.type]: state[actions.payload.type].filter(
          (val) => val.id !== actions.payload.id
        ),
      };
      break;
    case appointmentActionsTypes.CLEAR_INFORMATIONS_APPOINTMENT:
      new_state = {
        ...state,
        allergies: [],
        medications: [],
        diseases: [],
      };
      break;
    case appointmentActionsTypes.SUCCESS_UPDATE_APPOINTMENT_LIGHT_OR_PROFICIENT:
      new_state = {
        ...state,
        error: null,
        success: {
          ...state.success,
          genericMessage: actions.payload,
        },
      };
      break;
    case appointmentActionsTypes.SUCCESS_RESEND_CODE:
      new_state = {
        ...state,
        error: null,
        success: {
          ...state.success,
          genericMessage: actions.payload,
        },
      };
      break;
    case appointmentActionsTypes.SUCCESS_GET_PROFICIENT_DATES:
      new_state = {
        ...state,
        error: null,
        proficientDates: actions.payload,
      };
      break;
    default:
      new_state = state;
  }
  return new_state;
};

export { appointmentsReducer };
