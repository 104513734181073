import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Box, Input, Button } from '@material-ui/core';
import AutocompleteInfo from '../../components/AutocompleteInfo';
import {useTranslation} from 'react-i18next'

function MedicalData({ classes, addUserInfo, userData, updateUser, session }) {
  const [state, setState] = useState({
    weight: null,
    height: null,
  });
  const [t, i18n] = useTranslation('global')
  const { weight, height } = state;
  const { user, medications, allergies, diseases } = userData;
  useEffect(() => {
    if (user) {
      setState({
        ...state,
        weight: user.weight,
        height: user.height,
      });
    }
  }, [user]);

  const onSave = () => {
    const body = {
      weight,
      height,
    };
    updateUser(session.userType, user.id, body, null);
  };
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      padding='1rem'
    >
      <Box className={classes.formContainer} style={{ marginTop: '1rem' }}>
        <Box style={{ display: 'flex' }}>
          <FormControl
            className={classes.formControl}
            style={{ marginRight: '1rem' }}
            error={weight && typeof weight !== 'number'}
          >
            <InputLabel htmlFor='my-input-weight' shrink>
              Peso (kg)*
            </InputLabel>
            <Input
              id='my-input-weight'
              onChange={(e) =>
                setState({
                  ...state,
                  weight: parseInt(e.target.value, 10),
                })
              }
              value={weight}
              required
              type='number'
            />
          </FormControl>
          <FormControl
            className={classes.formControl}
            error={height && typeof height !== 'number'}
          >
            <InputLabel htmlFor='my-input-height' shrink>
              Altura (cm)*
            </InputLabel>
            <Input
              id='my-input-height'
              onChange={(e) =>
                setState({
                  ...state,
                  height: parseInt(e.target.value, 10),
                })
              }
              value={height}
              required
              type='number'
            />
          </FormControl>
        </Box>
        <AutocompleteInfo
          label={'Medicamentos'}
          optionList={medications}
          valuesSelected={user ? user.medications : []}
          setNewValue={(med) => {
            const isNew = true;
            addUserInfo.handleUserMedication(isNew, med);
          }}
          addValue={(med) => {
            const isNew = false;
            addUserInfo.handleUserMedication(isNew, med);
          }}
          deleteValue={(med) => {
            const isNew = false;
            const isForDelete = true;
            addUserInfo.handleUserMedication(isNew, med, isForDelete);
          }}
        />
        <AutocompleteInfo
          label={'Alergias'}
          optionList={allergies}
          valuesSelected={user ? user.allergies : []}
          setNewValue={(allergie) => {
            const isNew = true;
            addUserInfo.handleUserAllergie(isNew, allergie);
          }}
          addValue={(allergie) => {
            const isNew = false;
            addUserInfo.handleUserAllergie(isNew, allergie);
          }}
          deleteValue={(allergie) => {
            const isNew = false;
            const isForDelete = true;
            addUserInfo.handleUserAllergie(isNew, allergie, isForDelete);
          }}
        />
        <AutocompleteInfo
          label={t('MedicalData.diseases')}
          optionList={diseases}
          valuesSelected={user ? user.diseases : []}
          setNewValue={(disease) => {
            const isNew = true;
            addUserInfo.handleUserDisease(isNew, disease);
          }}
          addValue={(disease) => {
            const isNew = false;
            addUserInfo.handleUserDisease(isNew, disease);
          }}
          deleteValue={(disease) => {
            const isNew = false;
            const isForDelete = true;
            addUserInfo.handleUserDisease(isNew, disease, isForDelete);
          }}
        />
      </Box>
      <Box display='flex' justifyContent='center' marginTop='2rem'>
        <Button variant='contained' color='primary' onClick={onSave}>
         {t('MedicalData.save')}
        </Button>
      </Box>
    </Box>
  );
}

export default MedicalData;
