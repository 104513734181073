import React from 'react';
import moment from 'moment';
import { Grid, Box } from '@material-ui/core';

import References from '../../components/DoctariCalendar/References';
import { mobileScreen } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';


function MonthCalendar({ currMonth, goToWeek, classes }) {
  const isMobile = mobileScreen();
  const [t, i18n] = useTranslation('global');


  const nameOfDays = isMobile
    ? [t('calendarDays.monday'), t('calendarDays.tuesday'), t('calendarDays.wednesday'), t('calendarDays.thursday'), t('calendarDays.friday'), 'Sáb', 'Dom']
    : [
      t('EditScheduleModal.monday'),
      t('EditScheduleModal.tuesday'),
      t('EditScheduleModal.wednesday'),
      t('EditScheduleModal.thursday'),
      t('EditScheduleModal.friday'),
      'Sábado',
      'Domingo',
    ];

  const getStartDay = () => {
    let start = 0;
    const daysOfWeek = [
      t('EditScheduleModal.monday'),
      t('EditScheduleModal.tuesday'),
      t('EditScheduleModal.wednesday'),
      t('EditScheduleModal.thursday'),
      t('EditScheduleModal.friday'),
      'Sábado',
      'Domingo',
    ];
    for (let i = 0; i < 7; i++) {
      if (
        daysOfWeek[i].toLowerCase() ===
        moment().add(currMonth, 'month').startOf('month').format('dddd')
      ) {
        start = i;
        break;
      }
    }
    return start;
  };

  const lastMonthDays = (number) => {
    const days = [];
    const daysOfLastMonth = moment()
      .subtract(currMonth + 1, 'month')
      .daysInMonth();
    for (let i = number - 1; i >= 0; i--) {
      days.push(daysOfLastMonth - i);
    }
    return days;
  };

  return (
    <Grid
      item
      xs={12}
      md={12}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box className={classes.monthContainer}>
        {nameOfDays.map((day) => {
          return (
            <Box
              className={classes.monthItem}
              style={{
                marginTop: 'initial',
                border: '1px solid white',
                margin: '0.5rem 0',
                height: 'auto',
              }}
            >
              <span className={classes.dayText}>{day}</span>
            </Box>
          );
        })}
        {lastMonthDays(getStartDay()).map((num) => {
          return (
            <Box
              className={classes.monthItem}
              style={{
                background: '#d3d3d363',
              }}
            >
              <span style={{ marginTop: '5px', color: 'gray' }}>{num}</span>
            </Box>
          );
        })}
        {[...Array(moment().add(currMonth, 'month').daysInMonth()).keys()].map(
          (num) => {
            return (
              <Box
                className={classes.monthItem}
                style={{
                  cursor: 'pointer',
                }}
                onClick={() =>
                  goToWeek(
                    moment()
                      .add(currMonth, 'month')
                      .set('date', num + 1)
                      .diff(moment(), 'weeks')
                  )
                }
              >
                <span style={{ marginTop: '5px' }}>{num + 1}</span>
              </Box>
            );
          }
        )}
        <Box style={{ width: '100%', display: 'flex' }}>
          <References />
        </Box>
      </Box>
    </Grid>
  );
}

export default MonthCalendar;
