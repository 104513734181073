import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Grid,
  Button,
  Paper,
  Select,
  FormControl,
  Input,
  InputLabel,
  Box,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import DoctorCard from '../../components/Cards/DoctorCard';
import LinearLoader from '../../components/LinearLoader';
import Notification from '../../components/Notification';
import { MyAppointments } from './MyAppointments';
import { useTranslation } from 'react-i18next';

function Patient({
  patient,
  classes,
  doctorsInfo,
  appointmentsData,
  deleteMessages,
  getDoctorsInfo,
  createScheduleRequests,
  getAppointments,
  clearAppointments,
  getNewAccessToken,
  userState,
}) {
  const theme = useTheme();
  const [t, i18n] = useTranslation('global');

  const [state, setState] = useState({
    dateOfAppointment: null,
    doctorName: null,
    specialty: null,
    country: null,
    type: null,
    currentPage: 1,
    availability: true,
    score: false,
  });
  const {
    dateOfAppointment,
    doctorName,
    specialty,
    country,
    type,
    availability,
    score,
    currentPage,
  } = state;
  const { countries, specialities, numOfPages, doctors } = doctorsInfo;
  const [appointmentLoading, setAppointmentLoading] = useState(true);
  const [specialtiesSet, setSpecialtiesSet] = useState([]);
  const [countriesSet, setCountriesSet] = useState([]);

  useEffect(() => {
    searchDoctors();
  }, [specialty, country, type, dateOfAppointment, doctorName, currentPage]);

  useEffect(() => {
    if (patient && patient.id) {
      getDoctorsInfo([], [], currentPage, patient.accessToken);
      let queryParams = ['date__gt', 'is_cancelled'];
      let queryValues = [
        moment().subtract(30, 'minutes').format('YYYY-MM-DD HH:mm'),
        false,
      ];

      getAppointments(
        'patient',
        patient.id,
        false, //isDone
        queryParams,
        queryValues
      );
    }
    return () => clearAppointments();
  }, []);

  useEffect(() => {
    if (
      appointmentsData.appointments &&
      appointmentsData.appointments.length >= 0
    ) {
      setAppointmentLoading(false);
    }
  }, [appointmentsData.appointments]);

  const searchDoctors = () => {
    const queryParams = [];
    const queryValues = [];
    if (currentPage) {
      queryParams.push('page');
      queryValues.push(currentPage);
    }
    if (specialty) {
      queryParams.push('specialty');
      queryValues.push(specialty);
    }
    if (country) {
      queryParams.push('country');
      queryValues.push(country);
    }
    if (doctorName) {
      queryParams.push('full_name__icontains');
      queryValues.push(doctorName);
    }
    if (dateOfAppointment) {
      queryParams.push('date_start');
      queryValues.push(dateOfAppointment);
    }
    if (type) {
      queryParams.push('type');
      queryValues.push(type === 'f2f' ? 3 : 2); // face_to_face = 3. online = 2
    }
    getDoctorsInfo(queryParams, queryValues);
  };

  const sort = () => {
    setState({ ...state, score: true, availability: false });
    doctors.sort((a, b) => {
      if (!a.score) {
        return 1;
      }
      if (!b.score) {
        return -1;
      }
      if (a.score == b.score) {
        return 0;
      }
      if (a.score > b.score) {
        return -1;
      }
      return 1;
    });
  };

  const sortDate = () => {
    setState({ ...state, score: false, availability: true });
    doctors.sort((a, b) => {
      if (!a.next_available_hour) {
        return 1;
      }
      if (!b.next_available_hour) {
        return -1;
      }
      if (a.next_available_hour && b.next_available_hour) {
        if (a.next_available_hour.start == b.next_available_hour.start) {
          return 0;
        }
        if (a.next_available_hour.start < b.next_available_hour.start) {
          return -1;
        }
        return 1;
      }
    });
  };

  return (
    <>
      {doctorsInfo.loading && <LinearLoader />}
      {appointmentsData.success.genericMessage ==
        'Appointment creado con exito' && (
        <Notification
          type="success"
          message={`Consulta ${t('word.created')} ${t('prep.with')} ${t(
            'word.success'
          )}`}
          onCloseCallback={deleteMessages}
        />
      )}
      {appointmentsData.success.genericMessage ==
        'Appointment eliminado con exito' && (
        <Notification
          type="error"
          message={'Consulta cancelada'}
          onCloseCallback={deleteMessages}
        />
      )}
      {appointmentsData.success.genericMessage ==
        'Appointment actualizado con exito' && (
        <Notification
          type="success"
          message={`Consulta ${t('word.updated')}`}
          onCloseCallback={deleteMessages}
        />
      )}
      {appointmentsData.error && (
        <Notification
          type="error"
          message={`${t('sentence.errorNotification2')}`}
          onCloseCallback={deleteMessages}
        />
      )}
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ flexWrap: 'initial' }}
      >
        <MyAppointments
          appointmentData={appointmentsData}
          theme={theme}
          isProfessional={false}
          classes={classes}
          session={patient}
          getNewAccessToken={getNewAccessToken}
          userState={userState}
          appointmentLoading={appointmentLoading}
        ></MyAppointments>

        <h1 className={classes.title}>{t('Patient.new-appointment')}</h1>
        <Paper
          className={classes.formContainer}
          elevation={2}
          style={{ padding: '1rem' }}
        >
          <span style={{ fontWeight: 300 }}>{t('Patient.search-doctor')}</span>
          <FormControl className={classes.formControl}>
            <InputLabel id="specialty-simple-select-label">
              {t('Patient.specialty')}
            </InputLabel>
            <Select
              inputProps={{
                name: 'specialty',
                id: 'specialty-native-simple',
              }}
              native
              value={specialty}
              onChange={(e) =>
                setState({ ...state, specialty: e.target.value })
              }
            >
              <option aria-label="None" value="" />
              {specialities &&
                specialities.map((specialty) => {
                  return <option value={specialty.id}>{specialty.name}</option>;
                })}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">País</InputLabel>
            <Select
              inputProps={{
                name: 'age',
                id: 'age-native-simple',
              }}
              native
              value={country}
              onChange={(e) =>
                setState({ ...state, country: Number(e.target.value) })
              }
            >
              <option aria-label="None" value="" />
              {countries &&
                countries.map((country) => {
                  return <option value={country.id}>{country.name}</option>;
                })}
            </Select>
          </FormControl>
          {/* <FormControl className={classes.formControl}>
            <TextField
              id='date'
              label='Fecha de consulta'
              type={!dateOfAppointment ? 'text' : 'date'}
              value={dateOfAppointment}
              onChange={(e) => {
                setState({ ...state, dateOfAppointment: e.target.value });
              }}
              onFocus={(e) => (e.target.type = 'date')}
              onBlur={(e) =>
                (e.target.type = !dateOfAppointment ? 'text' : 'date')
              }
              inputProps={{
                min: moment().format('YYYY-MM-DD'),
              }}
            />
          </FormControl> */}
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="my-input-doctorName">
              {t('Patient.name')}
            </InputLabel>
            <Input
              id="my-input-doctorName"
              aria-describedby="my-helper-text"
              onChange={(e) => {
                setState({ ...state, doctorName: e.target.value });
              }}
              value={doctorName}
              autoComplete="off"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              Tipo de consulta
            </InputLabel>
            <Select
              inputProps={{
                name: 'type',
                id: 'type-native-simple',
              }}
              native
              value={type}
              onChange={(e) => setState({ ...state, type: e.target.value })}
            >
              <option aria-label="None" value="" />
              <option value="online">Online</option>
              <option value="f2f">{t('Patient.f2f')}</option>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={() => {
              setState({
                ...state,
                dateOfAppointment: '',
                doctorName: '',
                specialty: '',
                country: '',
                type: '',
              });
            }}
            style={{
              color: theme.palette.primary.main,
              backgroundColor: 'white',
              width: '8rem',
            }}
          >
            Reiniciar
          </Button>
        </Paper>
        <Box className={classes.sortButtons}>
          <Box>
            <span>{t('Patient.sort')}</span>
            <Button
              variant="outlined"
              size="medium"
              onClick={sortDate}
              style={{
                color: availability ? 'white' : 'black',
                backgroundColor: availability
                  ? theme.palette.lightPrimary.main
                  : 'white',
                borderColor: availability
                  ? theme.palette.lightPrimary.main
                  : 'black',
                textTransform: 'none',
                marginLeft: '0.5rem',
                borderRadius: '2rem',
              }}
            >
              {t('Patient.available-date')}
            </Button>
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              onClick={sort}
              style={{
                color: score ? 'white' : 'black',
                backgroundColor: score
                  ? theme.palette.lightPrimary.main
                  : 'white',
                borderColor: score ? theme.palette.lightPrimary.main : 'black',
                textTransform: 'none',
                marginLeft: '0.5rem',
                borderRadius: '2rem',
              }}
            >
              {t('Patient.rating')}
            </Button>
          </Box>
          <Box className={classes.availabilityContainer}>
            <div
              style={{
                backgroundColor: '#d2effd',
                border: '2px solid #33aff9',
                ...theme.circle,
              }}
            />
            <span className={classes.typeAvailability}>
              {t('Patient.available-online')}
            </span>
            <div
              style={{
                backgroundColor: '#d4d6fd',
                border: '2px solid #344cf8',
                ...theme.circle,
              }}
            />
            <span className={classes.typeAvailability}>
              {t('Patient.available')}
            </span>
          </Box>
        </Box>
        <Grid
          item
          xs={10}
          md={6}
          style={{
            width: '98%',
            marginTop: '1rem',
          }}
        >
          {doctors && (
            <Box>
              {doctors.map((doc) => {
                return (
                  <DoctorCard
                    doctor={doc}
                    createScheduleRequests={(requestReason) =>
                      createScheduleRequests(
                        {
                          patient: patient.id,
                          doctor: doc.id,
                          request_reason: requestReason,
                        },
                        patient.token
                      )
                    }
                  />
                );
              })}
            </Box>
          )}
          {numOfPages > 0 && (
            <Pagination
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
              count={numOfPages}
              onChange={(_, page) => {
                if (currentPage !== page) {
                  setState({ ...state, currentPage: page });
                }
              }}
              variant="outlined"
              color="primary"
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Patient;
