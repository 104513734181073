import React, { useState } from 'react';
import moment from 'moment';
import {
  Grid,
  Button,
  FormControl,
  Input,
  InputLabel,
  Select,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/helpers';

function ProfessionalForm({
  onSignup,
  classes,
  validateEmail,
  countries,
  specialities,
  isOlderThan18,
}) {
  const [state, setState] = useState({
    name: null,
    lastName: null,
    country: null,
    document: null,
    email: null,
    dateOfBirth: moment().add(-18, 'years').format('YYYY-MM-DD'),
    phone: null,
    license: null,
    specialty: null,
    city: null,
    gender: null,
  });

  const {
    name,
    lastName,
    country,
    document,
    email,
    dateOfBirth,
    specialty,
    phone,
    license,
    city,
    gender,
  } = state;
  const [t, i18n] = useTranslation('global');

  const signUp = () => {
    const body = {
      email,
      first_name: name,
      last_name: lastName,
      identity_document: document,
      date_of_birth: moment(dateOfBirth).format('YYYY-MM-DD'),
      gender,
      telephone: phone,
      licence_number: license,
      city,
      specialty,
    };
    onSignup('doctor', body);
  };

  const isEnable = () => {
    let isEnable = true;
    let obj = null;
    for (const prop in state) {
      obj = state[prop];
      if (obj && prop === 'dateOfBirth') {
        isEnable = isEnable && isOlderThan18(dateOfBirth);
      } else if (obj && prop === 'email') {
        isEnable = isEnable && validateEmail(email);
      } else {
        isEnable = isEnable && obj;
      }
    }
    return isEnable;
  };

  return (
    <>
      <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
        <span className={classes.H4} style={{ color: 'black' }}>
          {`${t('sentence.registerProfessional')}`}
        </span>
      </Grid>
      <FormControl className={classes.formControl} error={specialty === ''}>
        <InputLabel id="specialty-simple-select-label">
          {`${t('ProfessionalData.select-specialty')}`}
        </InputLabel>
        <Select
          inputProps={{
            name: 'specialty',
            id: 'specialty-native-simple',
          }}
          native
          value={specialty}
          onChange={(e) => setState({ ...state, specialty: e.target.value })}
        >
          <option aria-label="None" value="" />
          {specialities &&
            specialities.map((specialty) => {
              return <option value={specialty.id}>{specialty.name}</option>;
            })}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl} error={name === ''}>
        <InputLabel htmlFor="my-input-name">{`${t(
          'PersonalData.name'
        )}`}</InputLabel>
        <Input
          id="my-input-name"
          aria-describedby="my-helper-text"
          autoComplete="off"
          onChange={(e) => setState({ ...state, name: e.target.value })}
          value={name}
          required
        />
      </FormControl>
      <FormControl className={classes.formControl} error={lastName === ''}>
        <InputLabel htmlFor="my-input-lastName">{`${t(
          'PersonalData.last-name'
        )}`}</InputLabel>
        <Input
          id="my-input-lastName"
          aria-describedby="my-helper-text"
          autoComplete="off"
          onChange={(e) => setState({ ...state, lastName: e.target.value })}
          value={lastName}
          required
        />
      </FormControl>
      <FormControl
        className={classes.formControl}
        error={email && !validateEmail(email)}
      >
        <InputLabel htmlFor="my-input-email">Email *</InputLabel>
        <Input
          id="my-input-email"
          aria-describedby="my-helper-text"
          autoComplete="off"
          onChange={(e) => setState({ ...state, email: e.target.value })}
          value={email}
          required
        />
        {email && !validateEmail(email) && (
          <FormHelperText id="standard-adornment-password-text" error>
            Fromato inválido
          </FormHelperText>
        )}
      </FormControl>
      <FormControl className={classes.formControl} error={phone === ''}>
        <InputLabel htmlFor="my-input-phone">{`${t(
          'PersonalData.phone'
        )}`}</InputLabel>
        <Input
          id="my-input-phone"
          aria-describedby="my-helper-text"
          autoComplete="off"
          onChange={(e) => setState({ ...state, phone: e.target.value })}
          value={phone}
          required
        />
      </FormControl>
      <FormControl className={classes.formControl} error={license === ''}>
        <InputLabel htmlFor="my-input-license">{`${t(
          'PersonalData.license'
        )}`}</InputLabel>
        <Input
          id="my-input-license"
          aria-describedby="my-helper-text"
          onChange={(e) => setState({ ...state, license: e.target.value })}
          value={license}
          required
        />
      </FormControl>
      <FormControl className={classes.formControl} error={document === ''}>
        <InputLabel htmlFor="my-input-document">Documento *</InputLabel>
        <Input
          id="my-input-document"
          aria-describedby="my-helper-text"
          autoComplete="off"
          onChange={(e) => setState({ ...state, document: e.target.value })}
          value={document}
          required
        />
      </FormControl>
      <FormControl
        className={classes.formControl}
        style={{ marginTop: '1rem' }}
        error={gender === ''}
      >
        <FormLabel component="legend">{`${t(
          'PersonalData.gender'
        )}`}</FormLabel>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          onChange={(e) => setState({ ...state, gender: e.target.value })}
          value={gender}
          row
        >
          <FormControlLabel value="F" control={<Radio />} label="Femenino" />
          <FormControlLabel value="M" control={<Radio />} label="Masculino" />
          <FormControlLabel
            value="O"
            control={<Radio />}
            label={`${capitalize(t('word.other'))}`}
          />
        </RadioGroup>
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          id="date"
          label={`${t('PersonalData.date-of-birth')}`}
          type="date"
          defaultValue={dateOfBirth}
          onChange={(e) => setState({ ...state, dateOfBirth: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!isOlderThan18(dateOfBirth)}
        />
        {!isOlderThan18(dateOfBirth) && (
          <FormHelperText error>{`${t(
            'PersonalData.must-be-older-than-18'
          )}`}</FormHelperText>
        )}
      </FormControl>
      <FormControl className={classes.formControl} error={country === ''}>
        <InputLabel id="demo-simple-select-label">
          {`${t('PersonalData.country')}`}
        </InputLabel>
        <Select
          inputProps={{
            name: 'age',
            id: 'age-native-simple',
          }}
          native
          value={country}
          onChange={(e) =>
            setState({ ...state, country: Number(e.target.value) })
          }
        >
          <option aria-label="None" value="" />
          {countries &&
            countries.map((country) => {
              return <option value={country.id}>{country.name}</option>;
            })}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl} error={city === ''}>
        <InputLabel htmlFor="my-input">{`${t(
          'PersonalData.city'
        )}`}</InputLabel>
        <Input
          id="my-input"
          aria-describedby="my-helper-text"
          onChange={(e) => setState({ ...state, city: e.target.value })}
          value={city}
          required
        />
      </FormControl>

      <Button
        variant="outlined"
        color="primary"
        className={classes.register}
        fullWidth
        disabled={!isEnable()}
        onClick={signUp}
      >
        {`${t('DoctorCard.send-request')}`}
      </Button>
    </>
  );
}

export default ProfessionalForm;
