import { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  FormControl,
  Box,
  Grid,
  InputAdornment,
  IconButton,
  Typography,
  AppBar,
  Tab,
  Tabs,
} from '@material-ui/core';
import { connect } from 'react-redux';

import DoctorAppointmentCard from './DoctorAppointmentCard';
import { useStyles } from './style';
import { doctorsActionsTypes } from '../../redux/doctor/actions';
import { userActionsTypes } from '../../redux/user/actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Pagination from '@material-ui/lab/Pagination';
import { appointmentActionsTypes } from '../../redux/appointment/actions';
import DoctariSpinner from '../../components/Spinner';
import moment from 'moment-timezone';
import { SearchOutlined, SettingsBackupRestore } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { capitalize, getSlotsRange } from '../../utils/helpers';
import DoctariModal from '../../components/Modal';
import Notification from '../../components/Notification';
import { useTranslation } from 'react-i18next';

const AssistantDoctorsAppointment = ({
  userData,
  getUserData,
  session,

  getAppointmentsLight,
  appointmentsData,
  getSchedulesByDate,
  updateAppointmentLight,
  deleteMessages,
  resendVideoCode,
  getAppointmentsProficient,
  getProficientDates,
  updateAppointmentProficient,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [t, i18n] = useTranslation('global');

  useEffect(() => {
    getUserData(session.userType, session.id);
  }, []);

  const [state, setState] = useState({
    userLoad: false,
    doctorFullData: null,
    doctorId: null,
    proficientData: null,
  });
  const [filterState, setFilterState] = useState({
    fromDate: null,
    toDate: null,
    personName: null, // patient or client name
    doctorFilter: null,
    currentPage: 1,
    doctorName: null,
  });
  const {
    fromDate,
    toDate,
    personName,
    doctorFilter,
    currentPage,
    doctorName,
  } = filterState;
  const { userLoad, doctorFullData, doctorId, proficientData } = state;
  const { appointments, numOfPages, schedules, proficientDates } =
    appointmentsData;
  const [doctorsIds, setDoctorsIds] = useState('');
  const [proficientsIds, setProficientsIds] = useState('');
  const [appointmentsLoading, setAppointmentsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [appointmentReason, setAppointmentReason] = useState('');
  const [scheduleHour, setScheduleHour] = useState();
  const [hours, setHours] = useState([]);
  const [appointmentToModify, setAppointmentToModify] = useState();
  const [disabledHours, setDisabledHours] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [openModalAppointmentCancelled, setOpenModalAppointmentCancelled] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [numberTab, setNumberTab] = useState(0);
  const [appointmentsInfo, setAppointmentsInfo] = useState([]);

  useEffect(() => {
    if (toDate || fromDate || personName || doctorName || currentPage) {
      searchAppointments();
    }
    /* return () => clearAppointments(); */
  }, [toDate, fromDate, personName, doctorName, currentPage]);

  useEffect(() => {
    if (userData.user) {
      if (numberTab == 2) {
        if (userData.user.proficients) {
          setState({
            ...state,
            userLoad: true,
          });
          let ids = '';
          for (let i = 0; i < userData.user.proficients.length; i++) {
            if (i == userData.user.proficients.length - 1) {
              ids = ids + userData.user.proficients[i].id;
            } else {
              ids = ids + userData.user.proficients[i].id + ',';
            }
          }
          setProficientsIds(ids);
          getAppointmentsProficient('proficient__in', ids, [], []);
        }
      } else {
        if (userData.user.doctors) {
          setState({
            ...state,
            userLoad: true,
          });
          let ids = '';
          let doctors;
          if (numberTab == 0) {
            doctors = userData.user.doctors.filter((doctor) => {
              return doctor.specialty.name !== 'Veterinaria';
            });
          } else {
            doctors = userData.user.doctors.filter((doctor) => {
              return doctor.specialty.name === 'Veterinaria';
            });
          }
          for (let i = 0; i < doctors.length; i++) {
            if (i == doctors.length - 1) {
              ids = ids + doctors[i].id;
            } else {
              ids = ids + doctors[i].id + ',';
            }
          }
          setDoctorsIds(ids);
          if (ids) {
            getAppointmentsLight('doctor__in', ids, [], []);
          } else {
            setAppointmentsInfo([]);
          }
        }
      }
    }
  }, [userData.user, numberTab]);

  const searchAppointments = () => {
    setAppointmentsLoading(true);
    let queryParams = [];
    let queryValues = [];
    if (currentPage > 1) {
      queryParams.push('page');
      queryValues.push(currentPage);
    }
    if (fromDate) {
      queryParams.push('date__gt');
      let fromDateFormatted = moment(fromDate)
        .set('hour', '00')
        .set('minute', '00')
        .tz('America/Montevideo')
        .format('YYYY-MM-DD HH:mm');
      queryValues.push(fromDateFormatted);
    }
    if (toDate) {
      queryParams.push('date__lt');
      let toDateFormatted = moment(toDate)
        .set('hour', '23')
        .set('minute', '59')
        .tz('America/Montevideo')
        .format('YYYY-MM-DD HH:mm');
      queryValues.push(toDateFormatted);
    }
    if (doctorName) {
      if (numberTab == 2) {
        queryParams.push('proficient__full_name__icontains');
        queryValues.push(doctorName);
      } else {
        queryParams.push('doctor__full_name__icontains');
        queryValues.push(doctorName);
      }
    }
    if (personName) {
      if (numberTab == 2) {
        queryParams.push('client__full_name__icontains');
      } else {
        queryParams.push('patient__full_name__icontains');
      }
      queryValues.push(personName);
    }

    if (numberTab == 2) {
      getAppointmentsProficient(
        'proficient__in',
        proficientsIds,
        queryParams,
        queryValues
      );
    } else {
      if (doctorsIds) {
        getAppointmentsLight(
          'doctor__in',
          doctorsIds,
          queryParams,
          queryValues
        );
      }
    }
  };

  useEffect(() => {
    if (
      // doctorsIds ||
      appointmentsData.success.genericMessage ===
      'Appointment actualizado con exito'
    ) {
      setState({
        userLoad: false,
        doctorFullData: null,
        doctorId: null,
      });
      searchAppointments();
      setOpenModal(false);
      setOpenModalAppointmentCancelled(false);
      setLoading(false);
    }
  }, [appointmentsData.success.genericMessage]);

  useEffect(() => {
    if (appointments?.length > 0) {
      setAppointmentsLoading(false);
      setAppointmentsInfo(appointments);
    } else {
      setAppointmentsLoading(false);
      setAppointmentsInfo([]);
    }
  }, [appointments]);

  useEffect(() => {
    if (appointmentToModify) {
      let dateToModify;
      let hourToModify;

      if (numberTab == 2) {
        setState({
          ...state,
          proficientData: appointmentToModify.proficient,
          doctorFullData: null,
        });
        dateToModify = moment(appointmentToModify.date).format('YYYY-MM-DD');
        hourToModify = moment(appointmentToModify.date).format('HH:mm');
      } else {
        setState({
          ...state,
          doctorId: appointmentToModify.doctor.id,
          doctorFullData: appointmentToModify.doctor,
          proficientData: null,
        });
        dateToModify = moment(appointmentToModify.schedule.start).format(
          'YYYY-MM-DD'
        );
        hourToModify = moment(appointmentToModify.schedule.start).format(
          'HH:mm'
        );
      }

      setSelectedDate(dateToModify);
      setScheduleHour(hourToModify);
    }
  }, [appointmentToModify]);

  useEffect(() => {
    if (schedules) {
      setDisabledHours(
        Object.keys(schedules).map((key, index) => {
          if (schedules[key][0].taken) {
            return moment(key).tz(moment.tz.guess()).format('HH:mm');
          }
        })
      );
    }
  }, [schedules]);

  useEffect(() => {
    if (proficientDates) {
      setDisabledHours(
        Object.keys(proficientDates).map((key, index) => {
          return moment(key).format('HH:mm');
        })
      );
    }
  }, [proficientDates]);

  useEffect(() => {
    if (selectedDate) {
      let fromDateFormatted = moment(selectedDate)
        .set('hour', '00')
        .set('minute', '00')
        .tz('America/Montevideo')
        .format('YYYY-MM-DD HH:mm');
      let toDateFormatted = moment(selectedDate)
        .set('hour', '23')
        .set('minute', '59')
        .tz('America/Montevideo')
        .format('YYYY-MM-DD HH:mm');

      if (numberTab == 2) {
        getProficientDates(
          fromDateFormatted,
          toDateFormatted,
          proficientData.id
        );
      } else {
        getSchedulesByDate(
          fromDateFormatted,
          toDateFormatted,
          ['doctor'],
          [doctorId ? doctorId : appointmentToModify.doctor.id]
        );
      }
    }
  }, [selectedDate, doctorId]);

  useEffect(() => {
    if (doctorId) {
      setHours(getSlotsRange(doctorFullData.specialty.duration.minutes));
    }
  }, [doctorId]);

  useEffect(() => {
    if (proficientData) {
      setHours(getSlotsRange(proficientData.slot));
    }
  }, [proficientData]);

  useEffect(() => {
    if (!openModal) {
      setSelectedDate(null);
      setAppointmentToModify(null);
    }
  }, [openModal]);

  const handleChangeProfessionalId = (e, value) => {
    if (value == null) {
      // clearSelectDoctor();
      if (numberTab == 2) {
        setState({
          ...state,
          proficientData: null,
        });
      } else {
        setState({
          ...state,
          doctorId: null,
          doctorFullData: null,
        });
      }
    } else {
      if (numberTab == 2) {
        setState({
          ...state,
          proficientData: value,
        });
      } else {
        setState({
          ...state,
          doctorId: value.id,
          doctorFullData: value,
        });
      }
    }
  };

  const handleInputChange = (e) => {
    setAppointmentReason(e.target.value);
  };

  const handleSetHour = (e, value) => {
    if (value == null) {
      setScheduleHour(null);
    } else {
      setScheduleHour(value);
    }
  };

  const handlePatchAppointment = (isCancelled = false) => {
    setLoading(true);
    let body;
    if (isCancelled) {
      body = {
        status: 'cancelled',
        is_cancelled: true,
      };
    } else {
      if (numberTab == 2) {
        body = {
          proficient: appointmentToModify.proficient.id,
          date:
            moment(selectedDate + ' ' + scheduleHour).format(
              'YYYY-MM-DD HH:mm'
            ) != 'Fecha inválida'
              ? moment(selectedDate + ' ' + scheduleHour)
                  .tz(moment.tz.guess())
                  .format()
              : appointmentToModify.date,
          client_reason_text: appointmentReason
            ? appointmentReason
            : appointmentToModify.client_reason_text,
        };
      } else {
        body = {
          doctor: doctorId ? doctorId : appointmentToModify.doctor.id,
          schedule_hour:
            moment(selectedDate + ' ' + scheduleHour).format(
              'YYYY-MM-DD HH:mm'
            ) != 'Fecha inválida'
              ? moment(selectedDate + ' ' + scheduleHour).format(
                  'YYYY-MM-DD HH:mm'
                )
              : appointmentToModify.schedule.start,
          patient_appointment_reason: appointmentReason
            ? appointmentReason
            : appointmentToModify.patient_appointment_reason,
        };
      }
    }
    if (numberTab == 2) {
      updateAppointmentProficient(appointmentToModify.id, body);
    } else {
      updateAppointmentLight(appointmentToModify.id, body);
    }
  };

  const handleChangeTab = (_, newValue) => {
    setFilterState({
      ...filterState,
      toDate: '',
      fromDate: '',
      personName: '',
      doctorName: '',
    });
    setState({
      ...state,
      doctorFullData: null,
      doctorId: null,
      proficientData: null,
    });
    // setAppointmentsInfo([]);
    setDoctorsIds('');
    setNumberTab(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const AppointmentCards = ({}) => {
    return (
      <Grid className={classes.cardContainer}>
        {appointmentsData.loading ? (
          <DoctariSpinner />
        ) : appointmentsInfo && appointmentsInfo.length > 0 ? (
          appointmentsInfo.map((appointment) => {
            return (
              <DoctorAppointmentCard
                appointment={appointment}
                setOpenModal={setOpenModal}
                setAppointmentToModify={setAppointmentToModify}
                setOpenModalAppointmentCancelled={
                  setOpenModalAppointmentCancelled
                }
                resendVideoCode={resendVideoCode}
              />
            );
          })
        ) : (
          <h3>{`${capitalize(t('HistoricAppointments.no-data'))}`}</h3>
        )}

        <Pagination
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
            marginBottom: '2rem',
          }}
          count={appointmentsInfo.length > 0 ? numOfPages : 1}
          onChange={(_, page) => {
            if (currentPage !== page) {
              setFilterState({ ...filterState, currentPage: page });
            }
          }}
          variant="outlined"
          color="primary"
        />
      </Grid>
    );
  };

  return (
    <>
      {appointmentsData.success.genericMessage ===
        'Appointment actualizado con exito' && (
        <Notification
          type="success"
          message={`Consulta ${t('word.updated')} ${t('prep.with')} ${t(
            'word.success'
          )}`}
          onCloseCallback={deleteMessages}
        />
      )}
      {appointmentsData.success.genericMessage ===
        'Datos reenviados con exito' && (
        <Notification
          type="success"
          message={'Datos reenviados con éxito'}
          onCloseCallback={deleteMessages}
        />
      )}
      <DoctariModal
        open={openModal}
        setOpen={(val) => {
          setOpenModal(val);
        }}
        title={'Modificar consulta'}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ padding: '2rem', width: '100%' }}
        >
          <Grid
            item
            xs={12}
            md={6}
            // style={{
            //   display: 'flex',
            //   justifyContent: 'center',
            //   alignItems: 'center',
            // }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={
                userLoad
                  ? numberTab == 0
                    ? userData.user.doctors.filter((doctor) => {
                        return doctor.is_active;
                      })
                    : numberTab == 1
                    ? userData.user.doctors.filter((doctor) => {
                        return doctor.specialty.name == 'Veterinaria';
                      })
                    : userData.user.proficients
                  : []
              }
              getOptionLabel={(option) => option.full_name}
              sx={{ width: 300 }}
              value={
                doctorFullData
                  ? doctorFullData
                  : proficientData
                  ? proficientData
                  : null
              }
              onChange={handleChangeProfessionalId}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    `${capitalize(t('word.select'))} ` +
                    `${
                      numberTab == 2 ? t('word.proficient') : t('word.doctor')
                    }`
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="standard-basic"
              label={t('HistoricAppointments.appointment-reason')}
              variant="standard"
              style={{ width: '100%' }}
              // className={styles.paperGridItems}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl style={{ width: '100%' }}>
              <TextField
                id="fromDateSelected"
                label={t('HistoricAppointments.appointment-date')}
                type={!selectedDate ? 'text' : 'date'}
                value={selectedDate ? selectedDate : ''}
                onChange={(e) => setSelectedDate(e.target.value)}
                onFocus={(e) => (e.target.type = 'date')}
                onBlur={(e) =>
                  (e.target.type = !selectedDate ? 'text' : 'date')
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={hours ? hours : []}
              // getOptionLabel={(option) => option.full_name}
              getOptionDisabled={(option) =>
                !!disabledHours.find((element) => element === option) ||
                moment(
                  moment().tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm')
                ).isSameOrAfter(
                  moment(selectedDate + ' ' + option).format('YYYY-MM-DD HH:mm')
                )
              }
              sx={{ width: 300 }}
              value={scheduleHour ? scheduleHour : ''}
              onChange={handleSetHour}
              disabled={!selectedDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('HistoricAppointments.appointment-time')}
                />
              )}
            />
          </Grid>
          {moment(
            moment().tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm')
          ).isSameOrAfter(
            moment(selectedDate + ' ' + scheduleHour).format('YYYY-MM-DD HH:mm')
          ) && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="error">
                La fecha seleccionada es pasada a la actual
              </Typography>
            </Grid>
          )}
        </Grid>
        <Box
          style={{ display: 'flex', justifyContent: 'end', padding: '2rem' }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePatchAppointment()}
            disabled={moment(
              moment().tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm')
            ).isSameOrAfter(
              moment(selectedDate + ' ' + scheduleHour).format(
                'YYYY-MM-DD HH:mm'
              )
            )}
          >
            {appointmentsData.loading
              ? t('HistoricAppointments.loading')
              : t('HistoricAppointments.accept')}
          </Button>
        </Box>
      </DoctariModal>
      <DoctariModal
        open={openModalAppointmentCancelled}
        setOpen={(val) => setOpenModalAppointmentCancelled(val)}
        title={`${capitalize(t('word.confirmation'))} de ${t(
          'word.cancellation'
        )}`}
      >
        <Box
          style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}
        >
          <Typography>{`${t('sentence.cancelAppointmentModal')}`}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'end',
            padding: '2rem',
            gap: '1rem',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenModalAppointmentCancelled(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePatchAppointment(true)}
          >
            {appointmentsData.loading
              ? t('word.loading')
              : t('HistoricAppointments.accept')}
          </Button>
        </Box>
      </DoctariModal>
      <Grid
        container
        direction="row"
        className={classes.generalContainer}
        style={{ marginTop: '2rem' }}
      >
        <Grid item xs={3} className={classes.formContainer}>
          <span style={{ fontWeight: 400, fontSize: '24px' }}>
            {t('HistoricAppointments.history')}
          </span>
          {/*   <FormControl className={classes.select}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={
              userLoad
                ? userData.user.doctors.filter((doctor) => {
                    return doctor.is_active;
                  })
                : []
            }
            getOptionLabel={(option) => option.full_name}
            sx={{ width: 300 }}
            value={doctorFilter ? doctorFilter : null}
            onChange={handleFilterDoctor}
            renderInput={(params) => (
              <TextField {...params} label="Seleccione doctor" />
            )}
          />
        </FormControl> */}
          <TextField
            className={classes.search}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchOutlined />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                height: '46px',
              },
              classes: { underline: classes.underline },
            }}
            id="my-input"
            placeholder={
              `${capitalize(t('Patient.name'))} ${t('prep.of-1')} ` +
              `${numberTab == 2 ? `${t('word.client')}` : 'paciente'}`
            }
            variant="standard"
            autoComplete="off"
            style={{ marginBottom: '0' }}
            value={personName}
            onChange={(e) =>
              setFilterState({
                ...filterState,
                personName: e.target.value,
                currentPage: 1,
              })
            }
          />
          <TextField
            className={classes.search}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchOutlined />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                height: '46px',
              },
              classes: { underline: classes.underline },
            }}
            id="my-input"
            placeholder="Nombre de doctor"
            variant="standard"
            autoComplete="off"
            value={doctorName}
            onChange={(e) =>
              setFilterState({
                ...filterState,
                doctorName: e.target.value,
                currentPage: 1,
              })
            }
          />
          <Grid item className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <TextField
                id="fromDate"
                label="Desde"
                type="date"
                defaultValue={fromDate}
                value={fromDate}
                onChange={(e) =>
                  setFilterState({
                    ...filterState,
                    fromDate: e.target.value,
                    currentPage: 1,
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <TextField
                id="toDate"
                label={t('HistoricAppointments.to')}
                type="date"
                defaultValue={toDate}
                value={toDate}
                onChange={(e) =>
                  setFilterState({
                    ...filterState,
                    toDate: e.target.value,
                    currentPage: 1,
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid className={classes.buttonContainer}>
            <Button
              variant="contained"
              onClick={() => {
                setFilterState({
                  ...filterState,
                  toDate: '',
                  fromDate: '',
                  personName: '',
                  doctorName: '',
                  currentPage: 1,
                });
                // getAppointmentsLight('doctor__in', doctorsIds, false, [], []);
              }}
              style={{
                color: 'white',
                backgroundColor: '#666666',
              }}
            >
              <SettingsBackupRestore />
              Reiniciar
            </Button>
            <Button
              variant="contained"
              onClick={() => searchAppointments()}
              style={{
                color: 'white',
                backgroundColor: theme.palette.primary.main,
                marginLeft: 'auto',
              }}
            >
              Filtrar
            </Button>
          </Grid>
        </Grid>
        <Grid item container direction="column" xs={9}>
          <Grid container item justifyContent="center">
            <AppBar
              position="static"
              style={{
                width: '72.8%',
                background: 'white',
                boxShadow: 'none',
                zIndex: '0',
              }}
            >
              <Tabs
                value={numberTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                aria-label="simple tabs example"
                // style={{ justifyContent: 'space-between' }}
                centered
              >
                <Tab label="Medicina" {...a11yProps(0)} />
                <Tab
                  label={`${capitalize(t('word.veterinary'))}`}
                  {...a11yProps(1)}
                />
                <Tab
                  label={`${capitalize(t('word.expertise'))}`}
                  {...a11yProps(2)}
                />
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item>
            <TabPanel value={numberTab} index={0}>
              <AppointmentCards />
            </TabPanel>
            <TabPanel value={numberTab} index={1}>
              <AppointmentCards />
            </TabPanel>
            <TabPanel value={numberTab} index={2}>
              <AppointmentCards />
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ session, user, doctors, appointments }) => {
  return {
    session,
    userData: user,
    doctorInfo: doctors,
    appointmentsData: appointments,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDoctorsInfo: (queryParams, queryValues) =>
    dispatch({
      type: doctorsActionsTypes.GET_DOCTORS,
      payload: { queryParams, queryValues },
    }),
  getUserData: (type, id) =>
    dispatch({ type: userActionsTypes.GET_USER, payload: { type, id } }),
  setSelectedDoctor: (doctorBody) =>
    dispatch({
      type: doctorsActionsTypes.SET_DOCTOR_BODY,
      payload: doctorBody,
    }),
  clearSelectedDoctor: () =>
    dispatch({ type: doctorsActionsTypes.CLEAR_SELECT_DOCTOR }),
  getAppointments: (userType, id, isDone, queryParams, queryValues) => {
    dispatch({
      type: appointmentActionsTypes.GET_APPOINTMENTS, //TODO cambiar el formato de fechas en el back para no tener que hacer todo este juego con format
      payload: {
        userType,
        id,
        isDone,
        queryParams,
        queryValues,
      },
    });
  },
  getAppointmentsLight: (userType, id, queryParams, queryValues) =>
    dispatch({
      type: appointmentActionsTypes.GET_APPOINTMENTS_LIGHT,
      payload: {
        userType,
        id,
        queryParams,
        queryValues,
      },
    }),
  getSchedulesByDate: (start, end, queryParams, queryValues) =>
    dispatch({
      type: appointmentActionsTypes.GET_SCHEDULES_BY_DATE,
      payload: {
        start,
        end,
        queryParams,
        queryValues,
      },
    }),
  updateAppointmentLight: (id, body) =>
    dispatch({
      type: appointmentActionsTypes.UPDATE_APPOINTMENT_LIGHT,
      payload: { id, body },
    }),
  deleteMessages: () =>
    dispatch({
      type: userActionsTypes.DELETE_MESSAGES,
    }),
  resendVideoCode: (id) => {
    dispatch({
      type: appointmentActionsTypes.RESEND_CODE,
      payload: { id },
    });
  },

  getAppointmentsProficient: (userType, id, queryParams, queryValues) =>
    dispatch({
      type: appointmentActionsTypes.GET_APPOINTMENTS_PROFICIENT, //TODO cambiar el formato de fechas en el back para no tener que hacer todo este juego con format
      payload: {
        userType,
        id,
        queryParams,
        queryValues,
      },
    }),

  getProficientDates: (start, end, proficientId) =>
    dispatch({
      type: appointmentActionsTypes.GET_PROFICIENT_DATES,
      payload: {
        start,
        end,
        proficientId,
      },
    }),

  updateAppointmentProficient: (id, body) =>
    dispatch({
      type: appointmentActionsTypes.UPDATE_APPOINTMENT_PROFICIENT,
      payload: { id, body },
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssistantDoctorsAppointment);
