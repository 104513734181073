import { useEffect } from "react";

export const AudioVisualizer = () => {
  useEffect(() => {
    const viz = () => {
      const canvas = document.createElement('canvas');
      canvas.height = 150;
      canvas.width = 1024;
      canvas.style =
        'width: 100%; border-radius:10px;box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;; margin-top:15px';
      const context = canvas.getContext('2d');
      document.getElementById('audio').appendChild(canvas);
      return [canvas, context];
    };
    const [canvas, ctx] = viz();
    window.plotLine = (points) => {
      window.requestAnimationFrame(() => {
        const xInterval = canvas.width / points.length;
        const max = Math.max(...points);
        const min = Math.min(...points);
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = '#067fcf';
        ctx.beginPath();
        ctx.moveTo(-1, canvas.height / 2);
        let previous = 0;
        points.forEach((value, index) => {
          ctx.lineTo((index + 1) * xInterval, value / 1.7);
        });
        ctx.lineTo(canvas.width + 1, canvas.height / 2);
        ctx.stroke();
      });
    };
    async function getMicAudio() {
      return await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
    }
    let running = false;
    let analyser;
    let dataArray;
    async function buildAudioStack() {
      const audio = await getMicAudio();
      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      analyser = audioCtx.createAnalyser();
      const source = audioCtx.createMediaStreamSource(audio);
      source.connect(analyser);
      dataArray = new Uint8Array(analyser.frequencyBinCount);
    }
    function tick() {
      analyser.getByteTimeDomainData(dataArray);
      window.plotLine(Array.from(dataArray));
      if (running) {
        let rfID = requestAnimationFrame(tick);
      }
    }
    window.begin = function () {
      running = true;
      tick();
    };
    window.pause = function () {
      window.levels = Array.from(dataArray);
      running = false;
    };
    buildAudioStack().then(() => window.begin());
  }, []);
  return (
    <>
      <div>
        <div id="audio"></div>
      </div>

    </>)

}