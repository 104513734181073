import React, { useState } from 'react';
import {
  InputAdornment,
  IconButton,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

function PasswordInput({
  classes,
  changePassword,
  labelText,
  password,
  isSecondPassword,
  diffPassword,
  isValidPassword,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <FormControl className={classes.formControl} error={password === ''}>
        <InputLabel htmlFor='standard-adornment-password'>
          {labelText}
        </InputLabel>
        <Input
          id='standard-adornment-password' 
          type={showPassword ? 'text' : 'password'}
          autoComplete='new-password' 
          aria-describedby='my-helper-text'
          onChange={(e) => changePassword(e.target.value)}
          value={password}
          color='primary'
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
              >
                {password &&
                  (showPassword ? <Visibility /> : <VisibilityOff />)}
              </IconButton>
            </InputAdornment>
          }
        />
        {password && !isSecondPassword && !isValidPassword(password) && (
          <FormHelperText id='standard-adornment-password-text' error>
            La contraseña debe tener al menos una mayúscula, una minúscula, un
            número y mínimo 8 caracteres
          </FormHelperText>
        )}
        {password && diffPassword && (
          <FormHelperText id='standard-adornment-password-text' error>
            Las contraseña no coinciden
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
}

export default PasswordInput;
