import { useState } from 'react';
import moment from 'moment';
import {
  Grid,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function EditScheduleModal({
  //TODO agregar constante = isGroup y reemplazarlo
  availabilityStart,
  availabilityEnd,
  token,
  setAvailability,
  deleteSchedule,
  setModalOpen,
  selectedSchedule,
  slot,
}) {
  const [t, i18n] = useTranslation('global');
  const isGroup = selectedSchedule.schedule_group.date_start; // si date start es distinto de null entonces pertenece a un grupo
  const scheduleGroup = selectedSchedule.schedule_group; //defino el posible schedule del grupo
  const DAYS = {
    0: t('EditScheduleModal.monday'),
    1: t('EditScheduleModal.tuesday'),
    2: t('EditScheduleModal.wednesday'),
    3: t('EditScheduleModal.thursday'),
    4: t('EditScheduleModal.friday'),
    5: 'Sábado',
    6: 'Domingo',
  };
  let stringDays = t('EditScheduleModal.days');
  let stringDateRepeat = t('EditScheduleModal.repeat-date');

  const [state, setState] = useState({
    delete_multiple_schedules: false,
    delete_schedule: false,
  });

  const { delete_multiple_schedules, delete_schedule } = state;
  // Defino el string de días a mostrar si la disposibilidad pertenece a un grupo
  if (isGroup) {
    let counter = 0;

    for (let day of scheduleGroup.days) {
      stringDays =
        stringDays +
        DAYS[day] +
        `${counter === scheduleGroup.days.length - 1 ? '' : ', '}`;
      counter = counter + 1;
    }
    if (isGroup != scheduleGroup.date_finish_repeat) {
      stringDateRepeat =
        stringDateRepeat +
        moment(scheduleGroup.date_finish_repeat).format('dddd DD-MM-YYYY');
    }
  }

  const dateRange = ` ${
    isGroup
      ? moment(isGroup).tz(moment.tz.guess()).format('[de] HH:mm [a] ')
      : moment(selectedSchedule.start)
          .tz(moment.tz.guess())
          .format('dddd [de] HH:mm [a] ')
  }${
    isGroup
      ? isGroup === scheduleGroup.date_finish
        ? moment(scheduleGroup.date_finish)
            .add(slot, 'minute')
            .tz(moment.tz.guess())
            .format('HH:mm')
        : moment(scheduleGroup.date_finish)
            .tz(moment.tz.guess())
            .format('HH:mm')
      : moment(selectedSchedule.start)
          .add(slot, 'minute')
          .tz(moment.tz.guess())
          .format('HH:mm')
  } `;

  const handleDeleteSchedule = (scheduleId, scheduleGroupId) => {
    !isGroup // si no hay multiples entonces borro solo el seleccionado
      ? deleteSchedule('token', scheduleId, false)
      : delete_multiple_schedules // si hay multiples entonces tengo que ver que checkbox esta marcada, para eso uso el state delete_multiple_schedules
      ? deleteSchedule('token', scheduleGroupId, true)
      : deleteSchedule('token', scheduleId, false);
  };

  const isEnableAcceptButton = () => {
    let enable = false;
    if (!isGroup) {
      enable = true;
    } else if (delete_multiple_schedules && !delete_schedule) {
      enable = true;
    } else if (!delete_multiple_schedules && delete_schedule) {
      enable = true;
    }
    return enable;
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Box
      style={{
        padding: '2rem',
        overflow: 'auto',
      }}
    >
      <Box marginBottom="2rem" textAlign="center">
        <span>{t('EditScheduleModal.time-availability')}</span>
        <span style={{ fontWeight: 'bold' }}>{dateRange}</span>
        <br></br>
        <br></br>
        <span>{isGroup && stringDays}</span>
        <br></br>
        <br></br>
        <span>
          {isGroup != scheduleGroup.date_finish_repeat && stringDateRepeat}
        </span>
      </Box>
      {isGroup ? (
        <Box>
          <p>{t('EditScheduleModal.available-actions')}:</p>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={delete_schedule}
                  onClick={handleChange}
                  name="delete_schedule"
                  color="primary"
                />
              }
              label={`Eliminar solo el evento seleccionado: ${moment(
                selectedSchedule.start
              )
                .tz(moment.tz.guess())
                .format('dddd [de] HH:mm [a] ')} ${moment(
                selectedSchedule.start
              )
                .add(slot, 'minute')
                .tz(moment.tz.guess())
                .format('HH:mm')}`}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={delete_multiple_schedules}
                  onClick={handleChange}
                  name="delete_multiple_schedules"
                  color="primary"
                />
              }
              label="Eliminar todos los eventos del grupo "
            />
          </FormGroup>
        </Box>
      ) : (
        <Box marginBottom="2rem" textAlign="center">
          <span>¿Desea eliminar esta disponibilidad?</span>
        </Box>
      )}

      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '1rem',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          disabled={!isEnableAcceptButton()}
          style={{ width: '200px' }}
          onClick={() => {
            handleDeleteSchedule(selectedSchedule.id, scheduleGroup.id);
            setModalOpen((modalOpen) => {
              return { ...modalOpen, deleteSchedule: false };
            });
            setAvailability((availability) => {
              return { ...availability, start: null, end: null };
            });
          }}
        >
          Aceptar
        </Button>
      </Grid>
    </Box>
  );
}

export default EditScheduleModal;
