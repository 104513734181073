import { useState } from 'react';
import { Box, Button, Avatar, Paper, TextField } from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/helpers';

const DoctorAppointmentCard = ({
  appointment,
  setOpenModal,
  setAppointmentToModify,
  setOpenModalAppointmentCancelled,
  resendVideoCode,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [t, i18n] = useTranslation('global');

  const useStyles = makeStyles((theme) => ({
    paperContainer: {
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '1rem',
      width: '100%',
      flexWrap: 'wrap',
      borderRadius: '8px',
      '@media screen and (max-width: 425px)': {
        width: '100%',
      },
    },
    infoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      flexDirection: 'column',
    },
    boxContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '16px',
      '@media screen and (max-width: 320px)': {
        flexDirection: 'column',
        alignItems: 'normal',
        padding: '16px!important',
      },
      '@media screen and (max-width:768px)': {
        padding: '0 16px',
      },
    },
    dataSize: {
      fontSize: '14px',
      padding: '16px 16px 0 16px',
    },
    date: {
      display: 'flex',
      marginBottom: 'auto',
    },
    consult: {
      fontSize: '13px',
    },
    dateNumber: {
      marginLeft: 'auto',
      fontSize: '12px',
      color: '#666666',
    },
    avatar: {
      width: '56px',
      height: '56px',
      borderRadius: '25%',
      marginRight: '18px',
      '@media screen and (max-width: 576px)': {
        width: '51px',
        height: '51px',
        marginRight: '18px',
        border: '2px solid #067DCF',
      },
      '@media screen and (max-width:320px)': {
        marginRight: '0',
      },
    },
    name: {
      fontSize: '16px',
    },
    specialty: {
      color: '#666666',
      fontSize: '14px',
    },
    button: {
      width: '150px',
      height: '36px',
      marginRight: '10px',
      '@media screen and (max-width:320px)': {
        marginLeft: '0',
        marginBottom: '5px',
        marginRight: '0',
      },
      '@media screen and (max-width:768px)': {
        display: 'none',
      },
    },
    detailContainer: {
      padding: '16px',
      '@media screen and (max-width: 320px)': {
        flexDirection: 'column',
      },
    },
    attached: {
      marginTop: '1rem',
    },
    attachedHeading: {
      marginBottom: '20px',
      fontSize: '14px',
    },
    attachedFiles: {
      color: '#0066FF',
      fontSize: '12px',
      marginRight: '18px',
      display: 'inline',
    },
    responsiveContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: ' 0 0 16px 16px',
      '@media screen and (max-width:321px)': {
        padding: '0 0 16px 5px',
      },
      '@media screen and (min-width:769px)': {
        display: 'none',
      },
    },
    buttonColorError: {
      color: '#F44336',
      borderColor: '#F44336',
    },
  }));

  const classes = useStyles();

  const detailHandler = () => {
    if (!showDetails) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  };

  const ClipboardCopy = ({ copyText, label }) => {
    const [isCopied, setIsCopied] = useState(false);

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }
    // onClick handler function for the copy button
    const handleCopyClick = () => {
      // Asynchronously call copyTextToClipboard
      copyTextToClipboard(copyText)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <TextField
          id="my-input"
          aria-describedby="my-helper-text"
          style={{ width: '80%' }}
          variant="outlined"
          value={copyText}
          size="small"
          label={label}
          readOnly
        />
        <Button
          style={{ float: 'right', margin: '10px' }}
          variant="text"
          color="primary"
          //disabled={!attachments.length}
          onClick={handleCopyClick}
        >
          <span>{isCopied ? 'Copiado!' : 'Copiar'}</span>
        </Button>
      </Box>
    );
  };

  return (
    <Paper className={classes.paperContainer}>
      <Box className={classes.infoContainer}>
        <Box className={classes.dataSize}>
          <Box>
            <span>
              <strong>
                {' '}
                {t('HistoricAppointmentCard.appointment-reason')}{' '}
              </strong>
            </span>
            <span>
              {appointment?.doctor
                ? appointment.patient_appointment_reason
                  ? appointment.patient_appointment_reason
                  : `${t('HistoricAppointmentCard.no-data')}`
                : appointment.client_reason_text
                ? appointment.client_reason_text
                : `${t('HistoricAppointmentCard.no-data')}`}
            </span>
          </Box>

          <span className={classes.consult}>Consulta: </span>
          <span className={classes.dateNumber}>
            {appointment.date
              ? moment(appointment.date)
                  .tz(moment.tz.guess())
                  .format('DD-MM-YYYY h:mm a')
              : '-'}
          </span>
        </Box>
        <Box style={{ padding: '0 16px' }}>
          <hr style={{ color: 'rgba(0, 0, 0, 0.12)' }}></hr>
        </Box>
        <Box className={classes.boxContainer}>
          <Avatar
            className={classes.avatar}
            alt="profile picture"
            src={
              appointment.doctor && appointment.doctor.profile_picture
                ? appointment.doctor.profile_picture.url
                : appointment.proficient &&
                  appointment.proficient.profile_picture
                ? appointment.proficient.profile_picture.url
                : null
            }
          />
          <p className={classes.name}>
            {appointment?.doctor
              ? 'Paciente: ' +
                `${appointment.patient_name ? appointment.patient_name : ''}`
              : `${capitalize(t('word.client'))}: ` +
                `${appointment.client ? appointment.client.full_name : ''}`}
            <br></br>
            {appointment.doctor
              ? `${capitalize(t('word.doctor'))}: ` +
                `${appointment.doctor ? appointment.doctor.full_name : ''}`
              : `${capitalize(t('word.proficient'))}: ` +
                `${
                  appointment.proficient ? appointment.proficient.full_name : ''
                }`}
            <br></br>
            {appointment?.doctor &&
              appointment?.doctor.specialty.name != 'Veterinaria' && (
                <span className={classes.specialty}>
                  {t('HistoricAppointmentCard.date-of-birth')}
                  {moment(appointment.patient.date_of_birth).format(
                    'DD/MM/YYYY'
                  )}
                </span>
              )}
          </p>
          <Button
            style={{ marginLeft: 'auto' }}
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenModal(true);
              setAppointmentToModify(appointment);
            }}
            disabled={appointment.is_done}
          >
            Modificar
          </Button>
          <Button
            // style={{ width: '175px' }}
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenModalAppointmentCancelled(true);
              setAppointmentToModify(appointment);
            }}
            disabled={appointment.is_done}
          >
            Cancelar
          </Button>

          {/* {!showDetails ? (
            <Button
              style={{ width: '175px' }}
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={detailHandler}
            >
             {t('HistoricAppointmentCard.show-details')}
            </Button>
          ) : (
            <Button
            style={{ width: '175px' }}
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={detailHandler}
            >
              {t('HistoricAppointmentCard.hide-details')}
            </Button>
          )} */}
        </Box>
        <div className={classes.responsiveContainer}>
          <Button
            style={{ marginRight: '10px' }}
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenModal(true);
              setAppointmentToModify(appointment);
            }}
          >
            Modificar
          </Button>
          <Button
            style={{ width: '175px' }}
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenModalAppointmentCancelled(true);
              setAppointmentToModify(appointment);
            }}
          >
            Cancelar
          </Button>
        </div>
        <Box style={{ padding: '0 16px' }}>
          <hr style={{ color: 'rgba(0, 0, 0, 0.12)' }}></hr>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            {!showDetails ? (
              <Button
                style={{ width: '175px' }}
                variant="text"
                color="primary"
                size="small"
                onClick={detailHandler}
              >
                {t('HistoricAppointmentCard.show-details')} <ExpandMoreIcon />
              </Button>
            ) : (
              <Button
                style={{ width: '175px' }}
                variant="text"
                color="primary"
                size="small"
                onClick={detailHandler}
              >
                {t('HistoricAppointmentCard.hide-details')} <ExpandLessIcon />
              </Button>
            )}
          </Box>
        </Box>
        {showDetails ? (
          <div className={classes.detailContainer}>
            <div className={classes.attached}>
              <p>
                <strong className={classes.attachedHeading}>
                  {t('HistoricAppointmentCard.link')}
                </strong>
                <br></br>
              </p>
              <ClipboardCopy
                copyText={
                  appointment.patient_link
                    ? appointment.patient_link
                    : appointment.client_link
                }
                label={t('HistoricAppointmentCard.patient-link')}
              />
              <ClipboardCopy
                copyText={appointment.video_conference_code}
                label="Código"
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '1rem',
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => resendVideoCode(appointment.id)}
              >
                REENVIAR DATOS DE ACCESO
              </Button>
            </div>
          </div>
        ) : (
          ''
        )}
      </Box>
    </Paper>
  );
};

export default DoctorAppointmentCard;
