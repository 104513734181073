import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    fontFamily: theme.typography.fontFamily,
    marginBottom: '1rem',
    width: '100%',
  },
  H5: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'center',
    marginBottom: '1rem',
    fontSize: 23,
    color: '#4a4a4a;',
  },
  H4: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    color: '#4a4a4a',
    lineHeight: 2,
  },
  SPAN: {
    textAlign: 'center',
    marginBottom: '1rem',
    fontSize: '14.4px',
    color: '#4a4a4a',
  },
  register: {
    marginTop: '1rem',
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover, &:focus': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  label: {
    color: theme.palette.primary.main,
    fontSize: '0.7rem',
  },
  modalBody: {
    padding: '2rem',
    overflow: 'auto',
  },
  helpText: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'center',
    marginTop: '2rem',
    fontStyle: 'italic',
    fontSize: '14px',
  },
}));
