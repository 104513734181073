import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Button, FormControl, TextField } from '@material-ui/core';
import { DateRange, CheckCircle } from '@material-ui/icons';
import Notification from '../../components/Notification';
import MonthCalendar from './MonthCalendar';
import DateControl from './DateControl';
import CreateScheduleModal from './CreateScheduleModal';
import DoctariCalendar from '../../components/DoctariCalendar';
import DoctariModal from '../../components/Modal';

import { appointmentActionsTypes } from '../../redux/appointment/actions';
import { userActionsTypes } from '../../redux/user/actions';
import {
  APPOINTMENT_LIGHT,
  APPOINTMENT_VETERINARY,
  APPOINTMENT_PROFICIENT,
} from '../../utils/urls';
import { capitalize, isProfessional } from '../../utils/helpers';
import { useStyles } from './style';
import moment from 'moment-timezone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { doctorsActionsTypes } from '../../redux/doctor/actions';
import { proficientsActionsTypes } from '../../redux/proficient/actions';

import { useTranslation } from 'react-i18next';

function Agenda({
  getDoctorInfo,
  createSchedule,
  doctorsInfo,
  session,
  appointmentsData,
  getSchedulesByDate,
  deleteMessages,
  deleteSchedule,
  doctors,
  proficients,
  userState,
  getProficientDates,
  clearSelectedDoctor,
  setSelectedDoctor,
  setSelectedProficient,
  clearSelectedProficient,
}) {
  const [t, i18n] = useTranslation('global');
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const appointmentType = location.state.specialtyType;
  const specialtyId = location.state.specialtyId;
  const professionals = location.state.doctorsSpecialty;

  const [dateHandler, setDateHandler] = useState({
    currentWeek: 0,
    currentMonth: 0,
    showByWeek: true,
  });
  const { currentWeek, currentMonth, showByWeek } = dateHandler;

  const [availability, setAvailability] = useState({
    start: null,
    end: null,
    isEditable: false,
    createModalOpen: false,
    openModalAvailability: false,
  });
  const [selectedSchedule, setSelectedSchedule] = useState({
    id: null,
    groupId: null,
    editModalOpen: false,
  });

  const { start, end, isEditable, createModalOpen, openModalAvailability } =
    availability;

  const doctorId = doctors.selectedDoctor ? doctors.selectedDoctor.id : null;

  const [isCreateAppointment, setIsCreateAppointment] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState('');

  useEffect(() => {
    if (appointmentsData.success.genericMessage) {
      let start =
        currentWeek === 0
          ? moment()
              .day(1)
              .add(currentWeek * 7, 'days')
              .set('hour', '00')
              .set('minute', '00')
              .tz('America/Montevideo')
              .format('YYYY-MM-DD HH:mm')
          : moment()
              .day(1)
              .add(currentWeek * 7, 'days')
              .set('hour', '00')
              .set('minute', '00')
              .tz('America/Montevideo')
              .format('YYYY-MM-DD HH:mm');

      let queryParams = [];
      let queryValues = [];

      if (appointmentType === 'Peritaje') {
        if (proficients.selectedProficient?.id) {
          getProficientDates(
            start,
            moment()
              .day(1)
              .add(currentWeek * 7 + 7, 'days')
              .tz('America/Montevideo')
              .format('YYYY-MM-DD HH:mm'),
            session.userType == 'assistant' || session.userType == 'operator'
              ? proficients.selectedProficient
                ? proficients.selectedProficient.id
                : null
              : session.id
          );
        }
      } else {
        if (doctors.selectedDoctor?.id) {
          queryParams.push('doctor');
          queryValues.push(doctors.selectedDoctor.id);

          getSchedulesByDate(
            start,
            moment()
              .day(1)
              .add(currentWeek * 7 + 7, 'days')
              .tz('America/Montevideo')
              .format('YYYY-MM-DD HH:mm'),
            // session.userType == 'assistant' || session.userType == 'operator'
            //   ? doctorId
            //   : session.id
            queryParams,
            queryValues
          );
        } else {
          if (
            session.userType == 'assistant' ||
            session.userType == 'operator'
          ) {
            queryParams.push('specialty');
            queryValues.push(specialtyId);
          } else if (session.userType == 'doctor') {
            queryParams.push('doctor');
            queryValues.push(session.id);
          } else {
            queryParams.push('patient');
            queryValues.push(session.id);
          }

          getSchedulesByDate(
            start,
            moment()
              .day(1)
              .add(currentWeek * 7 + 7, 'days')
              .tz('America/Montevideo')
              .format('YYYY-MM-DD HH:mm'),
            // session.userType == 'assistant' || session.userType == 'operator'
            //   ? doctorId
            //   : session.id
            queryParams,
            queryValues
          );
        }
      }
    }
  }, [appointmentsData.success.genericMessage]);

  useEffect(() => {
    if (session && isProfessional(session.userType)) {
      getDoctorInfo(session.userType, session.id);
    }

    let start =
      currentWeek === 0
        ? moment()
            .day(1)
            .set('hour', '00')
            .set('minute', '00')
            .add(currentWeek * 7, 'days')
            .tz('America/Montevideo')
            .format('YYYY-MM-DD HH:mm')
        : moment()
            .day(1)
            .set('hour', '00')
            .set('minute', '00')
            .add(currentWeek * 7, 'days')
            .tz('America/Montevideo')
            .format('YYYY-MM-DD HH:mm');

    let queryParams = [];
    let queryValues = [];

    if (appointmentType === 'Peritaje') {
      if (proficients.selectedProficient?.id) {
        getProficientDates(
          start,
          moment()
            .day(1)
            .add(currentWeek * 7 + 7, 'days')
            .tz('America/Montevideo')
            .format('YYYY-MM-DD HH:mm'),
          session.userType == 'assistant' || session.userType == 'operator'
            ? proficients.selectedProficient
              ? proficients.selectedProficient.id
              : null
            : session.id
        );
      }
    } else {
      if (doctors.selectedDoctor?.id) {
        queryParams.push('doctor');
        queryValues.push(doctors.selectedDoctor.id);

        getSchedulesByDate(
          start,
          moment()
            .day(1)
            .add(currentWeek * 7 + 7, 'days')
            .tz('America/Montevideo')
            .format('YYYY-MM-DD HH:mm'),
          // session.userType == 'assistant' || session.userType == 'operator'
          //   ? doctorId
          //   : session.id
          queryParams,
          queryValues
        );
      } else {
        if (session.userType == 'assistant' || session.userType == 'operator') {
          queryParams.push('specialty');
          queryValues.push(specialtyId);
        } else if (session.userType == 'doctor') {
          queryParams.push('doctor');
          queryValues.push(session.id);
        } else {
          queryParams.push('patient');
          queryValues.push(session.id);
        }

        getSchedulesByDate(
          start,
          moment()
            .day(1)
            .add(currentWeek * 7 + 7, 'days')
            .tz('America/Montevideo')
            .format('YYYY-MM-DD HH:mm'),
          // session.userType == 'assistant' || session.userType == 'operator'
          //   ? doctorId
          //   : session.id
          queryParams,
          queryValues
        );
      }
    }
  }, [currentWeek, doctors.selectedDoctor, proficients.selectedProficient]);

  useEffect(() => {
    if (
      appointmentsData.scheduleCreated &&
      (session.userType == 'assistant' || session.userType == 'operator') &&
      isCreateAppointment &&
      appointmentsData.success.genericMessage == 'schedule creado con exito'
    ) {
      setIsCreateAppointment(false);
      if (appointmentType === 'Veterinaria') {
        history.push({
          pathname: APPOINTMENT_VETERINARY,
          state: {
            schedule: appointmentsData.scheduleCreated,
            appointmentType,
          },
        });
      } else {
        history.push({
          pathname: APPOINTMENT_LIGHT,
          state: {
            schedule: appointmentsData.scheduleCreated,
            appointmentType,
            specialtyId,
            professionals,
          },
        });
      }
    }
  }, [appointmentsData.success.genericMessage]);

  useEffect(() => {
    if (isCreateAppointment && appointmentType === 'Peritaje') {
      setIsCreateAppointment(false);
      history.push({
        pathname: APPOINTMENT_PROFICIENT,
        state: {
          schedule: {
            proficient: proficients.selectedProficient,
            date: appointmentDate,
          },
          appointmentType,
        },
      });
    }
  }, [isCreateAppointment]);

  const handleChangeDoctor = (_, value) => {
    if (value == null) {
      clearSelectedDoctor();

      // setDoctorFullData(null);
    } else {
      // setDoctorFullData(value);
      setSelectedDoctor(value);
    }
  };

  const handleChangeProficient = (e, value) => {
    if (value == null) {
      clearSelectedProficient();

      // setProficientData(null);
    } else {
      // setProficientData(value);
      setSelectedProficient(value);
    }
  };

  return (
    <>
      {appointmentsData.success.genericMessage ===
        'Schedule eliminado con exito' && (
        <Notification
          type="success"
          message={`${capitalize(t('word.availability'))} ${t('word.deleted')}`}
          onCloseCallback={deleteMessages}
        />
      )}

      {appointmentsData.success.genericMessage ===
        'schedule creado con exito' && (
        <Notification
          type="success"
          message={`${capitalize(t('word.availability'))} ${t(
            'word.added'
          )} ${t('prep.with')} ${t('word.success')}`}
          onCloseCallback={deleteMessages}
        />
      )}
      {appointmentsData.success.genericMessage ==
        'Appointment creado con exito' && (
        <Notification
          type="success"
          message={`Consulta ${t('word.created')} ${t('prep.with')} ${t(
            'word.success'
          )}`}
          onCloseCallback={deleteMessages}
        />
      )}
      {appointmentsData.error && (
        <Notification
          type="error"
          message={`${t('sentence.errorNotification')}`}
          onCloseCallback={deleteMessages}
        />
      )}

      <DoctariModal
        open={
          // session.userType != 'assistant'
          createModalOpen
          // : openModalAvailability
        }
        setOpen={(val) =>
          setAvailability({
            ...availability,
            createModalOpen: val,
            openModalAvailability: val,
          })
        }
        title={
          (session.userType == 'assistant' ||
            session.userType == 'operator' ||
            userState?.user?.self_managed) &&
          !openModalAvailability
            ? `${capitalize(t('word.select'))} ${t('word.action')}`
            : `Marcar horas como ${t('word.available')}`
        }
      >
        <CreateScheduleModal
          availabilityStart={availability.start}
          availabilityEnd={availability.end}
          createSchedule={createSchedule}
          classes={classes}
          addresses={
            doctors.selectedDoctor
              ? doctors.selectedDoctor.address
              : doctorsInfo?.address
          }
          token={session.accessToken}
          professionalId={
            session.userType === 'assistant' || session.userType === 'operator'
              ? appointmentType === 'Peritaje'
                ? proficients.selectedProficient?.id
                : doctorId
              : session.id
          }
          setAvailability={setAvailability}
          isSelfManaged={
            session.userType === 'assistant' ||
            userState?.user?.self_managed ||
            session.userType === 'operator'
          } // TODO add userState.user.self_managed
          openModalAvailability={openModalAvailability}
          setIsCreateAppointment={setIsCreateAppointment}
          slot={session.slot}
          isProficient={appointmentType === 'Peritaje'}
          setAppointmentDate={setAppointmentDate}
          language={localStorage.getItem('language')}
          isAssistant={session.userType === 'assistant'}
          isOperator={session.userType === 'operator'}
          isDoctorSelected={doctors.selectedDoctor != null}
          isProficientSelected={proficients.selectedProficient != null}
        />
      </DoctariModal>
      {(session.userType === 'assistant' || session.userType == 'operator') && (
        <FormControl className={classes.selectProfessionalFormControl}>
          {appointmentType !== 'Peritaje' ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={professionals}
              getOptionLabel={(option) => option.full_name || ''}
              sx={{ width: 300 }}
              value={doctors.selectedDoctor}
              onChange={handleChangeDoctor}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${capitalize(t('word.select'))} ${t(
                    'word.professional'
                  )}`}
                />
              )}
            />
          ) : (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={professionals}
              getOptionLabel={(option) => option.full_name || ''}
              sx={{ width: 300 }}
              value={proficients.selectedProficient}
              onChange={handleChangeProficient}
              renderInput={(params) => (
                <TextField {...params} label="Seleccione profesional" />
              )}
            />
          )}
        </FormControl>
      )}
      <Grid
        container
        style={{ marginBottom: '5rem' }}
        className={classes.agendaContainer}
      >
        <Grid item xs={12} md={12} className={classes.changeView}>
          <span
            style={{
              marginRight: '0.5rem',
              cursor: 'pointer',
              fontWeight: showByWeek ? 700 : 200,
            }}
            onClick={() =>
              setDateHandler({
                ...dateHandler,
                showByWeek: true,
                currentMonth: 0,
              })
            }
          >{`Semana`}</span>
          <span
            style={{
              fontSize: '34px',
              fontWeight: 200,
            }}
          >
            |
          </span>
          <span
            style={{
              marginLeft: '0.5rem',
              cursor: 'pointer',
              fontWeight: showByWeek ? 200 : 700,
            }}
            onClick={() =>
              setDateHandler({
                ...dateHandler,
                showByWeek: false,
                currentWeek: 0,
              })
            }
          >
            {t('Agenda.month')}
          </span>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DateControl
            showingByWeek={showByWeek}
            setCurrentWeek={(week) =>
              setDateHandler({ ...dateHandler, currentWeek: week })
            }
            currentWeek={currentWeek}
            setCurrentMonth={(month) =>
              setDateHandler({ ...dateHandler, currentMonth: month })
            }
            currentMonth={currentMonth}
            language={localStorage.getItem('language')}
          />
        </Grid>
        {!showByWeek ? (
          <MonthCalendar
            currMonth={currentMonth}
            classes={classes}
            goToWeek={(week) =>
              setDateHandler({
                ...dateHandler,
                currentWeek: week,
                currentMonth: 0,
                showByWeek: true,
              })
            }
          />
        ) : (
          <DoctariCalendar
            currentWeek={currentWeek}
            creatingAvailability
            availability={availability}
            setAvailability={setAvailability}
            schedules={appointmentsData.schedules}
            deleteSchedule={deleteSchedule}
            selectedSchedule={selectedSchedule}
            setSelectedSchedule={setSelectedSchedule}
            isSelfManaged={
              session.userType == 'assistant' ||
              userState?.user?.self_managed ||
              session.userType == 'operator'
            }
            isAssistant={session.userType == 'assistant'}
            isOperator={session.userType == 'operator'}
            slot={
              session.userType == 'doctor'
                ? session.slot
                : (session.userType == 'assistant' ||
                    session.userType == 'operator') &&
                  appointmentType != 'Peritaje' &&
                  doctors.selectedDoctor
                ? doctors.selectedDoctor.specialty.duration.minutes
                : (session.userType == 'assistant' ||
                    session.userType == 'operator') &&
                  appointmentType == 'Peritaje' &&
                  proficients.selectedProficient
                ? proficients.selectedProficient.slot
                : session.slot
            }
            appointmentType={appointmentType}
            proficientDates={appointmentsData.proficientDates}
            schedulesInformation={{
              specialtyId,
              professionals,
            }}
          >
            {isEditable && (
              <Button
                variant="outlined"
                color="primary"
                style={{ width: '245px', marginRight: '0.5rem' }}
                onClick={() => {
                  setAvailability({
                    ...availability,
                    isEditable: false,
                    start: null,
                    end: null,
                  });
                }}
              >
                Cancelar
              </Button>
            )}
            {appointmentType !== 'Peritaje' && (
              <Button
                variant="outlined"
                color="primary"
                style={{ width: '206px' }}
                endIcon={isEditable ? <CheckCircle /> : <DateRange />}
                onClick={() => {
                  if (isEditable) {
                    setAvailability({
                      ...availability,
                      createModalOpen: true,
                      openModalAvailability: true,
                    });
                  } else {
                    setAvailability({
                      ...availability,
                      isEditable: true,
                    });
                  }
                }}
                disabled={(!start || !end) && isEditable}
              >
                {isEditable ? t('Agenda.confirm') : t('Agenda.select-range')}
              </Button>
            )}
          </DoctariCalendar>
        )}
      </Grid>
    </>
  );
}

const mapStateToProps = ({
  session,
  user,
  appointments,
  doctors,
  proficients,
}) => {
  return {
    doctorsInfo: user.user,
    session,
    appointmentsData: appointments,
    userState: user,
    doctors,
    proficients,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createSchedule: (body, isMultiple, token) =>
    dispatch({
      type: appointmentActionsTypes.CREATE_SCHEDULE,
      payload: { body, isMultiple, token },
    }),
  getDoctorInfo: (type, id) =>
    dispatch({
      type: userActionsTypes.GET_USER,
      payload: { type, id },
    }),
  getSchedulesByDate: (start, end, queryParams, queryValues) =>
    dispatch({
      type: appointmentActionsTypes.GET_SCHEDULES_BY_DATE,
      payload: {
        start,
        end,
        queryParams,
        queryValues,
      },
    }),
  deleteSchedule: (token, scheduleId, isMultiple) =>
    dispatch({
      type: appointmentActionsTypes.DELETE_SCHEDULE,
      payload: {
        token,
        scheduleId,
        isMultiple,
      },
    }),

  deleteMessages: () =>
    dispatch({
      type: userActionsTypes.DELETE_MESSAGES,
    }),

  getProficientDates: (start, end, proficientId) =>
    dispatch({
      type: appointmentActionsTypes.GET_PROFICIENT_DATES,
      payload: {
        start,
        end,
        proficientId,
      },
    }),

  clearSelectedDoctor: () =>
    dispatch({ type: doctorsActionsTypes.CLEAR_SELECT_DOCTOR }),

  setSelectedDoctor: (doctorBody) =>
    dispatch({
      type: doctorsActionsTypes.SET_DOCTOR_BODY,
      payload: doctorBody,
    }),

  setSelectedProficient: (proficientBody) =>
    dispatch({
      type: proficientsActionsTypes.SET_PROFICIENT_BODY,
      payload: proficientBody,
    }),

  clearSelectedProficient: () =>
    dispatch({ type: proficientsActionsTypes.CLEAR_SELECT_PROFICIENT }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Agenda);
