import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/es';
import { IconButton, Box } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { capitalize, mobileScreen } from '../../utils/helpers';

function DateControl({
  showingByWeek,
  setCurrentWeek,
  currentWeek,
  setCurrentMonth,
  currentMonth,
  language,
}) {
  const isMobile = mobileScreen();
  const WeekControl = () => {
    return (
      <Box>
        <IconButton
          onClick={() => setCurrentWeek(currentWeek - 1)}
          disabled={currentWeek === 0}
        >
          <NavigateBefore
            fontSize="inherit"
            style={{ color: currentWeek !== 0 ? '#707070' : '#b8b8b8' }}
          />
        </IconButton>
        {isMobile ? (
          <span style={{ fontSize: '19px', textAlign: 'center' }}>{`${moment()
            .locale(language)
            .add(currentWeek * 7, 'days')
            .startOf('week')
            .format('DD')} - ${moment()
            .add(currentWeek * 7, 'days')
            .endOf('week')
            .format('DD [de] MMMM [de] YYYY')}`}</span>
        ) : (
          <span style={{ fontSize: '19px', textAlign: 'center' }}>{`${moment()
            .locale(language)
            .add(currentWeek * 7, 'days')
            .startOf('week')
            .format('LL')} - ${moment()
            .locale(language)
            .add(currentWeek * 7, 'days')
            .endOf('week')
            .format('LL')}`}</span>
        )}
        <IconButton onClick={() => setCurrentWeek(currentWeek + 1)}>
          <NavigateNext fontSize="inherit" style={{ color: '#707070' }} />
        </IconButton>
      </Box>
    );
  };

  const MonthControl = () => {
    return (
      <Box>
        <IconButton
          onClick={() => setCurrentMonth(currentMonth - 1)}
          disabled={currentMonth === 0}
        >
          <NavigateBefore
            fontSize="inherit"
            style={{ color: currentMonth !== 0 ? '#707070' : '#b8b8b8' }}
          />
        </IconButton>
        <span style={{ fontSize: '19px', textAlign: 'center', width: '33rem' }}>
          {capitalize(
            moment()
              .locale(language)
              .add(currentMonth, 'month')
              .format('MMMM [de] YYYY')
          )}
        </span>
        <IconButton onClick={() => setCurrentMonth(currentMonth + 1)}>
          <NavigateNext fontSize="inherit" style={{ color: '#707070' }} />
        </IconButton>
      </Box>
    );
  };

  return showingByWeek ? <WeekControl /> : <MonthControl />;
}

export default DateControl;
