import { useState } from 'react';
import moment from 'moment-timezone';
import {
  Button,
  Grid,
  Chip,
  Box,
  Tooltip,
  useTheme,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import References from './References';
import { useStyles } from './style';
import { getSlotsRange, capitalize, mobileScreen } from '../../utils/helpers';
import DoctariModal from '../Modal';
import {
  APPOINTMENT,
  APPOINTMENT_LIGHT,
  APPOINTMENT_VETERINARY,
} from '../../utils/urls';
import EditScheduleModal from '../../screens/agenda/EditScheduleModal';
import { useTranslation } from 'react-i18next';

const SCHEDULE_TYPE = {
  internal: 1,
  online: 2,
  faceToFace: 3,
};
const COLOR = {
  internal: '#d7f1da',
  faceToFace: '#d2effd',
  online: '#d4d6fd',
  triple:
    'linear-gradient(to right, #d7f1da 0%,#d7f1da 33%,#000000 33%,#d2effd 33%,#d2effd 66%, #000000 66%, #d4d6fd 66%, #d4d6fd 100%)',
  taken: '#fde9cc',
  selected: 'lightgrey',
  default: '#eff1f2',
};

function DoctariCalendar({
  creatingAvailability,
  creatingAppointment,
  children,
  currentWeek,
  availability,
  setAvailability,
  schedules,
  deleteSchedule,
  isSelfManaged,
  isAssistant,
  slot,
  schedulesInformation,
  appointmentType,
  proficientDates,
  isOperator,
}) {
  const classes = useStyles();
  const isMobile = mobileScreen();
  const { start, end, isEditable } = availability || {};
  const theme = useTheme();
  const [selectedScheduleForAppointment, setSelectedScheduleForAppointment] =
    useState();
  const [
    selectedScheduleForAppointmentDate,
    setSelectedScheduleForAppointmentDate,
  ] = useState();
  const [modalOpen, setModalOpen] = useState({
    appointment: false,
    createSchedule: false,
    deleteSchedule: false,
    scheduleExistAppointment: false,
  });
  const [actionType, setActionType] = useState(null);
  const [t, i18n] = useTranslation('global');
  const history = useHistory();

  const isAppointmentProficient = appointmentType == 'Peritaje';

  const [doctorsAvailableForAppointment, setDoctorsAvailableForAppointment] =
    useState([]);

  const selectDate = (date) => {
    if (isEditable) {
      if (start) {
        if (start === date) {
          setAvailability({
            ...availability,
            start: availability.end,
            end: null,
          });
        } else if (moment(start).isSame(moment(date), 'day')) {
          if (moment(start).diff(moment(date)) > 0) {
            setAvailability({
              ...availability,
              start: date,
              end: availability.start,
            });
          } else {
            setAvailability({ ...availability, end: date });
          }
        }
      } else {
        setAvailability({ ...availability, start: date });
      }
    } else {
      if (start === date) {
        setAvailability({ ...availability, start: null });
      } else {
        setAvailability({
          ...availability,
          start: date,
          createModalOpen: true,
        });
      }
    }
  };

  const selectScheduleForAppointment = (schedule, isForEdit) => {
    if (!isForEdit) {
      setModalOpen({ ...modalOpen, appointment: true });
    }

    setSelectedScheduleForAppointment(schedule);
    const scheduleDate = moment(schedule.start)
      .tz(moment.tz.guess())
      .format('LL, h:mm: a');

    setSelectedScheduleForAppointmentDate(scheduleDate);
  };

  const isSelected = (date) => {
    let selected = false;
    if (start === date || end === date) {
      selected = true;
    } else if (start && end && start < date && date < end) {
      selected = true;
    }
    return selected;
  };

  const Hours = ({ day }) => {
    const hours = getSlotsRange(slot);
    const hoursList = hours.map((hour, index) => {
      let currentScheduleAvailable = false;
      let chipColor = COLOR.default;

      // The server returns the schedules in -03:00 time zone (Montevideo), so we need to convert them to the local timezone.

      //  This is your actual date, with your current date time zone.
      const currentDate = day
        .set('hour', hour.slice(0, 2))
        .set('minute', hour.slice(3, 5))
        .seconds(0)
        .tz(moment.tz.guess())
        .format();

      //  This the date returned by the API, with the server time zone (-03:00).
      const currentDateBack = moment(currentDate)
        .tz('America/Montevideo')
        .format();

      let currentSchedule = null;
      let doctorsAvailableHelper = [];
      let scheduleDoctorAvailable = null;
      if (schedules && schedules[currentDateBack]) {
        // Schedules is an object with the date (with server time zone) as key and an array of schedules as value.
        if (isAssistant || isOperator) {
          doctorsAvailableHelper = schedules[currentDateBack].filter(
            (schedule) => !schedule.taken
          );
          scheduleDoctorAvailable =
            schedules[currentDateBack].find((schedule) => !schedule.taken) ||
            schedules[currentDateBack][0];
        }
        currentSchedule =
          isAssistant || isOperator
            ? scheduleDoctorAvailable
            : schedules[currentDateBack][0];
      }
      //Calendar painting
      if (currentSchedule) {
        if (currentSchedule.taken == true) {
          // Taken schedule
          chipColor = COLOR.taken;
          currentScheduleAvailable = false;
        } else if (currentSchedule.types.length == 3) {
          // 3 schedules types painted with linear gradient defined as constant
          chipColor = COLOR.triple;
          currentScheduleAvailable = true;
        } else if (currentSchedule.types.length == 2) {
          // 2 schedules types, painted with linear gradient
          chipColor = `linear-gradient(to right, ${
            schedules[currentDate][0].types[0] == SCHEDULE_TYPE.internal
              ? COLOR.internal
              : COLOR.faceToFace
          } 0%, ${
            schedules[currentDate][0].types[0] == SCHEDULE_TYPE.internal
              ? COLOR.internal
              : COLOR.faceToFace
          } 50%,#000000 50%,${
            schedules[currentDate][0].types[0] == SCHEDULE_TYPE.online
              ? COLOR.online
              : COLOR.faceToFace
          } 50%,${
            schedules[currentDate][0].types[0] == SCHEDULE_TYPE.online
              ? COLOR.online
              : COLOR.faceToFace
          } 100%)`;
          currentScheduleAvailable = true;
        } else if (currentSchedule.types.length == 1) {
          // 1 schedule type
          chipColor =
            currentSchedule.types[0] == SCHEDULE_TYPE.internal
              ? COLOR.internal
              : currentSchedule.types[0] == SCHEDULE_TYPE.online
              ? COLOR.online
              : COLOR.faceToFace;
          currentScheduleAvailable = true;
        }
      }

      if (creatingAppointment) {
        // The calendar in which we can create appointment directly. The doctor's profile calendar.
        return (
          <Tooltip title={currentScheduleAvailable ? 'Click para agendar' : ''}>
            <Chip
              label={hour}
              className={classes.chip}
              style={{
                marginBottom: index + 1 === hours.length ? '1rem' : '0rem',
                background: isSelected(currentDate)
                  ? COLOR.selected
                  : chipColor,
                cursor: currentScheduleAvailable ? 'pointer' : 'default',
              }}
              onClick={() =>
                currentScheduleAvailable &&
                selectScheduleForAppointment(currentSchedule)
              }
              clickable={currentScheduleAvailable ? true : false}
              disabled={
                moment().isSameOrAfter(currentDate) || chipColor === COLOR.taken
              }
            />
          </Tooltip>
        );
      } else {
        // Classic Doctari calendar. For editing it we have to go to edit mode. Doctor/assistant's home calendar.
        return (
          <Tooltip
            title={
              creatingAvailability
                ? `${capitalize(t('word.click'))} para modificar`
                : ''
            }
          >
            <Chip
              label={hour}
              className={classes.chip}
              style={{
                marginBottom: index + 1 === hours.length ? '1rem' : '0rem',
                background: isSelected(currentDate)
                  ? COLOR.selected
                  : chipColor,
                cursor: creatingAvailability ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (creatingAvailability && chipColor == COLOR.default) {
                  selectDate(currentDate);
                } else if (creatingAvailability) {
                  setModalOpen({
                    ...modalOpen,
                    scheduleExistAppointment: isSelfManaged,
                    deleteSchedule: !isSelfManaged,
                  });
                  selectScheduleForAppointment(
                    schedules[currentDateBack][0],
                    true
                  ); //TODO cambiar esto en el modal de fausto (el select schedule)
                }
                setDoctorsAvailableForAppointment(doctorsAvailableHelper);

                chipColor = COLOR.default;
              }}
              clickable={false}
              disabled={
                isAssistant
                  ? moment().isSameOrAfter(currentDate) ||
                    chipColor === COLOR.taken ||
                    chipColor === COLOR.default
                  : moment().isSameOrAfter(currentDate) ||
                    chipColor === COLOR.taken
              }
            />
          </Tooltip>
        );
      }
    });

    return hoursList;
  };

  const weeksDay = (day) => {
    let dayName = day.slice(0, 3);
    let dayNumber = day.slice(3);

    if (dayName === 'lun') {
      dayName = t('calendarDays.monday');
    } else if (dayName === 'mar') {
      dayName = t('calendarDays.tuesday');
    } else if (dayName === 'mié') {
      dayName = t('calendarDays.wednesday');
    } else if (dayName === 'jue') {
      dayName = t('calendarDays.thursday');
    } else if (dayName === 'vie') {
      dayName = t('calendarDays.friday');
    } else if (dayName === 'sab') {
      dayName = t('calendarDays.saturday');
    } else if (dayName === 'dom') {
      dayName = t('calendarDays.sunday');
    }

    return dayName + dayNumber;
  };

  const ProficientDatesHours = ({ day }) => {
    const hours = getSlotsRange(slot);

    const hoursList = hours.map((hour, index) => {
      let currentHourAvailable = false;
      let chipColor = COLOR.default;

      const currentDate = day
        .set('hour', hour.slice(0, 2))
        .set('minute', hour.slice(3, 5))
        .format('YYYY-MM-DD HH:mm');

      const currentDateBack = moment(currentDate)
        .tz('America/Montevideo')
        .format();

      let currentHourDate = null;
      if (proficientDates && proficientDates[currentDateBack]) {
        currentHourDate = proficientDates[currentDateBack][0];
      }
      //Calendar painting
      if (currentHourDate) {
        chipColor = COLOR.taken;
        currentHourAvailable = false;
      }

      if (creatingAppointment) {
        // The calendar in which we can create appointment directly. The doctor's profile calendar.
        return (
          <Tooltip title={currentHourAvailable ? 'Click para agendar' : ''}>
            <Chip
              label={hour}
              className={classes.chip}
              style={{
                marginBottom: index + 1 === hours.length ? '1rem' : '0rem',
                background: isSelected(currentDate)
                  ? COLOR.selected
                  : chipColor,
                cursor: currentHourAvailable ? 'pointer' : 'default',
              }}
              onClick={() =>
                currentHourAvailable &&
                selectScheduleForAppointment(currentHourDate)
              }
              clickable={currentHourAvailable ? true : false}
            />
          </Tooltip>
        );
      } else {
        // Classic Doctari calendar. For editing it we have to go to edit mode. Doctor/assistant's home calendar.
        return (
          <Tooltip
            title={
              creatingAvailability
                ? `${capitalize(t('word.click'))} para modificar`
                : ''
            }
          >
            <Chip
              label={hour}
              className={classes.chip}
              style={{
                marginBottom: index + 1 === hours.length ? '1rem' : '0rem',
                background: isSelected(currentDate)
                  ? COLOR.selected
                  : chipColor,
                cursor: creatingAvailability ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (creatingAvailability && chipColor == COLOR.default) {
                  selectDate(currentDate);
                } else if (creatingAvailability) {
                  setModalOpen({
                    ...modalOpen,
                    scheduleExistAppointment: isSelfManaged,
                    deleteSchedule: !isSelfManaged,
                  });
                  selectScheduleForAppointment(
                    schedules[currentDateBack][0],
                    true
                  ); //TODO cambiar esto en el modal de fausto (el select schedule)
                }
                chipColor = COLOR.default;
              }}
              clickable={false}
              disabled={
                moment().isSameOrAfter(currentDate) || chipColor === COLOR.taken
              }
            />
          </Tooltip>
        );
      }
    });

    return hoursList;
  };

  const Days = () => {
    const calendarDay = [];
    for (let i = 0; i < 7; i++) {
      calendarDay.push(
        <div
          style={{
            border: 'solid 1px white',
            color: `${
              moment().isSame(
                moment()
                  .day(1)
                  .add(currentWeek * 7 + i, 'days')
                  .tz(moment.tz.guess())
              )
                ? theme.palette.primary.main
                : 'black'
            }`,
            fontWeight: `${
              moment().isSame(
                moment()
                  .day(1)
                  .add(currentWeek * 7 + i, 'days')
                  .tz(moment.tz.guess())
              ) && 'bold'
            }`,
          }}
        >
          <p className={classes.dayName}>
            {capitalize(
              moment()
                .day(1)
                .add(currentWeek * 7 + i, 'days')
                .tz(moment.tz.guess())
                .format('ddd DD')
            )}
          </p>
        </div>
      );
    }
    return calendarDay;
  };

  const DaysHours = () => {
    const dayHoursList = [];
    for (let i = 0; i < 7; i++) {
      dayHoursList.push(
        <div
          className={classes.hourContainer}
          style={{
            backgroundColor: 'white',
          }}
        >
          {isAppointmentProficient ? (
            <ProficientDatesHours
              day={moment()
                .day(1)
                .add(currentWeek * 7 + i, 'days')
                .tz(moment.tz.guess())}
            />
          ) : (
            <Hours
              day={moment()
                .day(1)
                .add(currentWeek * 7 + i, 'days')
                .tz(moment.tz.guess())}
            />
          )}
        </div>
      );
    }
    return dayHoursList;
  };

  const ReferenceSection = () => {
    return (
      <div className={classes.referenceContainer}>
        <References />
        <Box>{children}</Box>
      </div>
    );
  };

  const handleAction = () => {
    if (actionType == 'deleteAvailability') {
      setModalOpen({
        ...modalOpen,
        scheduleExistAppointment: false,
        deleteSchedule: true,
      });
    } else {
      if (appointmentType == 'Veterinaria') {
        history.push({
          pathname: APPOINTMENT_VETERINARY,
          state: {
            schedule: selectedScheduleForAppointment,
            appointmentType,
            specialtyId: schedulesInformation.specialtyId,
            professionals: schedulesInformation.professionals,
            doctorsAvailableForAppointment,
          },
        });
      } else {
        history.push({
          pathname: APPOINTMENT_LIGHT,
          state: {
            schedule: selectedScheduleForAppointment,
            appointmentType,
            specialtyId: schedulesInformation.specialtyId,
            professionals: schedulesInformation.professionals,
            doctorsAvailableForAppointment,
          },
        });
      }
    }
  };

  return (
    <Grid container style={{ marginBottom: '5rem' }}>
      <DoctariModal // create appointment modal
        open={modalOpen.appointment}
        setOpen={(val) => setModalOpen({ ...modalOpen, appointment: val })}
        title={'Agendar consulta'}
      >
        <div style={{ margin: 10 }}>
          <p style={{ margin: 10 }}>
            {`Agendar consulta para ${t(
              'prep.the-1'
            )} ${selectedScheduleForAppointmentDate}`}
          </p>
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              history.push({
                pathname: APPOINTMENT,
                state: {
                  doctor: selectedScheduleForAppointment.doctor,
                  schedule: selectedScheduleForAppointment,
                  isAssistant,
                },
              })
            }
            style={{ float: 'right', marginBottom: 15 }}
          >
            Agendar
          </Button>
        </div>
      </DoctariModal>

      <DoctariModal
        open={modalOpen.scheduleExistAppointment}
        setOpen={(val) =>
          setModalOpen({ ...modalOpen, scheduleExistAppointment: val })
        }
        title={`${capitalize(t('word.select'))} ${t('word.action')}`}
      >
        <Box style={{ padding: '2rem' }}>
          <RadioGroup
            onChange={(e) => setActionType(e.target.value)}
            //   value={repeat}
            row
            style={{ justifyContent: 'space-around' }}
          >
            {!isAssistant && (
              <FormControlLabel
                value="deleteAvailability"
                control={<Radio color="primary" />}
                label={`${capitalize(t('word.delete'))} ${t(
                  'word.availability'
                )}`}
              />
            )}

            <FormControlLabel
              value="appointment"
              control={<Radio color="primary" />}
              label="Agendar consulta"
            />
          </RadioGroup>

          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
            }}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={!actionType}
              style={{ width: '200px' }}
              onClick={() => {
                handleAction();
              }}
            >
              Aceptar
            </Button>
          </Grid>
        </Box>
      </DoctariModal>

      <DoctariModal // deleting schedule modal
        open={modalOpen.deleteSchedule}
        setOpen={(val) => setModalOpen({ ...modalOpen, deleteSchedule: val })}
        title={t('DoctariModal.edit-availability')}
      >
        <EditScheduleModal
          setModalOpen={setModalOpen}
          availabilityStart={start}
          availabilityEnd={end}
          setAvailability={setAvailability}
          selectedSchedule={selectedScheduleForAppointment}
          deleteSchedule={deleteSchedule}
          slot={slot}
        />
      </DoctariModal>
      <Grid
        item
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className={classes.weekContainer}>
          <div style={{ display: 'flex' }}>
            <Days />
          </div>

          <div className={classes.hourList}>
            <div style={{ display: 'flex' }}>
              <DaysHours />
            </div>
          </div>
          {!isMobile && <ReferenceSection />}
        </div>
        {isMobile && <ReferenceSection />}
      </Grid>
    </Grid>
  );
}

export default DoctariCalendar;
