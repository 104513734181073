import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  agendaContainer: {
    fontFamily: theme.typography.fontFamily,
    display: 'flex',
    justifyContent: 'center',
    // marginBottom: '5rem',
  },
  availability: {
    display: 'flex',
    justifyContent: 'end',
    margin: '1rem 0',
    '@media (max-width: 900px)': {
      flexDirection: 'column',
      alignItems: 'start',
      height: '4rem',
      justifyContent: 'space-between',
      marginLeft: '1rem',
    },
  },
  daysContainer: {
    display: 'flex',
    justifyContent: 'center',
    color: '#c9c9c9',
    '@media (max-width: 900px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  avatar: {
    border: `solid 2px ${theme.palette.lightPrimary.main}`,
    marginRight: '1rem',
    width: '5rem',
    height: '5rem',
  },
  daysContainerDateControl: {
    display: 'flex',
    justifyContent: 'center',
    color: 'black',
    '@media (max-width: 900px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));
