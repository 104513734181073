import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

// import { PatientProfile } from 'frontend_doctari_core';
import DoctorProfile from './DoctorProfile';
import PatientProfile from './PatientProfile';
import Notification from '../../components/Notification';
import { userActionsTypes } from '../../redux/user/actions';
import {
  validateEmail,
  isOlderThan18,
  isValidPassword,
} from '../../utils/validators';
import { capitalize, isProfessional } from '../../utils/helpers';
import { useStyles } from './style';
import AssistantProfile from './AssistantProfile';
import { useTranslation } from 'react-i18next';

import { doctorsActionsTypes } from '../../redux/doctor/actions';

function Profile({
  session,
  userData,
  getUserData,
  updateUserData,
  updateUserPassword,
  updateProfilePic,
  createInfo,
  addUserInfo,
  deleteUserInfo,
  deleteMessages,
  manageAdresses,
  doctors,
  setSelectedDoctor,
  clearSelectedDoctor,
  clearMessage,
  updateSignature,
  updateSelectedDoctor,
  manageAddressDoctor,
  getDoctorsAssistant,
}) {
  const classes = useStyles();
  const location = useLocation();
  const isDoctor = isProfessional(session.userType || '');
  const isProfileDoctor = location.state.profileType === 'profileDoctor';
  const [t, i18n] = useTranslation('global');
  /*   const [userLoad, setUserLoad] = useState(false);


  const [state, setState] = useState({
    dateOfAppointment: null,
    doctorName: null,
    specialty: null,
    country: null,
    type: null,
    availability: true,
    score: false,
    doctorId: null,
    doctorFullData: null,
    // userLoad: false,
  });

  const {
    dateOfAppointment,
    doctorName,
    specialty,
    country,
    type,
    availability,
    score,
    doctorId,
    doctorFullData,
    // userLoad,
  } = state; */

  useEffect(() => {
    if (isProfileDoctor) {
      if (doctors.selectedDoctor) {
        getUserData('doctor', doctors.selectedDoctor.id);
      }
    } else {
      if (session) {
        getUserData(session.userType, session.id); // TODO ese null tiene que ser userTypes y cambiar el false de abajo por isDoctor
      }
    }
  }, []);

  //Get the url and information id for the user
  const getUrlAndId = (type, isForDelete) => {
    let dataType = {
      id: 'medication_id',
      url: isForDelete ? 'delete_medication' : 'patient_medication',
    };
    if (type === 'diseases') {
      dataType = {
        id: 'disease_id',
        url: isForDelete ? 'delete_disease' : 'patient_disease',
      };
    } else if (type === 'allergies') {
      dataType = {
        id: 'allergie_id',
        url: isForDelete ? 'delete_allergie' : 'patient_allergie',
      };
    }
    return dataType;
  };

  const handleUserMedication = (isNew, med, isForDelete = false) => {
    const infoToUpdate = 'medications';
    const data = getUrlAndId(infoToUpdate, isForDelete);
    if (!isForDelete) {
      if (isNew) {
        createInfo(infoToUpdate, { name: med.name }, data);
      } else {
        addUserInfo(infoToUpdate, data, med);
      }
    } else {
      deleteUserInfo(infoToUpdate, data, med.id);
    }
  };

  const handleUserDisease = (isNew, disease, isForDelete = false) => {
    const infoToUpdate = 'diseases';
    const data = getUrlAndId(infoToUpdate, isForDelete);
    if (!isForDelete) {
      if (isNew) {
        createInfo(infoToUpdate, { name: disease.name }, data);
      } else {
        addUserInfo(infoToUpdate, data, disease);
      }
    } else {
      deleteUserInfo(infoToUpdate, data, disease.id);
    }
  };

  const handleUserAllergie = (isNew, allergie, isForDelete = false) => {
    const infoToUpdate = 'allergies';
    const data = getUrlAndId(infoToUpdate, isForDelete);
    if (!isForDelete) {
      if (isNew) {
        createInfo(infoToUpdate, { name: allergie.name }, data);
      } else {
        addUserInfo(infoToUpdate, data, allergie);
      }
    } else {
      deleteUserInfo(infoToUpdate, data, allergie.id);
    }
  };

  const addInformation = {
    handleUserMedication,
    handleUserAllergie,
    handleUserDisease,
  };

  return (
    <>
      {userData.success && (
        <Notification
          type="success"
          message={`${capitalize(t('word.data'))} ${t('word.updated')} ${t(
            'prep.with'
          )} ${t('word.success')}`}
          onCloseCallback={deleteMessages}
        />
      )}
      {userData.error && (
        <Notification
          type="error"
          message={'Ha ocurrido un error al actualizar los datos'}
          onCloseCallback={deleteMessages}
        />
      )}
      {doctors.success && (
        <Notification
          type="success"
          message={`${capitalize(t('word.data'))} ${t('word.updated')} ${t(
            'prep.with'
          )} ${t('word.success')}`}
          onCloseCallback={clearMessage}
        />
      )}
      {doctors.error && (
        <Notification
          type="error"
          message={`${t('sentence.errorNotification3')}`}
          onCloseCallback={clearMessage}
        />
      )}
      <Grid container alignItems="center" justify="center">
        {/*    <FormControl className={classes.formControl}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={
              userLoad
                ? userData.user.doctors.filter((doctor) => {
                    return doctor.is_active;
                  })
                : []
            }
            getOptionLabel={(option) => option.full_name}
            sx={{ width: 300 }}
            value={doctors.selectDoctor}
            onChange={handleChangeDoctorId}
            renderInput={(params) => (
              <TextField {...params} label="Seleccione un doctor" />
            )}
          />
        </FormControl> */}
        {isProfileDoctor || isDoctor ? (
          <DoctorProfile
            isOlderThan18={isOlderThan18}
            validateEmail={validateEmail}
            isValidPassword={isValidPassword}
            user={doctors.selectedDoctor || isDoctor ? userData.user : null}
            countries={userData.countries}
            specialities={userData.specialities}
            classes={classes}
            updateUser={updateUserData}
            createAddress={(body) => {
              manageAdresses('POST', null, body);
            }}
            createAddressByAssistant={(body) => {
              manageAddressDoctor(
                'POST',
                null,
                body,
                doctors.selectedDoctor.id,
                session.userType,
                session.id
              );
            }}
            deleteAddressByAssistant={(addressId) => {
              manageAddressDoctor(
                'DELETE',
                addressId,
                null,
                doctors.selectedDoctor.id,
                session.userType,
                session.id
              );
            }}
            deleteAddress={(addressId) => {
              manageAdresses('DELETE', addressId, null);
            }}
            changeUserPassword={(body) => updateUserPassword(body)}
            updateProfilePic={(body) => updateProfilePic(body)}
            isProfileDoctor={isProfileDoctor}
            session={session}
            doctors={doctors}
            setSelectedDoctor={setSelectedDoctor}
            clearSelectedDoctor={clearSelectedDoctor}
            getUserData={getUserData}
            userData={userData}
            isAssistant={session.userType == 'assistant'}
            clearMessage={clearMessage}
            updateSignature={updateSignature}
            deleteMessages={deleteMessages}
            getDoctorsAssistant={getDoctorsAssistant}
          />
        ) : session.userType == 'patient' ? (
          <PatientProfile
            isOlderThan18={isOlderThan18}
            validateEmail={validateEmail}
            userData={userData}
            session={session}
            updateUser={updateUserData}
            addUserInfo={addInformation}
            updateProfilePic={(body) => updateProfilePic(body)}
            classes={classes}
            changeUserPassword={(body) => updateUserPassword(body)}
            doctors={doctors}
            isProfileDoctor={isProfileDoctor}
            isAssistant={session.userType == 'assistant'}
          />
        ) : (
          <AssistantProfile
            isOlderThan18={isOlderThan18}
            validateEmail={validateEmail}
            isValidPassword={isValidPassword}
            user={userData.user}
            countries={userData.countries}
            specialities={userData.specialities}
            classes={classes}
            updateUser={updateUserData}
            createAddress={(body) => {
              manageAdresses('POST', null, body);
            }}
            deleteAddress={(addressId) => {
              manageAdresses('DELETE', addressId, null);
            }}
            changeUserPassword={(body) => updateUserPassword(body)}
            updateProfilePic={(body) => updateProfilePic(body)}
            isProfileDoctor={isProfileDoctor}
            session={session}
            doctors={doctors}
            isAssistant={session.userType == 'assistant'}
            deleteMessages={deleteMessages}
          />
        )}
      </Grid>
    </>
  );
}

const mapStateToProps = ({ session, user, doctors }) => {
  return {
    session,
    userData: user, // userState
    doctors, // doctorsInfo
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUserData: (type, id) =>
    dispatch({ type: userActionsTypes.GET_USER, payload: { type, id } }),
  updateUserData: (type, id, body, isAssistant, isProfileDoctor) =>
    dispatch({
      type: userActionsTypes.UPDATE_USER,
      payload: { type, id, body, isAssistant, isProfileDoctor },
    }),
  updateUserPassword: (body) =>
    dispatch({
      type: userActionsTypes.UPDATE_PASSWORD,
      payload: { body },
    }),
  updateProfilePic: (body) =>
    dispatch({
      type: userActionsTypes.UPDATE_USER_PIC,
      payload: { body },
    }),
  createInfo: (type, body, data) =>
    dispatch({
      type: userActionsTypes.CREATE_INFORMATION,
      payload: { type, body, data },
    }),
  addUserInfo: (type, data, newValue) =>
    dispatch({
      type: userActionsTypes.ADD_INFO_USER,
      payload: { type, data, newValue },
    }),
  deleteUserInfo: (type, data, valueId) =>
    dispatch({
      type: userActionsTypes.DELETE_INFO_USER,
      payload: { type, data, valueId },
    }),
  deleteMessages: () =>
    dispatch({
      type: userActionsTypes.DELETE_MESSAGES,
    }),
  manageAdresses: (type, id, body) =>
    dispatch({
      type: userActionsTypes.UPDATE_ADDRESS,
      payload: { type, body, id },
    }),
  setSelectedDoctor: (doctorBody) =>
    dispatch({
      type: doctorsActionsTypes.SET_DOCTOR_BODY,
      payload: doctorBody,
    }),
  clearSelectedDoctor: () =>
    dispatch({ type: doctorsActionsTypes.CLEAR_SELECT_DOCTOR }),
  clearMessage: () =>
    dispatch({ type: doctorsActionsTypes.CLEAR_SUCCESS_MESSAGE }),

  updateSignature: (body) =>
    dispatch({
      type: userActionsTypes.UPDATE_USER_SIGNATURE,
      payload: { body },
    }),
  manageAddressDoctor: (type, id, body, doctorId, userType, idUserType) => {
    dispatch({
      type: doctorsActionsTypes.MANEGE_ADDRESS_DOCTOR,
      payload: { type, id, body, userType, idUserType, doctorId },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
