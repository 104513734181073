import React from 'react';
import { TextField, Chip } from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import {useLocation} from 'react-router'
const filter = createFilterOptions();

function AutocompleteInfo({
  optionList,
  label,
  setNewValue,
  addValue,
  valuesSelected,
  deleteValue,
  isProfessional,
  isAssistant,
})
 {

  const location = useLocation()
  const appointment = location.state.appointment
  const isDone = appointment ? appointment.is_done : undefined

  return (
    <Autocomplete
      disabled={isProfessional || isAssistant || isDone}
      freeSolo
      multiple
      disableClearable
      value={valuesSelected}
      options={optionList}
      renderOption={(option) => option.name}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            color="primary"
            label={option.name}
            {...getTagProps({ index })}
            onDelete={() => {
              deleteValue(option);
            }}
          />
        ))
      }
      getOptionLabel={(option) => {
        // Render the option to create a new one
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            name: `Agregar "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      onChange={(_, newValue) => {
        if (newValue && newValue[newValue.length - 1]) {
          const value = newValue[newValue.length - 1];
          if (!value.id) {
            // Create a new value from the patient
            setNewValue({ name: value.inputValue });
          } else {
            let alreadyExist = false;
            // Check if the selected value is not already added
            newValue.findIndex((elem, i) => {
              if (elem.id === value.id && i !== newValue.length - 1) {
                alreadyExist = true;
                return true;
              }
            });
            if (!alreadyExist) {
              addValue(value);
            }
          }
        }
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}

export default AutocompleteInfo;
