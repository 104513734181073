import { call, put, takeEvery } from 'redux-saga/effects';
import { appointmentActions, appointmentActionsTypes } from './actions';
import API from '../../API/endpoints';
import { store } from '../store';
import { userActions } from '../user/actions';

function* getAppointments(action) {
  yield put(appointmentActions.loading(true));
  try {
    const { userType, id, token, isDone, queryParams, queryValues } =
      action.payload;
    let urlParams = `${userType}=${id}&is_done=${isDone}`;
    if (queryParams.length > 0) {
      for (let i = 0; i < queryParams.length; i++) {
        urlParams += `&${queryParams[i]}=${queryValues[i]}`;
      }
    }

    const response = yield call(API.getAppointments, urlParams, token);

    yield put(appointmentActions.successGetAppointment(response.data));
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(appointmentActions.error('Error al traer los datos'));
  }
  yield put(appointmentActions.loading(false));
}

function* getAppointmentsLight(action) {
  yield put(appointmentActions.loading(true));
  try {
    const {
      userType,
      id,
      token,
      isDone,
      queryParams,
      queryValues,
      isCanceled = false,
    } = action.payload;
    let urlParams = `${userType}=${id}&is_cancelled=${isCanceled}${
      isDone ? `&is_done=${isDone}` : ''
    }`;
    if (queryParams.length > 0) {
      for (let i = 0; i < queryParams.length; i++) {
        urlParams += `&${queryParams[i]}=${queryValues[i]}`;
      }
    }

    const response = yield call(API.getAppointmentsLight, urlParams, token);

    yield put(appointmentActions.successGetAppointment(response.data));
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(appointmentActions.error('Error al traer los datos'));
  }
  yield put(appointmentActions.loading(false));
}

function* createAppointment(action) {
  yield put(appointmentActions.loading(true));
  try {
    const {
      scheduleId,
      reason,
      doctor,
      patient,
      token,
      allergiesObjects,
      medicationsObjects,
      diseasesObjects,
    } = action.payload;
    const allergiesIds = allergiesObjects.map((allergie) => allergie.id); // este map se hace para pasar solo el array de  ids y no todos los  objectos
    const medicationsIds = medicationsObjects.map(
      (medication) => medication.id
    );
    const diseasesIds = diseasesObjects.map((disease) => disease.id);
    const body = {
      schedule: scheduleId,
      patient_appointment_reason: reason,
      doctor,
      patient,
      ...(allergiesIds.length && { allergies: allergiesIds }), // si el array de alergias esta vacio entonces no agrega la propiedad allergies al objeto
      ...(medicationsIds.length && { medications: medicationsIds }),
      ...(diseasesIds.length && { diseases: diseasesIds }),
    };

    const response = yield call(API.createAppointmentFull, body, token);

    yield put(
      appointmentActions.successCreateAppointment({
        message: 'Appointment creado con exito',
        data: response.data,
      })
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(appointmentActions.error('Error al crear consulta médica'));
  }
  yield put(appointmentActions.loading(false));
}

function* updateAppointment(action) {
  yield put(appointmentActions.loading(true));
  try {
    const { body, token, isInformation = false } = action.payload;
    const newBody = body;
    if (isInformation) {
      const allergiesIds = body.allergies.map((allergie) => allergie.id); // este map se hace para pasar solo el array de  ids y no todos los  objectos
      const medicationsIds = body.medications.map(
        (medication) => medication.id
      );
      const diseasesIds = body.diseases.map((disease) => disease.id);
      newBody = {
        ...body,
        ...(allergiesIds.length && { allergies: allergiesIds }), // si el array de alergias esta vacio entonces no agrega la propiedad allergies al objeto
        ...(medicationsIds.length && { medications: medicationsIds }),
        ...(diseasesIds.length && { diseases: diseasesIds }),
      };
    }
    const response = yield call(
      API.updateAppointmentFull,
      newBody,
      body.appointmentId,
      token
    );

    yield put(
      appointmentActions.successUpdateAppointment({
        message: 'Appointment actualizado con exito',
        data: response.data,
      })
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(appointmentActions.error('Error al crear disponibilidad'));
  }
  yield put(appointmentActions.loading(false));
}

function* deleteAppointment(action) {
  yield put(appointmentActions.loading(true));
  try {
    const { appointmentId, token } = action.payload;

    yield call(API.deleteAppointmentFull, appointmentId, token);
    yield put(
      appointmentActions.successDeleteAppointment(
        'Appointment eliminado con exito'
      )
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(appointmentActions.error('Error al borrar cita'));
  }
  yield put(appointmentActions.loading(false));
}

function* uploadAppointmentFiles(action) {
  yield put(appointmentActions.loading(true));
  try {
    const { body, token } = action.payload;

    const response = yield call(API.uploadeAppointmenFiles, body, token);

    yield put(appointmentActions.successUploadAppointmentFiles(response.data));
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(userActions.error('Error al subir la foto de perfil'));
  }
  yield put(appointmentActions.loading(false));
}

function* deleteAppointmentFile(action) {
  yield put(appointmentActions.loading(true));
  try {
    const { fileId, token } = action.payload;

    yield call(API.deleteAppointmentFile, fileId, token);
    yield put(
      appointmentActions.successDeleteAppointmentFile(
        'Adjunto eliminado con exito'
      )
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(appointmentActions.error('Error al borrar archivo'));
  }
  yield put(appointmentActions.loading(false));
}

function* getScheduleRequests(action) {
  yield put(appointmentActions.loading(true));
  try {
    const { queryId, token } = action.payload;

    const response = yield call(API.getScheduleRequests, queryId, token);

    yield put(appointmentActions.successGetScheduleRequests(response.data));
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(appointmentActions.error('Error al traer los datos'));
  }
  yield put(appointmentActions.loading(false));
}

function* getSchedules(action) {
  //yield put(appointmentActions.loading(true));
  try {
    const { start, end, queryParams, queryValues, token } = action.payload;

    let urlParams = '';
    if (queryParams.length > 0) {
      for (let i = 0; i < queryParams.length; i++) {
        urlParams += `&${queryParams[i]}=${queryValues[i]}`;
      }
    }

    const response = yield call(
      API.getSchedulesByDate,
      start,
      end,
      urlParams,
      token
    );

    yield put(appointmentActions.successGetSchedules(response.data));
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(
      appointmentActions.error('Error al traer los datos de schedules')
    );
  }
  //yield put(appointmentActions.loading(false));
}

function* createScheduleRequests(action) {
  yield put(appointmentActions.loading(true));
  try {
    const { body, token } = action.payload;

    yield call(API.createScheduleRequests, body, token);

    yield put(
      appointmentActions.successScheduleRequestAction(
        'Solicitud creada con éxito'
      )
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(appointmentActions.error('Error al traer los datos'));
  }
  yield put(appointmentActions.loading(false));
}

function* updateScheduleRequests(action) {
  yield put(appointmentActions.loading(true));
  try {
    const { id, body, queryId, token } = action.payload;

    yield call(API.updateScheduleRequests, id, body, token);
    //const response = yield call(API.getScheduleRequests, queryId, token);

    yield put(
      appointmentActions.successUpdateScheduleRequests(
        'Schedule request actualizada'
      )
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(
      appointmentActions.error('Ocurrió un problema al actualizar la solicitud')
    );
  }
  yield put(appointmentActions.loading(false));
}

function* acceptScheduleRequests(action) {
  yield put(appointmentActions.loading(true));
  try {
    const { body, token } = action.payload;

    const response = yield call(API.acceptScheduleRequest, body, token);

    yield put(appointmentActions.successScheduleRequestAccept(response.data));
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(appointmentActions.error('Error al traer los datos'));
  }
  yield put(appointmentActions.loading(false));
}

function* createSchedule(action) {
  yield put(appointmentActions.loading(true));
  try {
    const { body, isMultiple, token } = action.payload;

    const response = yield call(API.createSchedule, body, isMultiple, token);

    yield put(
      appointmentActions.successCreateSchedule({
        message: 'schedule creado con exito',
        data: response.data,
      })
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(appointmentActions.error('Error al crear disponibilidad'));
  }
  yield put(appointmentActions.loading(false));
}

function* deleteSchedule(action) {
  yield put(appointmentActions.loading(true));
  try {
    const { scheduleId, token, isMultiple } = action.payload;

    yield call(API.deleteSchedule, token, scheduleId, isMultiple);
    yield put(
      appointmentActions.successDeleteSchedule('Schedule eliminado con exito')
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(appointmentActions.error('Error al borrar disponibilidad'));
  }
  yield put(appointmentActions.loading(false));
}

function* createAppointmentLight(action) {
  yield put(appointmentActions.loading(true));
  try {
    const {
      scheduleId,
      reason,
      doctor,
      patient,
      token,
      allergiesObjects,
      medicationsObjects,
      diseasesObjects,
    } = action.payload;
    const allergiesIds = allergiesObjects.map((allergie) => allergie.id); // este map se hace para pasar solo el array de  ids y no todos los  objectos
    const medicationsIds = medicationsObjects.map(
      (medication) => medication.id
    );
    const diseasesIds = diseasesObjects.map((disease) => disease.id);
    const body = {
      schedule: scheduleId,
      patient_appointment_reason: reason,
      doctor,
      patient,
      ...(allergiesIds.length && { allergies: allergiesIds }), // si el array de alergias esta vacio entonces no agrega la propiedad allergies al objeto
      ...(medicationsIds.length && { medications: medicationsIds }),
      ...(diseasesIds.length && { diseases: diseasesIds }),
    };

    const response = yield call(API.createAppointmentLight, body, token);

    yield put(
      appointmentActions.successCreateAppointment({
        message: 'Appointment creado con exito',
        data: response.data,
      })
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(appointmentActions.error('Error al crear consulta médica'));
  }
  yield put(appointmentActions.loading(false));
}

function* updateAppointmentLight(action) {
  yield put(appointmentActions.loading(true));
  try {
    const { id, body, token } = action.payload;

    const response = yield call(API.updateAppointmentLight, id, body, token);

    yield put(
      appointmentActions.successUpdateAppointmentLightOrProficient(
        'Appointment actualizado con exito'
      )
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(
      appointmentActions.error('Ocurrió un problema al actualizar la solicitud')
    );
  }
  yield put(appointmentActions.loading(false));
}

function* createAppointmentProficient(action) {
  yield put(appointmentActions.loading(true));
  try {
    const { appointmentType, body, token } = action.payload;

    const response = yield call(
      API.createAppointmentProficient,
      appointmentType,
      body,
      token
    );

    yield put(
      appointmentActions.successCreateAppointment({
        message: 'Appointment creado con exito',
        data: response.data,
      })
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(appointmentActions.error('Error al crear consulta'));
  }
  yield put(appointmentActions.loading(false));
}

function* getAppointmentsProficient(action) {
  yield put(appointmentActions.loading(true));
  try {
    const {
      userType,
      id,
      token,
      isDone,
      queryParams,
      queryValues,
      isCanceled = false,
    } = action.payload;
    let urlParams = `${userType}=${id}&is_cancelled=${isCanceled}${
      isDone ? `&is_done=${isDone}` : ''
    }`;
    if (queryParams.length > 0) {
      for (let i = 0; i < queryParams.length; i++) {
        urlParams += `&${queryParams[i]}=${queryValues[i]}`;
      }
    }

    const response = yield call(
      API.getAppointmentsProficient,
      urlParams,
      token
    );

    yield put(appointmentActions.successGetAppointment(response.data));
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(appointmentActions.error('Error al traer los datos'));
  }
  yield put(appointmentActions.loading(false));
}

function* getProficientDates(action) {
  //yield put(appointmentActions.loading(true));
  try {
    const { start, end, proficientId, token } = action.payload;
    const response = yield call(
      API.getProficientDate,
      start,
      end,
      proficientId,
      token
    );

    yield put(appointmentActions.successGetProficientDates(response.data));
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(
      appointmentActions.error('Error al traer los datos de schedules')
    );
  }
  //yield put(appointmentActions.loading(false));
}

function* updateAppointmentProficient(action) {
  yield put(appointmentActions.loading(true));
  try {
    const { id, body, token } = action.payload;

    const response = yield call(
      API.updateAppointmentProficient,
      id,
      body,
      token
    );

    yield put(
      appointmentActions.successUpdateAppointmentLightOrProficient(
        'Appointment actualizado con exito'
      )
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(
      appointmentActions.error('Ocurrió un problema al actualizar la solicitud')
    );
  }
  yield put(appointmentActions.loading(false));
}

function* resendCode(action) {
  yield put(appointmentActions.loading(true));
  try {
    const { id, token } = action.payload;

    const response = yield call(API.resendCode, id, token);

    yield put(
      appointmentActions.successResendCode('Datos reenviados con exito')
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(
      appointmentActions.error('Ocurrió un problema al operar la solicitud')
    );
  }
  yield put(appointmentActions.loading(false));
}

export function refreshToken(saga) {
  return function* (action) {
    //yield put(appointmentActions.loading(true));
    const actionToken = { payload: { ...action.payload, token: true } };
    try {
      yield* saga(actionToken);
    } catch (error) {
      if (
        error.response.status === 401 &&
        error.response.data.code === 'token_not_valid'
      ) {
        const reduxStore = store;
        try {
          //Try refresh token
          const token = yield call(API.refreshToken, {
            refresh: reduxStore.getState().session.refreshToken,
          });
          yield put(
            userActions.successTokenRefresh({
              message: 'Nuevo token obtenido con éxito',
              token: token.data,
            })
          );
          yield* saga(actionToken);
        } catch (error) {
          if (
            error.response.status === 401 &&
            error.response.data.code === 'token_not_valid'
          ) {
            yield put(userActions.logoutUser()); //Refresh token expired
          }
        }
      }
    }
    //yield put(appointmentActions.loading(false));
  };
}
function* getAppointmentsAll(action) {
  yield put(appointmentActions.loading(true));
  try {
    const {
      userType,
      id,
      token,
      isDone,
      queryParams,
      queryValues,
      isCanceled = false,
      appointmentId,
    } = action.payload;
    // if we dont have an appointment id, then we process the queryparams for retrieving a list of appointments.
    let urlParams = `${userType}=${id}&is_done=${isDone}&is_cancelled=${isCanceled}`;
    if (!appointmentId) {
      if (queryParams.length > 0) {
        for (let i = 0; i < queryParams.length; i++) {
          urlParams += `&${queryParams[i]}=${queryValues[i]}`;
        }
      }
    }

    const response = yield call(
      API.getAppointmentsAll,
      urlParams,
      token,
      appointmentId
    );

    yield put(appointmentActions.successGetAppointment(response.data));
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(appointmentActions.error('Error al traer los datos'));
  }
  yield put(appointmentActions.loading(false));
}

export default function* appointmentSaga() {
  yield takeEvery(
    appointmentActionsTypes.GET_APPOINTMENTS,
    refreshToken(getAppointments)
  );
  yield takeEvery(
    appointmentActionsTypes.GET_SCHEDULE_REQUESTS,
    refreshToken(getScheduleRequests)
  );
  yield takeEvery(
    appointmentActionsTypes.CREATE_SCHEDULE_REQUESTS,
    refreshToken(createScheduleRequests)
  );
  yield takeEvery(
    appointmentActionsTypes.UPDATE_SCHEDULE_REQUESTS,
    refreshToken(updateScheduleRequests)
  );
  yield takeEvery(
    appointmentActionsTypes.ACCEPT_SCHEDULE_REQUESTS,
    refreshToken(acceptScheduleRequests)
  );
  yield takeEvery(
    appointmentActionsTypes.CREATE_SCHEDULE,
    refreshToken(createSchedule)
  );

  yield takeEvery(
    appointmentActionsTypes.DELETE_SCHEDULE,
    refreshToken(deleteSchedule)
  );

  yield takeEvery(
    appointmentActionsTypes.GET_SCHEDULES_BY_DATE,
    refreshToken(getSchedules)
  );

  yield takeEvery(
    appointmentActionsTypes.CREATE_APPOINTMENT,
    refreshToken(createAppointment)
  );

  yield takeEvery(
    appointmentActionsTypes.UPDATE_APPOINTMENT,
    refreshToken(updateAppointment)
  );
  yield takeEvery(
    appointmentActionsTypes.UPLOAD_APPOINTMENT_FILES,
    refreshToken(uploadAppointmentFiles)
  );
  yield takeEvery(
    appointmentActionsTypes.DELETE_APPOINTMENT,
    refreshToken(deleteAppointment)
  );
  yield takeEvery(
    appointmentActionsTypes.DELETE_APPOINTMENT_FILE,
    refreshToken(deleteAppointmentFile)
  );
  yield takeEvery(
    appointmentActionsTypes.GET_APPOINTMENTS_LIGHT,
    refreshToken(getAppointmentsLight)
  );
  yield takeEvery(
    appointmentActionsTypes.CREATE_APPOINTMENT_LIGHT,
    refreshToken(createAppointmentLight)
  );
  yield takeEvery(
    appointmentActionsTypes.UPDATE_APPOINTMENT_LIGHT,
    refreshToken(updateAppointmentLight)
  );
  yield takeEvery(
    appointmentActionsTypes.CREATE_APPOINTMENT_PROFICIENT,
    createAppointmentProficient
  );
  yield takeEvery(
    appointmentActionsTypes.GET_APPOINTMENTS_PROFICIENT,
    refreshToken(getAppointmentsProficient)
  );
  yield takeEvery(
    appointmentActionsTypes.GET_PROFICIENT_DATES,
    refreshToken(getProficientDates)
  );
  yield takeEvery(
    appointmentActionsTypes.UPDATE_APPOINTMENT_PROFICIENT,
    refreshToken(updateAppointmentProficient)
  );
  yield takeEvery(
    appointmentActionsTypes.RESEND_CODE,
    refreshToken(resendCode)
  );
  yield takeEvery(
    appointmentActionsTypes.GET_APPOINTMENTS_ALL,
    refreshToken(getAppointmentsAll)
  );
}
