import React from 'react';
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './style';
import {useTranslation} from 'react-i18next'

function References() {
  const classes = useStyles();
  const theme = useTheme();
  const [t, i18n] = useTranslation('global')
  return (
    <Box className={classes.availabilityContainer}>
      <Box className={classes.availabilityItem}>
        <div
          style={{
            backgroundColor: '#fde9cc',
            border: '2px solid #fa9c17',
            ...theme.circle,
          }}
        />
        <span className={classes.typeAvailability}>Consultas agendadas</span>
      </Box>
      <Box className={classes.availabilityItem}>
        <div
          style={{
            backgroundColor: '#d4d6fd',
            border: '2px solid #344cf8',
            ...theme.circle,
          }}
        />
        <span className={classes.typeAvailability}>{t('Calendar.available-online')}</span>
      </Box>
      <Box className={classes.availabilityItem}>
        <div
          style={{
            backgroundColor: '#d2effd',
            border: '2px solid #33aff9',
            ...theme.circle,
          }}
        />
        <span className={classes.typeAvailability}>{t('Calendar.available')}</span>
      </Box>
      <Box className={classes.availabilityItem}>
        <div
          style={{
            backgroundColor: '#d7f1da',
            border: '2px solid #49c65d',
            ...theme.circle,
          }}
        />
        <span className={classes.typeAvailability}>{t('Calendar.consult')}</span>
      </Box>
    </Box>
  );
}

export default References;
