import React from 'react';
import 'moment/locale/es';
import moment from 'moment';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { store, persistor } from './redux/store';
import { ThemeCore } from 'frontend_doctari_core';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import global_por from './translations/por/global.json';
import global_es from './translations/es/global.json';

let language = localStorage.getItem('language') || 'es';
localStorage.setItem('language', language);

i18next.init({
  interpolation: { escapeValue: false },
  lng: language,
  resources: {
    es: {
      global: global_es,
    },
    por: {
      global: global_por,
    },
  },
});

const theme = createTheme({
  ...ThemeCore,
  circle: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
  },
});
moment.locale(language);

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <BrowserRouter>
          <PersistGate persistor={persistor}>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </PersistGate>
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
