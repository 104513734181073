import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import List from './List';
import { userActionsTypes } from '../../redux/user/actions';
import { doctorsActionsTypes } from '../../redux/doctor/actions';
import { useLocation } from 'react-router-dom';
import {
  Paper,
  Container,
  Input,
  FormControl,
  InputLabel,
  Grid,
} from '@material-ui/core';
import Notification from '../../components/Notification';
import { useStyles } from './style';
import DoctariSpinner from '../../components/Spinner';
import Pagination from '@material-ui/lab/Pagination';
import { useTranslation } from 'react-i18next';

function UserList({
  userData,
  getPatients,
  doctorsInfo,
  getDoctorsInfo,
  updateUserData,
  updateUserPassword,
  getUserData,
  session,
  deleteMessages,
}) {
  const location = useLocation();
  const isListDoctor = location.state.list === 'doctors';
  const [t, i18n] = useTranslation('global');

  /* --------------pagination_user---------------------- */
  const [state, setState] = useState({
    currentPage: 1,
    full_name: '',
  });

  const { numOfPages } = userData;
  const { currentPage, full_name } = state;
  /* ------------------------------------ */

  const [doctors, setDoctors] = useState([]);

  const searchUsers = () => {
    const queryParams = [];
    const queryValues = [];

    if (currentPage > 1) {
      queryParams.push('page');
      queryValues.push(currentPage);
    }

    if (full_name) {
      queryParams.push('full_name__icontains');
      queryValues.push(full_name);
    }

    if (!isListDoctor) {
      queryParams.push('is_active');
      queryValues.push(true);
      getPatients(queryParams, queryValues);
    }
  };

  useEffect(() => {
    if (isListDoctor) {
      getUserData(session.userType, session.id);
    }
  }, []);

  useEffect(() => {
    if (currentPage || full_name) {
      searchUsers();
    }
  }, [currentPage, full_name]);

  useEffect(() => {
    if (userData.user && isListDoctor) {
      setDoctors(
        userData.user.doctors.filter((doctor) => {
          return doctor.full_name
            .toLowerCase()
            .includes(full_name.toLowerCase());
        })
      );
    }
  }, [full_name]);

  useEffect(() => {
    if (userData.user) {
      setDoctors(userData.user.doctors);
    }
  }, [userData.user, userData.patients]);

  useEffect(() => {
    if (userData.messageActiveDoctor === 'Active actualizado con exito') {
      getUserData(session.userType, session.id);
      deleteMessages();
    }
  }, [userData.messageActiveDoctor]);

  const classes = useStyles();

  return (
    <>
      {userData.success && (
        <Notification
          type="success"
          message={'Contraseña modificada con éxito'}
          onCloseCallback={deleteMessages}
        />
      )}
      {isListDoctor ? (
        <Container>
          <div>
            <div className={classes.titleSearchBarContainer}>
              <h1 className={classes.title}>{t('UserList.doctors-list')}</h1>
              <FormControl>
                <InputLabel htmlFor="my-input-doctorName">
                  {t('UserList.search-doctor')}
                </InputLabel>
                <Input
                  id="my-input-doctorName"
                  label="Buscar Doctor"
                  variant="standard"
                  autoComplete="off"
                  onChange={(e) => {
                    setState({
                      ...state,
                      full_name: e.target.value,
                      currentPage: 1,
                    });
                  }}
                  value={full_name}
                />
              </FormControl>
            </div>
            {doctors.length > 0 ? (
              doctors.map((doctorDetail) => {
                return (
                  <Paper className={classes.MuiPaper}>
                    <List
                      updateUserData={updateUserData}
                      userId={doctorDetail.id}
                      isActive={doctorDetail.is_active}
                      isListDoctor={isListDoctor}
                      fullName={doctorDetail.full_name}
                      identityDocument={doctorDetail.identity_document}
                      email={doctorDetail.email}
                      profilePic={
                        doctorDetail.profile_picture
                          ? doctorDetail.profile_picture.url
                          : null
                      }
                      updateUserPassword={updateUserPassword}
                      loading={userData.loading}
                      success={userData.success}
                    />
                  </Paper>
                );
              })
            ) : (
              <Grid container justifyContent="center">
                <Grid item>
                  <h3>{t('UserList.no-doctors')}</h3>
                </Grid>
              </Grid>
            )}
          </div>
        </Container>
      ) : (
        <>
          {userData.success && (
            <Notification
              type="success"
              message={`${t('word.password')} ${t('word.updated')} ${t(
                'prep.with'
              )} ${t('word.success')}`}
              onCloseCallback={deleteMessages}
            />
          )}
          <Container>
            <div>
              <div className={classes.titleSearchBarContainer}>
                <h1 className={classes.title}>Lista de usuario</h1>
                <FormControl>
                  <InputLabel htmlFor="my-input-userName">
                    Buscar Usuario
                  </InputLabel>
                  <Input
                    id="my-input-userName"
                    label="Buscar Usuario"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) => {
                      setState({
                        ...state,
                        full_name: e.target.value,
                        currentPage: 1,
                      });
                    }}
                    value={full_name}
                  />
                </FormControl>
              </div>
              {userData.loading ? (
                <DoctariSpinner />
              ) : userData.patients.length > 0 ? (
                userData.patients.map((patient) => {
                  return (
                    <Paper className={classes.MuiPaper}>
                      <List
                        isListDoctor={isListDoctor}
                        updateUserPassword={updateUserPassword}
                        fullName={patient.full_name}
                        userId={patient.id}
                        success={userData.success}
                        loading={userData.loading}
                        identityDocument={patient.identity_document}
                        email={patient.email}
                        profilePic={
                          patient.profile_picture
                            ? patient.profile_picture.url
                            : null
                        }
                      />
                    </Paper>
                  );
                })
              ) : (
                <Grid container justifyContent="center">
                  <Grid item>
                    <h3>No se encuentran pacientes </h3>
                  </Grid>
                </Grid>
              )}
            </div>

            <Pagination
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '2rem',
                marginBottom: '2rem',
              }}
              count={numOfPages}
              onChange={(_, page) => {
                if (currentPage !== page) {
                  setState({ ...state, currentPage: page });
                }
              }}
              variant="outlined"
              color="primary"
            />
          </Container>
        </>
      )}
    </>
  );
}

const mapStateToProps = ({ session, user, doctors }) => {
  return {
    session,
    userData: user,
    doctorInfo: doctors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDoctorsInfo: (queryParams, queryValues) =>
    dispatch({
      type: doctorsActionsTypes.GET_DOCTORS,
      payload: { queryParams, queryValues },
    }),
  getPatients: (queryParams, queryValues) =>
    dispatch({
      type: userActionsTypes.GET_PATIENTS,
      payload: { queryParams, queryValues },
    }),
  getUserData: (type, id) =>
    dispatch({ type: userActionsTypes.GET_USER, payload: { type, id } }),
  updateUserData: (type, id, body, isAssistant) =>
    dispatch({
      type: userActionsTypes.UPDATE_USER,
      payload: { type, id, body, isAssistant },
    }),
  updateUserPassword: (body) =>
    dispatch({
      type: userActionsTypes.UPDATE_PASSWORD,
      payload: { body },
    }),
  updateProfilePic: (body) =>
    dispatch({
      type: userActionsTypes.UPDATE_USER_PIC,
      payload: { body },
    }),
  createInfo: (type, body, data) =>
    dispatch({
      type: userActionsTypes.CREATE_INFORMATION,
      payload: { type, body, data },
    }),
  addUserInfo: (type, data, newValue) =>
    dispatch({
      type: userActionsTypes.ADD_INFO_USER,
      payload: { type, data, newValue },
    }),
  deleteUserInfo: (type, data, valueId) =>
    dispatch({
      type: userActionsTypes.DELETE_INFO_USER,
      payload: { type, data, valueId },
    }),
  deleteMessages: () =>
    dispatch({
      type: userActionsTypes.DELETE_MESSAGES,
    }),
  manageAdresses: (type, id, body) =>
    dispatch({
      type: userActionsTypes.UPDATE_ADDRESS,
      payload: { type, body, id },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
