import { useEffect, useState } from 'react';
import { Box, Button, Container, Avatar, Grid } from '@material-ui/core';
import { useStyles } from './style';
import PasswordInput from '../../components/PasswordInput';
import DoctariModal from '../../components/Modal';
import { isValidPassword } from '../../utils/validators';
import { useTranslation } from 'react-i18next';

const List = ({
  isListDoctor,
  fullName,
  identityDocument,
  email,
  profilePic,
  userId,
  updateUserData,
  isActive,
  updateUserPassword,
  success,
  loading,
}) => {
  const classes = useStyles();
  const [t, i18n] = useTranslation('global');

  const [isDoctorActive, setIsDoctorActive] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const ChangePassword = () => {
    // TODO make this component generic for assistant doctor and patient and import them
    const [state, setState] = useState({
      prevPassword: null,
      newPassword: null,
      newPassword2: null,
    });
    const { prevPassword, newPassword, newPassword2 } = state;

    const isDisabled = () => {
      return !(
        newPassword &&
        isValidPassword(newPassword) &&
        newPassword === newPassword2
      );
    };
    return (
      <form
        onSubmit={() =>
          updateUserPassword({
            user_to_modify: userId,
            new_password: newPassword,
          })
        }
      >
        <Grid
          container
          xs={12}
          md={12}
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            marginTop: '1rem',
          }}
        >
          <Grid
            item
            justifyContent="center"
            style={{ padding: '1rem 2rem ', width: '100%' }}
          >
            <PasswordInput
              classes={classes}
              changePassword={(newPass) =>
                setState({ ...state, newPassword: newPass })
              }
              password={newPassword}
              isValidPassword={isValidPassword}
              labelText={t('UserList.new-pass')}
            />
          </Grid>
          <Grid
            item
            justifyContent="center"
            style={{ padding: '1rem 2rem', width: '100%' }}
          >
            <PasswordInput
              classes={classes}
              changePassword={(newPass) =>
                setState({ ...state, newPassword2: newPass })
              }
              password={newPassword2}
              diffPassword={!newPassword || newPassword2 !== newPassword}
              isSecondPassword
              labelText={t('UserList.confirm-new-pass')}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '2rem 0rem',
              width: '100%',
            }}
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={isDisabled()}
            >
              {loading ? t('UserList.loading') : t('UserList.change-pass')}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  };

  const handleActive = () => {
    updateUserData('doctor', userId, { is_active: !isActive }, true);
    setIsDoctorActive((current) => !current);
  };

  useEffect(() => {
    if (success) {
      setModalOpen(false);
    }
  }, [success]);

  return (
    // change password modal
    <>
      <DoctariModal
        open={modalOpen}
        setOpen={(val) => setModalOpen(val)}
        title={t('UserList.change-pass')}
      >
        <ChangePassword></ChangePassword>

        {/* <Button
          style={{ float: 'right', margin: '10px' }}
          variant="contained"
          color="primary"
          onClick={() => 'test'}
        >
          Aceptar
        </Button>
        <Button
          style={{ float: 'right', margin: '10px' }}
          color="red"
          onClick={() => setModalOpen(false)}
          variant="outlined"
        >
          Cancelar
        </Button> */}
      </DoctariModal>

      <Container>
        <Box
          display="inline-flex"
          alignItems="center"
          style={{ opacity: !isActive && isListDoctor ? '0.5' : '' }}
        >
          <Avatar
            className={classes.avatar}
            src={profilePic}
            alt="profile picture"
          />
          <div className={classes.info}>
            <p>
              {fullName} <br />
              {identityDocument} <br />
              <span className={classes.grey}>{email}</span>
            </p>
          </div>
        </Box>
        {isListDoctor && (
          <div className={classes.linkButtonContainer}>
            <Button
              variant="contained"
              style={{
                backgroundColor: isActive ? 'red' : 'white',
                color: isActive ? 'white' : '#067fcf',
                borderRadius: '0',
                marginLeft: '10px',
              }}
              onClick={handleActive}
            >
              {!isActive ? t('UserList.enable') : t('UserList.disable')}
            </Button>
          </div>
        )}
        <div className={classes.linkButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            style={{
              opacity: !isActive && isListDoctor ? '0.5' : '',
              pointerEvents: !isActive && isListDoctor ? 'none' : '',
            }}
            className={classes.userButton}
            onClick={() => setModalOpen(true)}
          >
            {t('UserList.change-pass')}
          </Button>
        </div>
      </Container>
    </>
  );
};

export default List;
