import React, { useState } from 'react';
import {
  Grid,
  Button,
  Paper,
  Box,
  Link,
} from '@material-ui/core';
import { isValidPassword } from '../../utils/validators';
import PasswordInput from '../../components/PasswordInput';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/helpers';

function ResetPassword({ goLogin, classes, resetPassword, resetToken }) {
  const [t, i18n] = useTranslation('global');

  const ChangePassword = () => {
    let loading = false;
    // TODO make this component generic for assistant doctor and patient and import them
    const [state, setState] = useState({
      prevPassword: null,
      newPassword: null,
      newPassword2: null,
    });
    const { prevPassword, newPassword, newPassword2 } = state;

    const isDisabled = () => {
      return !(
        newPassword &&
        isValidPassword(newPassword) &&
        newPassword === newPassword2
      );
    };
    return (
      <form
        onSubmit={() =>
          resetPassword({
            token: resetToken,
            password: newPassword,
          })
        }
      >
        <Grid
          container
          xs={12}
          md={12}
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            marginTop: '1rem',
          }}
        >
          <Grid
            item
            justifyContent="center"
            style={{ padding: '1rem 2rem ', width: '100%' }}
          >
            <PasswordInput
              classes={classes}
              changePassword={(newPass) =>
                setState({ ...state, newPassword: newPass })
              }
              password={newPassword}
              isValidPassword={isValidPassword}
              labelText={`${t('AccountData.new-pass')}`}
            />
          </Grid>
          <Grid
            item
            justifyContent="center"
            style={{ padding: '1rem 2rem', width: '100%' }}
          >
            <PasswordInput
              classes={classes}
              changePassword={(newPass) =>
                setState({ ...state, newPassword2: newPass })
              }
              password={newPassword2}
              diffPassword={!newPassword || newPassword2 !== newPassword}
              isSecondPassword
              labelText={`${t('AccountData.confirm-new-pass')}`}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '2rem 0rem',
              width: '100%',
            }}
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={isDisabled()}
            >
              {loading
                ? `${capitalize(t('word.loading'))}`
                : `${capitalize(t('AccountData.change-pass'))}`}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  };

  return (
    <Grid
      container
      alignItems="center"
      style={{ minHeight: '100vh', backgroundColor: '#f1f1f1' }}
      justify="center"
    >
      <Grid item xs={11} md={3}>
        <Paper elevation={3}>
          <Box p={'13%'} display="flex" flexDirection="column">
            <Box className={classes.H5}>{`Resetear ${t('word.password')}`}</Box>
            <ChangePassword></ChangePassword>
          </Box>
        </Paper>
        <Box className={classes.helpText}>
          <Link style={{ cursor: 'pointer' }} onClick={goLogin}>
            {`${t('sentence.resetPassword')}`}
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ResetPassword;
