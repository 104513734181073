import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import { Button, Box, Paper, Grid, TextField } from '@material-ui/core';
import DoctorInfo from './DoctorInfo';
import DoctariModal from '../Modal';
import { APPOINTMENT, DOCTOR } from '../../utils/urls';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    fontFamily: theme.typography.fontFamily,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.5rem',
    marginTop: '1rem',
    '@media (max-width: 900px)': {
      alignItems: 'start',
      flexDirection: 'column',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexDirection: 'column',
    '@media (max-width: 900px)': {
      alignItems: 'start',
      width: '100%',
      marginTop: '1rem',
    },
  },
  name: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '15px',
    color: theme.palette.primary.main,
  },
  description: {
    color: 'grey',
    marginTop: '5px',
    fontWeight: 300,
    fontSize: '14px',
  },
  link: {
    fontStyle: 'italic',
    textDecoration: 'underline',
    color: theme.palette.lightPrimary.main,
    marginTop: '5px',
    marginBottom: '5px',
    cursor: 'pointer',
  },
}));

function DoctorCard({ doctor, createScheduleRequests }) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [t, i18n] = useTranslation('global');

  const [state, setState] = useState({
    modalOpen: false,
  });
  const { modalOpen } = state;
  function ScheduleRequest({ closeModal }) {
    const [input, setInput] = useState('');
    const handleInputChange = (e) => {
      setInput(e.target.value);
    };

    return (
      <Box
        style={{
          padding: '2rem',
          overflow: 'auto',
        }}
      >
        <Box>
          {t('DoctorCard.confirm')} {doctor.full_name} ?
        </Box>
        <Box marginTop="1rem">{t('DoctorCard.request-info')}</Box>
        <TextField
          id="standard-basic"
          label={t('DoctorCard.appointment-reason')}
          variant="standard"
          autoComplete="off"
          style={{ width: '100%', marginTop: '3px', marginBottom: '3px' }}
          onChange={handleInputChange}
        />
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={closeModal}
            style={{ marginRight: '1rem' }}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              createScheduleRequests(input);
              closeModal();
            }}
          >
            Enviar
          </Button>
        </Grid>
      </Box>
    );
  }

  return (
    <>
      <DoctariModal
        open={modalOpen}
        setOpen={(val) => setState({ ...state, modalOpen: val })}
        title={t('DoctorCard.send-request')}
        hideCross
      >
        <ScheduleRequest
          closeModal={() => setState({ ...state, modalOpen: false })}
        />
      </DoctariModal>
      <Paper className={classes.paperContainer}>
        <DoctorInfo doctor={doctor} />
        {doctor.next_available_hour ? (
          <Box className={classes.container}>
            <Box
              style={{
                display: 'flex',
                width: '13rem',
                justifyContent: 'space-between',
              }}
            >
              <span className={classes.description}>
                {t('DoctorCard.next-available-hour')}
              </span>
              <div
                style={{
                  backgroundColor: '#d2effd',
                  border: '2px solid #33aff9',
                  ...theme.circle,
                }}
              />
              {doctor.next_available_hour.face_to_face && (
                <div
                  style={{
                    backgroundColor: '#d4d6fd',
                    border: '2px solid #344cf8',
                    ...theme.circle,
                  }}
                />
              )}
            </Box>
            <span
              className={classes.description}
              style={{ fontWeight: 'bold', color: 'black', width: '13rem' }}
            >
              {moment(doctor.next_available_hour.start)
                .tz(moment.tz.guess())
                .format('DD MMMM h:mm a')}
            </span>
            <span
              className={classes.link}
              onClick={() =>
                history.push({
                  pathname: DOCTOR,
                  state: { doctor },
                })
              }
            >
              {t('DoctorCard.agenda-options')}
            </span>

            <Button
              variant="contained"
              color="primary"
              style={{ alignSelf: 'end' }}
              onClick={() =>
                history.push({
                  pathname: APPOINTMENT,
                  state: { schedule: doctor.next_available_hour },
                })
              }
            >
              AGENDAR
            </Button>
          </Box>
        ) : (
          <Box className={classes.container}>
            <span
              className={classes.link}
              onClick={() =>
                history.push({
                  pathname: DOCTOR,
                  state: { doctor },
                })
              }
            >
              Ver perfil
            </span>
            <span
              className={classes.link}
              onClick={() => setState({ ...state, modalOpen: true })}
            >
              {t('DoctorCard.appointment-request')}
            </span>
          </Box>
        )}
      </Paper>
    </>
  );
}

export default DoctorCard;
