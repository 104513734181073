import React, { useState, useRef } from 'react';
import {
  IconButton,
  MenuItem,
  Grow,
  MenuList,
  Popper,
  Paper,
  ClickAwayListener,
  Avatar,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import {useTranslation} from 'react-i18next'

const AvatarMenu = ({
  openModal,
  logout,
  classes,
  userName,
  profilePic,
  goProfile,
}) => {
  const menuOpen = useRef(null);
  const [open, openMenu] = useState(null);
  const history = useHistory();
  const [t, i18n] = useTranslation('global')

  return (
    <div
      className={classes.avatarContainer}
      onClick={() => openMenu(menuOpen.current)}
    >
      <Avatar alt='Remy Sharp' src={profilePic} className={classes.avatar} />
      <span>{userName}</span>
      <IconButton
        style={{ color: 'white' }}
        aria-controls='simple-menu'
        aria-haspopup='true'
        ref={menuOpen}
      >
        <ArrowDropDown fontSize='inherit' />
      </IconButton>
      <Popper
        open={open}
        anchorEl={menuOpen.current}
        transition
        disablePortal
        style={{ marginTop: '0.8rem' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => openMenu(null)}>
                <MenuList id='menu-list-grow'>
                  <MenuItem
                    style={{
                      height: '3rem',
                      width: '10rem',
                    }}
                    onClick={() => {
                      goProfile();
                    }}
                  >
                  {t('header.profile')}
                  </MenuItem>
                  <div
                    style={{ width: '100%', border: 'solid 1px #8080803b' }}
                  />

                  <MenuItem
                    style={{ height: '3rem' }}
                    onClick={() => {
                      logout();
                      openModal(null);
                    }}
                  >
                    {t('header.exit')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export { AvatarMenu };
