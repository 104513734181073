import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Backdrop,
  Fade,
  Grid,
  Box,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: theme.typography.fontFamily,
  },
  paper: {
    maxHeight: '80%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    overflowY: 'scroll',
    boxShadow: theme.shadows[5],
  },
  modalTitle: {
    fontSize: '125%',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '64px',
    background: theme.palette.primary.main,
  },
}));

function DoctariModal({ children, open, setOpen, title, hideCross }) {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableBackdropClick={hideCross}
      >
        <Fade in={open}>
          <Grid item xs={11} md={4} className={classes.paper}>
            <Box className={classes.modalTitle}>
              <span style={{ marginLeft: '2rem' }}>{title}</span>
              {!hideCross && (
                <IconButton
                  style={{ color: 'white' }}
                  aria-label='upload picture'
                  component='span'
                  onClick={() => setOpen(false)}
                >
                  <Close />
                </IconButton>
              )}
            </Box>
            {children}
          </Grid>
        </Fade>
      </Modal>
    </>
  );
}

export default DoctariModal;
