import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  generalContainer: {
    margin: '0 auto',
    width: '80%',
    '@media screen and (max-width: 1024px)': {
      width: '95%',
    },
    '@media screen and (max-width:768px)': {
      width: '97%',
    },
    '@media screen and (max-width: 426px)': {
      width: '95%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  formContainer: {
    fontFamily: theme.typography.fontFamily,
    display: 'flex',
    flexDirection: 'column',
    marginRight: '25px',
    width: '25%',
    float: 'left',
    '@media (max-width: 900px)': {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'initial',
      alignItems: 'flex-start',
      pading: '1.5rem',
    },
    '@media screen and (max-width:768px)': {
      width: 'fit-content',
      marginBottom: '1rem',
      paddingLeft: '0',
    },
  },
  search: {
    borderRadius: '4px',
    margin: '36px 0 45px 0',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);',
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
      outline: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
      outline: 'none',
    },
  },
  formControl: {
    fontFamily: theme.typography.fontFamily,
    marginBottom: '1rem',
    width: '100%',
    /*  '@media screen and (max-width: 900px)': {
      width: '100%',
    },
    '@media screen and (max-width:768px) ': {
      width:'100%'
    } */
  },

  gridItem: {
    '@media screen and (max-width:768px) ': {
      width: '100%',
    },
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '3rem',
    '@media screen and (max-width: 426px)': {
      display: 'block',
      width: '100%',
    },
  },
  buttonContainer: {
    display: 'flex',
    '@media screen and (max-width: 768px)': {
      width: '100%',
    },
    /* 'button:nth-first-child' : {
      '@media screen and (max-width: 320px)': {
        marginRight:'0'
      }
    } */
  },
  widthFieldModal: {
    width: '100%',
  },
}));
