import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  agendaContainer: {
    fontFamily: theme.typography.fontFamily,
  },
  formControl: {
    fontFamily: theme.typography.fontFamily,
    marginBottom: '1rem',
    width: '48%',
    '@media (max-width: 900px)': {
      width: '100%',
    },
  },
  monthContainer: {
    width: '959px',
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 900px)': {
      justifyContent: 'center',
    },
  },
  monthItem: {
    display: 'flex',
    justifyContent: 'center',
    width: '135px',
    height: '110px',
    marginTop: '0.5rem',
    border: '1px solid #00000033',
    borderRadius: '15px',
    '@media (max-width: 900px)': {
      width: '50px',
      height: '50px',
    },
  },
  dayText: {
    fontWeight: 400,
    fontSize: '21px',
    '@media (max-width: 900px)': {
      fontSize: '12px',
    },
  },
  changeView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    fontSize: '22px',
  },
  radioGroupContainer: {
    width: '65%',
    margin: '0 auto',
    '@media screen and (max-width:426px)': {
      width: '100%',
    },
    '@media screen and (min-width:768px)': {
      justifyContent: 'center',
      width: '100%',
    },
    '@media screen and (min-width: 1024px)': {
      width: '100%',
      justifyContent: 'normal',
    },
  },
  selectProfessionalFormControl: {
    margin: '2rem 0 1rem 22.5rem',
    width: '25%',

    '@media (max-width: 1023px)': {
      width: '80%',
      margin: '1rem ',
    },
    '@media (max-width: 1407px)': {
      width: '30%',
      margin: '2rem 0 1rem 3rem',
    },
  },
}));
