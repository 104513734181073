export const appointmentActionsTypes = {
  GET_APPOINTMENTS: 'GET_APPOINTMENTS',
  GET_APPOINTMENTS_LIGHT: 'GET_APPOINTMENTS_LIGHT',
  GET_APPOINTMENTS_PROFICIENT: 'GET_APPOINTMENTS_PROFICIENT',
  CREATE_APPOINTMENT: 'CREATE_APPOINTMENT',
  UPDATE_APPOINTMENT: 'UPDATE_APPOINTMENT',
  DELETE_APPOINTMENT: 'DELETE_APPOINTMENT',
  UPLOAD_APPOINTMENT_FILES: 'UPLOAD_APPOINTMENT_FILES',
  DELETE_APPOINTMENT_FILE: 'DELETE_APPOINTMENT_FILE',
  GET_SCHEDULE_REQUESTS: 'GET_SCHEDULE_REQUESTS',
  GET_SCHEDULES_BY_DATE: 'GET_SCHEDULE_BY_DATE',
  CREATE_SCHEDULE: 'CREATE_SCHEDULE',
  DELETE_SCHEDULE: 'DELETE_SCHEDULE',
  CREATE_SCHEDULE_REQUESTS: 'CREATE_SCHEDULE_REQUESTS',
  UPDATE_SCHEDULE_REQUESTS: 'UPDATE_SCHEDULE_REQUESTS',
  ACCEPT_SCHEDULE_REQUESTS: 'ACCEPT_SCHEDULE_REQUESTS',
  SUCCESS_UPDATE_SCHEDULE_REQUESTS: 'SUCCESS_UPDATE_SCHEDULE_REQUESTS',
  SUCCESS_GET_SCHEDULES: 'SUCCESS_GET_SCHEDULES',
  SUCCESS_DELETE_SCHEDULE: 'SUCCESS_DELETE_SCHEDULE',
  SUCCESS_GET_SCHEDULE_REQUESTS: 'SUCCESS_GET_SCHEDULE_REQUESTS',
  SUCCESS_SCHEDULE_REQUESTS_ACTION: 'SUCCESS_SCHEDULE_REQUESTS_ACTION',
  SUCCESS_ACCEPT_SCHEDULE_REQUESTS: 'SUCCESS_ACCEPT_SCHEDULE_REQUESTS',
  SUCCESS_GET_APPOINTMENTS: 'SUCCESS_GET_APPOINTMENTS',
  SUCCESS_CREATE_APPOINTMENT: 'SUCCESS_CREATE_APPOINTMENT',
  SUCCESS_UPDATE_APPOINTMENT: 'SUCCESS_UPDATE_APPOINTMENT',
  SUCCESS_DELETE_APPOINTMENT: 'SUCCESS_DELETE_APPOINTMENT',
  SUCCESS_UPLOAD_APPOINTMENT_FILES: 'SUCCESS_UPLOAD_APPOINTMENT_FILES',
  SUCCESS_DELETE_APPOINTMENT_FILE: 'SUCCESS_DELETE_APPOINTMENT_FILE',
  SUCCESS_CREATE_SCHEDULE: 'SUCCESS_CREATE_SCHEDULE',
  CLEAR_APPOINTMENTS: 'CLEAR_APPOINTMENTS',
  DELETE_MESSAGES: 'DELETE_MESSAGES',
  DELETE_ATTACHMENT_MESSAGES: 'DELETE_ATTACHMENT_MESSAGES',
  ERROR: 'ERROR_APPOINTMENT',
  LOADING: 'LOADING_APPOINTMENT',
  CLEAR_SHEDULES: 'CLEAR_SHEDULES',
  ADD_INFORMATION_APPOINTMENT: 'ADD_INFORMATION_APPOINTMENT',
  // CREATE_INFORMATION_APPOINTMENT: 'CREATE_INFORMATION_APPOINTMENT',
  SUCCESS_CREATE_INFORMATION_APPOINTMENT:
    'SUCCESS_CREATE_INFORMATION_APPOINTMENT',
  DELETE_INFORMATION_APPOINTMENT: 'DELETE_INFORMATION_APPOINTMENT',
  CREATE_APPOINTMENT_LIGHT: 'CREATE_APPOINTMENT_LIGHT',
  SUCCESS_CREATE_APPOINTMENT_LIGHT: 'SUCCESS_CREATE_APPOINTMENT_LIGHT',
  CLEAR_INFORMATIONS_APPOINTMENT: 'CLEAR_INFORMATIONS_APPOINTMENT',
  UPDATE_APPOINTMENT_LIGHT: 'UPDATE_APPOINTMENT_LIGHT',
  SUCCESS_UPDATE_APPOINTMENT_LIGHT_OR_PROFICIENT:
    'SUCCESS_UPDATE_APPOINTMENT_LIGHT_OR_PROFICIENT',
  CLEAR_SCHEDULE_REQUESTS: 'CLEAR_SCHEDULE_REQUESTS',
  CREATE_APPOINTMENT_PROFICIENT: 'CREATE_APPOINTMENT_PROFICIENT',
  SUCCESS_CREATE_APPOINTMENT_PROFICIENT:
    'SUCCESS_CREATE_APPOINTMENT_PROFICIENT',
  GET_PROFICIENT_DATES: 'GET_PROFICIENT_DATES',
  SUCCESS_GET_PROFICIENT_DATES: 'SUCCESS_GET_PROFICIENT_DATES',
  UPDATE_APPOINTMENT_PROFICIENT: 'UPDATE_APPOINTMENT_PROFICIENT',
  RESEND_CODE: 'RESEND_CODE',
  SUCCESS_RESEND_CODE: 'SUCCESS_RESEND_CODE',
  GET_APPOINTMENTS_ALL: 'GET_APPOINTMENTS_ALL',
};

export const appointmentActions = {
  successGetAppointment: (appointmentData) => ({
    type: appointmentActionsTypes.SUCCESS_GET_APPOINTMENTS,
    payload: appointmentData,
  }),
  successCreateAppointment: (appointmentData) => ({
    type: appointmentActionsTypes.SUCCESS_CREATE_APPOINTMENT,
    payload: appointmentData,
  }),
  successUpdateAppointment: (appointmentData) => ({
    type: appointmentActionsTypes.SUCCESS_UPDATE_APPOINTMENT,
    payload: appointmentData,
  }),
  successUploadAppointmentFiles: (files) => ({
    type: appointmentActionsTypes.SUCCESS_UPLOAD_APPOINTMENT_FILES,
    payload: files,
  }),
  successDeleteAppointmentFile: (appointmentData) => ({
    type: appointmentActionsTypes.SUCCESS_DELETE_APPOINTMENT_FILE,
    payload: appointmentData,
  }),
  successDeleteAppointment: (appointmentData) => ({
    type: appointmentActionsTypes.SUCCESS_DELETE_APPOINTMENT,
    payload: appointmentData,
  }),
  successGetScheduleRequests: (data) => ({
    type: appointmentActionsTypes.SUCCESS_GET_SCHEDULE_REQUESTS,
    payload: data,
  }),
  successGetSchedules: (data) => ({
    type: appointmentActionsTypes.SUCCESS_GET_SCHEDULES,
    payload: data,
  }),
  successScheduleRequestAction: (msj) => ({
    type: appointmentActionsTypes.SUCCESS_SCHEDULE_REQUESTS_ACTION,
    payload: msj,
  }),
  successScheduleRequestAccept: (data) => ({
    type: appointmentActionsTypes.SUCCESS_ACCEPT_SCHEDULE_REQUESTS,
    payload: data,
  }),
  successUpdateScheduleRequests: (data) => ({
    type: appointmentActionsTypes.SUCCESS_UPDATE_SCHEDULE_REQUESTS,
    payload: data,
  }),
  successCreateSchedule: (data) => ({
    type: appointmentActionsTypes.SUCCESS_CREATE_SCHEDULE,
    payload: data,
  }),
  successDeleteSchedule: (data) => ({
    type: appointmentActionsTypes.SUCCESS_DELETE_SCHEDULE,
    payload: data,
  }),
  error: (message) => ({
    type: appointmentActionsTypes.ERROR,
    payload: message,
  }),
  loading: (value) => ({
    type: appointmentActionsTypes.LOADING,
    payload: value,
  }),
  successCreateInformation: (data) => ({
    type: appointmentActionsTypes.SUCCESS_CREATE_INFORMATION_APPOINTMENT,
    payload: data,
  }),
  successUpdateAppointmentLightOrProficient: (message) => ({
    type: appointmentActionsTypes.SUCCESS_UPDATE_APPOINTMENT_LIGHT_OR_PROFICIENT,
    payload: message,
  }),
  successGetProficientDates: (data) => ({
    type: appointmentActionsTypes.SUCCESS_GET_PROFICIENT_DATES,
    payload: data,
  }),
  successResendCode: (message) => ({
    type: appointmentActionsTypes.SUCCESS_RESEND_CODE,
    payload: message,
  }),
};
