import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { userReducer, sessionReducer } from './user/reducer';
import { doctorsReducer } from './doctor/reducer';
import { appointmentsReducer } from './appointment/reducer';
import { proficientsReducer } from './proficient/reducer';

const persistConfig = {
  key: 'session',
  storage,
  whitelist: ['session'],
};

const rootReducer = combineReducers({
  user: userReducer,
  doctors: doctorsReducer,
  appointments: appointmentsReducer,
  session: sessionReducer,
  proficients: proficientsReducer,
});

export default persistReducer(persistConfig, rootReducer);
