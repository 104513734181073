import { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Grid, Button, Box, TextField } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import DoctariCalendar from '../../components/DoctariCalendar';
import LinearLoader from '../../components/LinearLoader';
import Notification from '../../components/Notification';
import { AGENDA } from '../../utils/urls';
import { MyAppointments } from './MyAppointments';
import DoctariModal from '../../components/Modal';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/helpers';

function Proficient({
  proficient,
  classes,
  proficientsInfo,
  appointmentsData,
  deleteMessages,

  getAppointments,
  clearAppointments,
  getNewAccessToken,
  userState,
  setSelectedProficient,
  getProficientDates,
}) {
  const theme = useTheme();
  const history = useHistory();
  const [t, i18n] = useTranslation('global');

  const [state, setState] = useState({
    dateOfAppointment: null,
    doctorName: null,
    specialty: null,
    country: null,
    type: null,
    availability: true,
    score: false,
  });

  const [createAppointmentModalOpen, setCreateAppointmentModalOpen] =
    useState(false);

  useEffect(() => {
    if (proficient && proficient.id) {
      getProficientDates(
        moment()
          .day(1)
          .set('hour', '00')
          .set('minute', '00')
          .add(0, 'days')
          .format('YYYY-MM-DD HH:mm'),
        moment().day(1).add(7, 'days').format('YYYY-MM-DD HH:mm'),
        proficient.id
      );
    }
  }, []);

  useEffect(() => {
    if (proficient && proficient.id) {
      let queryParams = ['date__gt'];
      let queryValues = [
        moment().subtract(30, 'minutes').format('YYYY-MM-DD HH:mm'),
      ];

      getAppointments(
        'proficient',
        proficient.id,
        false, //isDone
        queryParams,
        queryValues
      );
    }
    return () => clearAppointments();
  }, []);

  useEffect(() => {
    if (userState.user) {
      setSelectedProficient(userState.user);
    }
  }, [userState.user]);

  useEffect(() => {
    if (appointmentsData.appointmentCreated) {
      if (
        appointmentsData.appointmentCreated.patient_link ||
        appointmentsData.appointmentCreated.client_link
      ) {
        setCreateAppointmentModalOpen(true);
      }
    }
  }, [appointmentsData.appointmentCreated]);

  const ClipboardCopy = ({ copyText, label }) => {
    const [isCopied, setIsCopied] = useState(false);

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }

    // onClick handler function for the copy button
    const handleCopyClick = () => {
      // Asynchronously call copyTextToClipboard
      copyTextToClipboard(copyText)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <TextField
          id="my-input"
          aria-describedby="my-helper-text"
          style={{ width: '80%' }}
          variant="outlined"
          value={copyText}
          size="small"
          label={label}
          readOnly
        />
        <Button
          style={{ float: 'right', margin: '10px' }}
          variant="text"
          color="primary"
          onClick={handleCopyClick}
        >
          <span>{isCopied ? 'Copiado!' : 'Copiar'}</span>
        </Button>
      </Box>
    );
  };

  return (
    <>
      {proficientsInfo.loading && <LinearLoader />}

      {appointmentsData.success.genericMessage ==
        'Appointment eliminado con exito' && (
        <Notification
          type="error"
          message={'Consulta cancelada'}
          onCloseCallback={deleteMessages}
        />
      )}
      <DoctariModal
        open={createAppointmentModalOpen}
        setOpen={(val) => setCreateAppointmentModalOpen(val)}
        title={`Consulta ${t('word.created')}`}
      >
        <ClipboardCopy
          copyText={
            appointmentsData.appointmentCreated
              ? appointmentsData.appointmentCreated.patient_link
                ? appointmentsData.appointmentCreated.patient_link
                : appointmentsData.appointmentCreated.client_link
              : ''
          }
          label="Link paciente"
        />
        <ClipboardCopy
          copyText={
            appointmentsData.appointmentCreated
              ? appointmentsData.appointmentCreated.video_conference_code
              : ''
          }
          label="Código paciente"
        />
        {
          <p style={{ margin: '5%' }}>
            {`${t('sentence.sendEmailCreatedAppointment')}`}{' '}
            {appointmentsData.appointmentCreated
              ? appointmentsData.appointmentCreated.patient_link
                ? 'paciente'
                : `${t('word.client')}`
              : ''}{' '}
            {`${t('sentence.sendEmailCreatedAppointment2')}`}
          </p>
        }

        <Button
          style={{ float: 'right', margin: '10px' }}
          variant="contained"
          color="primary"
          onClick={() => {
            setCreateAppointmentModalOpen(false);
          }}
        >
          {`${capitalize(t('word.accept'))}`}
        </Button>
      </DoctariModal>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ flexWrap: 'initial', fontFamily: theme.typography.fontFamily }}
      >
        <MyAppointments
          appointmentData={appointmentsData}
          theme={theme}
          isProfessional={true}
          classes={classes}
          session={proficient}
          getNewAccessToken={getNewAccessToken}
          userState={userState}
        ></MyAppointments>
        <Grid item xs={11} md={12}>
          <h1
            className={classes.title}
            style={{ fontSize: '40px', textAlign: 'center' }}
          >
            {t('Calendar.my-agenda')}
          </h1>
          <DoctariCalendar
            currentWeek={0}
            schedules={appointmentsData.schedules}
            slot={proficient.slot}
            appointmentType={'Peritaje'}
            proficientDates={appointmentsData.proficientDates}
          >
            {userState.user && userState.user.self_managed && (
              <Button
                variant="contained"
                color="primary"
                style={{ width: '206px' }}
                onClick={() =>
                  history.push({
                    pathname: AGENDA,
                    state: { specialtyType: 'Peritaje' },
                  })
                }
              >
                {t('Calendar.edit-calendar')}
              </Button>
            )}
          </DoctariCalendar>
        </Grid>
      </Grid>
    </>
  );
}

export default Proficient;
