import { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, Paper, Avatar, Button, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/helpers';
// import MoreIcon from '@mui/icons-material/MoreVert';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    fontFamily: theme.typography.fontFamily,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    /* padding: "1.5rem", */
    marginTop: '1rem',
    width: '100%',
    flexWrap: 'wrap',
    borderRadius: '8px',
    '@media screen and (max-width: 425px)': {
      width: '100%',
    },
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    /*  '@media screen and (max-width:576px)' : {
      width:'100%'
    } */
  },
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    '@media screen and (max-width: 320px)': {
      flexDirection: 'column',
      alignItems: 'normal',
    },
    '@media screen and (max-width:768px)': {
      padding: '10px',
    },
  },
  /*  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    flexDirection: "column",
    "@media (max-width: 900px)": {
      marginTop: "1rem",
    },
  }, */
  /*  description: {
    marginTop: "5px",
    fontWeight: 300,
    fontSize: "14px",
  }, */
  /*  moreDataContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    marginTop: "1rem",
    "@media (max-width: 900px)": {
      justifyContent: "center",
    },
  }, */
  dataSize: {
    fontSize: '14px',
    padding: '16px 16px 0 16px',
  },
  date: {
    display: 'flex',
    marginBottom: 'auto',
  },
  consult: {
    fontSize: '13px',
  },
  dateNumber: {
    marginLeft: 'auto',
    fontSize: '12px',
    color: '#666666',
  },
  solDate: {
    float: 'right',
    color: '#666666',
    '@media screen and (max-width:425px )': {
      float: 'left',
    },
  },
  avatar: {
    width: '56px',
    height: '56px',
    borderRadius: '25%',
    marginRight: '18px',
    '@media screen and (max-width:320px)': {
      marginRight: '0',
    },
    '@media screen and (max-width: 576px)': {
      width: '51px',
      height: '51px',
      marginRight: '18px',
      border: '2px solid #067DCF',
    },
    '@media screen and (max-width:768px)': {
      marginRight: '8px',
    },
  },
  name: {
    fontSize: '16px',
  },
  specialty: {
    color: '#666666',
    fontSize: '14px',
  },
  button: {
    width: '150px',
    marginLeft: 'auto',
    height: '36px',
    marginRight: '10px',
    '@media screen and (max-width:320px)': {
      marginLeft: '0',
      marginBottom: '5px',
      marginRight: '0',
      width: '100%',
    },
    '@media screen and (max-width:768px)': {
      marginRight: '0',
    },
  },
  hideDetail: {
    width: '175px',
    marginLeft: 'auto',
    height: '36px',
    marginRight: '10px',
    '@media screen and (max-width:320px)': {
      marginLeft: '0',
      marginBottom: '5px',
      marginRight: '0',
      width: '100%!important',
    },
    '@media screen and (max-width:426px)': {
      width: '218px',
    },
    '@media screen and (max-width:768px)': {
      marginRight: '0',
    },
  },
  detailContainer: {
    /* display: "flex", */
    padding: '16px',
    '@media screen and (max-width: 320px)': {
      flexDirection: 'column',
    },
  },
  alergiesContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    fontSize: '14px',
  },
  attached: {
    marginTop: '1rem',
  },
  attachedHeading: {
    marginBottom: '20px',
    fontSize: '14px',
  },
  attachedFiles: {
    color: '#0066FF',
    fontSize: '12px',
    marginRight: '18px',
    display: 'inline',
  },
  dataContainer: {
    fontSize: '14px',
    marginTop: '20px',
  },
  containerAttachedFiles: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: '1rem',
  },
}));

function HistoricAppointmentCard({
  appointment,
  isProfessional,
  isAssistant,
  setPrescriptionModal,
  setSelectedPrescription,
}) {
  const history = useHistory();
  const classes = useStyles();
  const { diseases, medications, allergies } = appointment;

  const [showDetails, setShowDetails] = useState(false);
  const [t, i18n] = useTranslation('global');

  const detailHandler = () => {
    if (!showDetails) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  };

  return (
    <Paper className={classes.paperContainer}>
      <Box className={classes.infoContainer}>
        <Box className={classes.dataSize}>
          <Box>
            <span>
              <strong>
                {' '}
                {t('HistoricAppointmentCard.appointment-reason')}{' '}
              </strong>
            </span>
            <span>
              {appointment?.doctor
                ? appointment.patient_appointment_reason
                  ? appointment.patient_appointment_reason
                  : 'No especifica'
                : appointment.client_reason_text
                ? appointment.client_reason_text
                : 'No especifica'}
            </span>
          </Box>

          <span className={classes.consult}>Consulta: </span>
          <span className={classes.dateNumber}>
            {moment(appointment.date)
              .tz(moment.tz.guess())
              .format('YYYY-MM-D h:mm a')}
          </span>
        </Box>
        <Box style={{ padding: '0 16px' }}>
          <hr style={{ color: 'rgba(0, 0, 0, 0.12)' }}></hr>
        </Box>
        <Box className={classes.boxContainer}>
          <Avatar
            className={classes.avatar}
            alt="profile picture"
            src={
              appointment.doctor && appointment.doctor.profile_picture
                ? appointment.doctor.profile_picture.url
                : appointment.proficient &&
                  appointment.proficient.profile_picture
                ? appointment.proficient.profile_picture.url
                : null
            }
          />
          {/*  <Box className={classes.date}>
          
            <span className={classes.coonsult}>
              <strong>Consulta </strong>
            </span>
            <span className={classes.dateNumber}>{moment(appointment.date).format("DD MMMM / h:mm a")}</span>
          </Box> */}
          <p className={classes.name}>
            {isProfessional || appointment.client_link
              ? appointment.client_link
                ? appointment.client.full_name
                : appointment.patient
                ? appointment.patient.full_name
                : 'No hay paciente'
              : appointment.doctor.full_name}
            <br></br>
            <span className={classes.specialty}>
              {appointment?.doctor
                ? appointment.patient.identity_document
                : appointment.client.identity_document}
            </span>
            <br></br>

            <span className={classes.specialty}>
              {t('HistoricAppointmentCard.date-of-birth')}
              {appointment?.doctor ? (
                <span>
                  {moment(appointment.patient.date_of_birth).format(
                    'DD/MM/YYYY'
                  )}
                </span>
              ) : (
                <span>
                  {moment(appointment.client.date_of_birth).format(
                    'DD/MM/YYYY'
                  )}
                </span>
              )}
            </span>
          </p>

          {/*  <Box>
             <span className={classes.description}>{isProfessional ? "Paciente: " : "Doctor: "}</span> 
          
          </Box> */}
          {/* <Box>
            <span className={classes.dataSize}>CI: {appointment.patient.identity_document}</span>
          </Box>
          <Box>
            <span className={classes.dataSize}>Nacimiento: {appointment.patient.date_of_birth}</span>
          </Box> */}
          {/*  <Box className={classes.dataSize}>
            {appointment.patient_appointment_reason && (
              <Box>
                <span>{`Motivo de la consulta: `}</span>
                <span>{appointment.patient_appointment_reason}</span>
              </Box>
            )}
              {appointment.medical_recomendations && (
              <Box style={{ marginTop: "0.5rem" }}>
                <span className={classes.description} style={{ fontWeight: "bold" }}>
                  {`Recomendaciones: `}
                </span>
                <span className={classes.description}>{appointment.medical_recomendations}</span>
              </Box>
            )} 

             {appointment.medical_diagnostic && (
              <Box style={{ marginTop: "0.5rem" }}>
                <span className={classes.description} style={{ fontWeight: "bold" }}>
                  {`Diagnostico: `}
                </span>
                <span className={classes.description}>{appointment.medical_treatment}</span>
              </Box>
            )}
            {appointment.medical_notes && (
              <Box style={{ marginTop: "0.5rem" }}>
                <span className={classes.description} style={{ fontWeight: "bold" }}>
                  {`Notas: `}
                </span>
                <span className={classes.description}>{appointment.medical_notes}</span>
              </Box>
            )} 
          </Box> */}
          {!showDetails ? (
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={detailHandler}
            >
              {t('HistoricAppointmentCard.show-details')}
            </Button>
          ) : (
            <Button
              className={classes.hideDetail}
              variant="outlined"
              color="primary"
              onClick={detailHandler}
            >
              {t('HistoricAppointmentCard.hide-details')}
            </Button>
          )}
          {/* <IconButton
            size="large"
            aria-label="display more actions"
            edge="end"
            color="inherit"
          >
            <MoreIcon />
          </IconButton> */}
        </Box>
        {showDetails ? (
          <div className={classes.detailContainer}>
            {appointment?.doctor && (
              <>
                <div className={classes.alergiesContainer}>
                  <div>
                    <strong>Alergias</strong>
                    {allergies.length > 0
                      ? allergies.map((allergie) => {
                          return <p>{allergie.name}</p>;
                        })
                      : ''}
                  </div>
                  <div>
                    <strong>Medicamentos</strong>
                    {medications.length > 0
                      ? medications.map((medication) => {
                          return <p>{medication.name}</p>;
                        })
                      : ''}
                  </div>
                  <div>
                    <strong>{t('HistoricAppointmentCard.diseases')}</strong>
                    {diseases.length > 0
                      ? diseases.map((disease) => {
                          return <p>{disease.name}</p>;
                        })
                      : ''}
                  </div>
                </div>
                <div className={classes.attached}>
                  <p>
                    <strong className={classes.attachedHeading}>
                      {t('HistoricAppointmentCard.attached')}
                    </strong>
                    <br></br>
                  </p>
                  {appointment.files.length > 0 ? (
                    appointment.files.map((file) => {
                      return (
                        <a
                          href={file.url}
                          className={classes.attachedFiles}
                          target="_blank"
                        >
                          {file.filename}
                        </a>
                      );
                    })
                  ) : (
                    <p style={{ fontSize: '14px' }}>
                      {t('HistoricAppointmentCard.no-attached')}
                    </p>
                  )}
                  {/* <p className={classes.attachedFiles}>Historia clinica</p>
              <p className={classes.attachedFiles}>Receta Medica</p>
              <p className={classes.attachedFiles}>Foto de Ematoma</p>
              <p className={classes.attachedFiles}>Rayos X</p> */}
                </div>
                <div className={classes.dataContainer}>
                  <p>
                    {' '}
                    <strong>
                      {t('HistoricAppointmentCard.recomendations')}
                    </strong>
                  </p>
                  {appointment.medical_recomendations ? (
                    <p>{appointment.medical_recomendations}</p>
                  ) : (
                    <p>{capitalize(t('word.no'))} especifica</p>
                  )}
                  {/* <p>
                {' '}
                <strong>Indicaciones:</strong>
              </p>
              <p>
                ectetur adipiscing elit, sed domagna aliqua. Ut enim ad minim
                veniam <span style={{ color: '#0066FF' }}>Ver mas</span>
              </p> */}
                  <p>
                    {' '}
                    <strong>Diagnostico:</strong>
                  </p>
                  {appointment.medical_diagnostic ? (
                    <p>{appointment.medical_diagnostic}</p>
                  ) : (
                    <p>{capitalize(t('word.no'))} especifica</p>
                  )}
                  {isProfessional ? (
                    <>
                      <p>
                        {' '}
                        <strong>Recetas:</strong>
                      </p>
                      {appointment.prescriptions.length > 0 ? (
                        appointment.prescriptions.map((prescription) => {
                          return (
                            // <Button
                            //   onClick={() => {
                            //     setPrescriptionModal(true);
                            //     setSelectedPrescription(prescription);
                            //   }}
                            // >
                            //   {prescription.generic_drug}
                            // </Button>
                            <Link
                              component="button"
                              variant="overline"
                              style={{ marginRight: '1rem' }}
                              onClick={() => {
                                setPrescriptionModal(true);
                                setSelectedPrescription(prescription);
                              }}
                            >
                              {prescription.generic_drug}
                            </Link>
                          );
                        })
                      ) : (
                        <p>{capitalize(t('word.no'))} especifica</p>
                      )}
                      <p>
                        <strong>Notas:</strong>
                      </p>
                      {appointment.medical_notes ? (
                        <p>{appointment.medical_notes}</p>
                      ) : (
                        <p>{capitalize(t('word.no'))} especifica</p>
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </>
            )}

            {appointment.client_link && (
              <>
                <div className={classes.attached}>
                  <p>
                    <strong className={classes.attachedHeading}>
                      {t('HistoricAppointmentCard.attached')}
                    </strong>
                    <br></br>
                  </p>
                  <div className={classes.containerAttachedFiles}>
                    {appointment.files.length > 0 ? (
                      appointment.files.map((file) => {
                        return (
                          <a href={file.url} className={classes.attachedFiles}>
                            {file.filename}
                          </a>
                        );
                      })
                    ) : (
                      <p style={{ fontSize: '14px' }}>
                        {t('HistoricAppointmentCard.no-attached')}
                      </p>
                    )}
                  </div>
                  {/* <p className={classes.attachedFiles}>Historia clinica</p>
              <p className={classes.attachedFiles}>Receta Medica</p>
              <p className={classes.attachedFiles}>Foto de Ematoma</p>
              <p className={classes.attachedFiles}>Rayos X</p> */}
                </div>
                <div className={classes.dataContainer}>
                  <p>
                    {' '}
                    <strong>Tipo de {t('word.expertise')}:</strong>
                  </p>
                  {appointment.appointment_type == 'vehicle' ? (
                    <p>{capitalize(t('word.vehicle'))}</p>
                  ) : (
                    <p>{capitalize(t('word.home'))}</p>
                  )}
                  {appointment.appointment_type == 'home' && (
                    <>
                      <p>
                        {' '}
                        <strong>{capitalize(t('word.intervention'))}:</strong>
                      </p>
                      {appointment.type_intervetion ? (
                        <p>{appointment.type_intervetion}</p>
                      ) : (
                        <p>{capitalize(t('word.no'))} especifica</p>
                      )}
                      <p>
                        {' '}
                        <strong>{capitalize(t('word.request'))}:</strong>
                      </p>
                      {appointment.client_request ? (
                        <p>{appointment.client_request}</p>
                      ) : (
                        <p>{capitalize(t('word.no'))} especifica</p>
                      )}
                      <p>
                        {' '}
                        <strong>Diagnóstico:</strong>
                      </p>
                      {appointment.provider_diagnosis ? (
                        <p>{appointment.provider_diagnosis}</p>
                      ) : (
                        <p>{capitalize(t('word.no'))} especifica</p>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        ) : (
          ''
        )}
      </Box>
    </Paper>
  );
}

export default HistoricAppointmentCard;
