import React from 'react';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  List,
  Link,
  IconButton,
  ListItemSecondaryAction,
} from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';

export const AttachmentList = ({ files, setFiles, deleteFile }) => {
  const [t, i18n] = useTranslation('global');

  const handleDeleteFile = (id) => {
    deleteFile(id);
    setFiles(files.filter((file) => file.id !== id));
  };

  const AttachmentListElement = ({ file }) => {
    return (
      <ListItem>
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => handleDeleteFile(file.id)}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
        <ListItemAvatar>
          <Avatar>
            <AttachmentIcon></AttachmentIcon>
          </Avatar>
        </ListItemAvatar>
        <Link href={file.url}>{file.filename}</Link>
      </ListItem>
    );
  };

  return (
    <>
      {files.length > 0 ? (
        <List>
          {files.map((file) => (
            <AttachmentListElement file={file}></AttachmentListElement>
          ))}
        </List>
      ) : (
        <h4
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {t('HistoricAppointmentCard.no-attached')}
        </h4>
      )}
    </>
  );
};
