import React, { useState } from 'react';
import moment from 'moment';
import {
  Grid,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  FormHelperText,
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  Select,
  TextField,
  Link,
} from '@material-ui/core';
import PasswordInput from '../../components/PasswordInput';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/helpers';

function PatientForm({
  classes,
  countries,
  validateEmail,
  onSignup,
  isOlderThan18,
}) {
  const [state, setState] = useState({
    name: null,
    lastName: null,
    country: null,
    document: null,
    email: null,
    phone: null,
    password: null,
    password2: null,
    dateOfBirth: moment().add(-18, 'years').format('YYYY-MM-DD'),
    termsAndConditions: false,
    city: null,
    gender: null,
  });

  const {
    name,
    lastName,
    country,
    phone,
    document,
    email,
    password,
    password2,
    termsAndConditions,
    dateOfBirth,
    city,
    gender,
  } = state;
  const [t, i18n] = useTranslation('global');

  const isValidPassword = (password) => {
    let passError = false;
    const strongRegex = new RegExp(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
    );

    if (strongRegex.test(password)) {
      passError = true;
    }
    return passError;
  };

  const isEnable = () => {
    let isEnable = true;
    let obj = null;
    for (const prop in state) {
      obj = state[prop];
      if (obj && (prop === 'password' || prop === 'password2')) {
        isEnable =
          isEnable && password2 === password && isValidPassword(password);
      } else if (obj && prop === 'email') {
        isEnable = isEnable && validateEmail(email);
      } else if (obj && prop === 'dateOfBirth') {
        isEnable = isEnable && isOlderThan18(dateOfBirth);
      } else {
        isEnable = isEnable && obj;
      }
    }
    return isEnable;
  };

  const signUp = () => {
    const body = {
      email,
      first_name: name,
      last_name: lastName,
      identity_document: document,
      date_of_birth: moment(dateOfBirth).format('YYYY-MM-DD'),
      gender,
      telephone: phone,
      city,
      country,
      password,
    };
    onSignup('patient', body);
  };

  return (
    <>
      <FormControl className={classes.formControl} error={name === ''}>
        <InputLabel htmlFor="my-input">{`${t(
          'PersonalData.name'
        )}`}</InputLabel>
        <Input
          id="my-input"
          aria-describedby="my-helper-text"
          autoComplete="off"
          onChange={(e) => setState({ ...state, name: e.target.value })}
          value={name}
          required
        />
      </FormControl>
      <FormControl className={classes.formControl} error={lastName === ''}>
        <InputLabel htmlFor="my-input">{`${t(
          'PersonalData.last-name'
        )}`}</InputLabel>
        <Input
          id="my-input"
          aria-describedby="my-helper-text"
          autoComplete="off"
          onChange={(e) => setState({ ...state, lastName: e.target.value })}
          value={lastName}
          required
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          id="date"
          label={`${t('PersonalData.date-of-birth')}`}
          type="date"
          defaultValue={dateOfBirth}
          onChange={(e) => setState({ ...state, dateOfBirth: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!isOlderThan18(dateOfBirth)}
        />
        {!isOlderThan18(dateOfBirth) && (
          <FormHelperText error>Debe ser mayor de 18 años</FormHelperText>
        )}
      </FormControl>
      <FormControl className={classes.formControl} error={country === ''}>
        <InputLabel id="demo-simple-select-label">
          {`${t('PersonalData.country')}`}
        </InputLabel>
        <Select
          inputProps={{
            name: 'age',
            id: 'age-native-simple',
          }}
          native
          value={country}
          onChange={(e) =>
            setState({ ...state, country: Number(e.target.value) })
          }
        >
          <option aria-label="None" value="" />
          {countries &&
            countries.map((country) => {
              return <option value={country.id}>{country.name}</option>;
            })}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl} error={city === ''}>
        <InputLabel htmlFor="my-input">{`${t(
          'PersonalData.city'
        )}`}</InputLabel>
        <Input
          id="my-input"
          aria-describedby="my-helper-text"
          onChange={(e) => setState({ ...state, city: e.target.value })}
          value={city}
          required
        />
      </FormControl>
      <FormControl className={classes.formControl} error={document === ''}>
        <InputLabel htmlFor="my-input">Documento *</InputLabel>
        <Input
          id="my-input"
          aria-describedby="my-helper-text"
          autoComplete="off"
          onChange={(e) => setState({ ...state, document: e.target.value })}
          value={document}
          required
        />
      </FormControl>
      <FormControl className={classes.formControl} error={phone === ''}>
        <InputLabel htmlFor="my-input-phone">{`${t(
          'PersonalData.phone'
        )}`}</InputLabel>
        <Input
          id="my-input-phone"
          aria-describedby="my-helper-text"
          autoComplete="off"
          onChange={(e) => setState({ ...state, phone: e.target.value })}
          value={phone}
          required
        />
      </FormControl>
      <FormControl
        className={classes.formControl}
        style={{ marginTop: '1rem', marginBottom: '0rem' }}
        error={gender === ''}
      >
        <FormLabel component="legend">{`${t(
          'PersonalData.gender'
        )}`}</FormLabel>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          onChange={(e) => setState({ ...state, gender: e.target.value })}
          value={gender}
          row
        >
          <FormControlLabel value="F" control={<Radio />} label="Femenino" />
          <FormControlLabel value="M" control={<Radio />} label="Masculino" />
          <FormControlLabel
            value="O"
            control={<Radio />}
            label={`${capitalize(t('word.other'))}`}
          />
        </RadioGroup>
      </FormControl>
      <FormControl
        className={classes.formControl}
        error={email && !validateEmail(email)}
      >
        <InputLabel htmlFor="my-input">Email *</InputLabel>
        <Input
          id="my-input"
          aria-describedby="my-helper-text"
          autoComplete="off"
          onChange={(e) => setState({ ...state, email: e.target.value })}
          value={email}
          required
        />
        {email && !validateEmail(email) && (
          <FormHelperText id="standard-adornment-password-text" error>
            Formato inválido
          </FormHelperText>
        )}
      </FormControl>
      <PasswordInput
        classes={classes}
        changePassword={(newPass) => setState({ ...state, password: newPass })}
        password={password}
        isValidPassword={isValidPassword}
        labelText={`${t('AccountData.pass')} *`}
      />
      <PasswordInput
        classes={classes}
        changePassword={(newPass) => setState({ ...state, password2: newPass })}
        password={password2}
        diffPassword={!password || password2 !== password}
        isSecondPassword
        labelText={`Confirmar ${t('word.password')} *`}
      />
      <Grid item className={classes.formControl}>
        <FormControlLabel
          control={
            <Checkbox
              checked={termsAndConditions}
              onChange={(event) =>
                setState({ ...state, termsAndConditions: event.target.checked })
              }
              name="checkedB"
              color="primary"
            />
          }
          style={{ marginRight: '0px' }}
        />
        <span>{`${capitalize(t('word.accept'))} ${t('prep.the-2')}`}</span>
        <Link href="#"> {`${t('sentence.registerPatient')}`}</Link>
      </Grid>
      <Button
        variant="outlined"
        color="primary"
        className={classes.register}
        fullWidth
        disabled={!isEnable()}
        onClick={signUp}
      >
        {`${capitalize(t('word.signIn'))}`}
      </Button>
    </>
  );
}

export default PatientForm;
